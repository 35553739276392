import styles from './styles.module.css';

const index = (props) => {
  return (
    <fieldset className={styles.fieldset} style={props.style}>
      <legend className={styles.legend}>{props.legend}</legend>
      {props.children}
    </fieldset>
  );
};

export default index;
