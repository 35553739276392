import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border: 1px solid #1a60b4;
  color: #fff;
  background-color: #1a60b4;
  border-radius: 2px;
  padding: 4px 16px;
  gap: 8px;
  font-size: 1em;
  white-space: nowrap;
  font-family: inter;
  line-height: 22px;
  cursor: pointer;
  svg {
    width: 18px;
    height: 18px;
  }
`;

const SaveButton = ({onClick, onBlur, children}) => {
  return (
    <StyledButton
      onClick={onClick}
      onBlur={onBlur}
    >
      {children}
      <KeyboardArrowDownIcon />
    </StyledButton>
  );
};

export default SaveButton;
