const paths = {
  documentation: '/documentation',
  dashboard: '/dashboard',
  files: '/files',
  profile: '/profile',
  order: '/order',
  visualization: '/visualization',
  yourEnvironments: '/',
  home: '/',
};
export default paths;
