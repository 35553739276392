import area from '@turf/area';
import bbox from '@turf/bbox';

import { v4 as uuid4 } from 'uuid';

import { isNilOrEmpty } from '@/utils/validator';

import { WAM, LEGAL_KEYWORDS, INPUT_KEYS } from '../constants';

export const computeEstimatedArea = ({features, noAreaValue = undefined}) => {
  if (isNilOrEmpty(features)) return noAreaValue;

  const _features = (
    (features instanceof Array) ?
      features :
      Object.values(features)
  );

  // area calculated in m2, change to km2
  const m2 = area({
    type: 'FeatureCollection',
    features: _features,
  });
  return Math.ceil(m2 * 0.000001);
};

export const getBbox = (features) => {
  if (isNilOrEmpty(features)) return;

  const _features = (
    (features instanceof Array) ?
      features :
      Object.values(features)
  );
  const featureCollection = {
    type: 'FeatureCollection',
    features: _features,
  };

  return bbox(featureCollection);
};

export const computeViewStateFromBbox = ({
  bbox,
  maxZoom = 21,
}) => {
  return {
    latitude: (bbox[3] + bbox[1]) / 2,
    longitude:(bbox[2] + bbox[0]) / 2,
    zoom: computeZoomLevelFromBbox({ bbox, maxZoom }),
  };
};

export const computeZoomLevelFromBbox = ({
  bbox,
  mapDim = {height: 1080, width: 1920},
  maxZoom = 21,
}) => {
  let WORLD_DIM = { height: 256, width: 256 };

  function latRad(lat) {
      var sin = Math.sin(lat * Math.PI / 180);
      var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
      return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
      return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  let [minX, minY, maxX, maxY] = bbox;

  var latFraction = (latRad(maxY) - latRad(minY)) / Math.PI;

  var lngDiff = maxX - minX;
  var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, maxZoom);
}

export const assembleWamOrder = (productConfig, formState, calculations) => {
  const wamOrder = {
    id: uuid4(),
    name: formState[INPUT_KEYS.NAME],
    description: formState[INPUT_KEYS.DESCRIPTION],
    enabled: true,
    envType: WAM,
    viewState: computeViewStateFromBbox({
      bbox: getBbox(formState[INPUT_KEYS.AOI]),
    }),
    envTypeConfig: {
      order: {
        ...formState,
      },
      calculations: {
        ...calculations,
      },
      productConfig: {
        ...productConfig,
      },
    },
    legalConfig: {
      config: Object.fromEntries(
        LEGAL_KEYWORDS.map(
          (keyword) => [keyword, productConfig.legal[keyword]],
        )
      ),
      accepted: Object.fromEntries(
        LEGAL_KEYWORDS.map(
          (keyword) => [keyword, formState[keyword]],
        )
      ),
      now: Date.now(),
    },
  };

  return wamOrder;
};

export const unwindWamOrder = (wamOrder) => {
  const formState = {
    ...(wamOrder.envTypeConfig.order),
    [INPUT_KEYS.NAME]: wamOrder.name,
    [INPUT_KEYS.DESCRIPTION]: wamOrder.description,
  };
  const productConfig = {
    ...(wamOrder.envTypeConfig.productConfig),
  }
  const calculations = {
    ...(wamOrder.envTypeConfig.calculations),
  }

  return [productConfig, formState, calculations];
};
