// Basemap selector component, used for changing between mapstyles
import { useState } from 'react';
import classNames from 'classnames';
import { has } from 'ramda';

// utils
import { isArrayNotEmpty } from '@/utils/validator';
import {
  Grid,
  IconButton,
  Popover,
  Typography,
  makeStyles,
} from '@material-ui/core';
// icons
import CloseIcon from '@mui/icons-material/Close';
import QueueIcon from '@mui/icons-material/Queue';

// style
const useStyles = makeStyles((theme) => ({
  baseMapContainer: {
    width: '80px',
    height: '80px',
    backgroundColor: theme.palette.common.black,
    boxShadow:
      '0 1px 2px rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
    position: 'absolute',
    top: '32px',
    left: '32px',
    cursor: 'pointer',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '4px',
    border: `solid 3px ${theme.palette.common.white}`,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
    '& div': {
      color: theme.palette.common.white,
      zIndex: 1,
      textTransform: 'uppercase',
      fontSize: '11px',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0,0,0,.5)',
      borderRadius: 0,
    },
  },
  stylesPopOverContainer: {
    marginLeft: '10px',
  },
  stylesPopOverGrid: {
    minHeight: '80px',
    padding: '10px',
  },
  stylesPopOverGridItem: {
    cursor: 'pointer',
    minWidth: '70px',
    height: '60px',
    fontSize: '11px',
    '&:hover span': {
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },
    '&:hover $gridImageContainer': {
      '&:after': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  gridImageContainer: {
    backgroundColor: theme.palette.grey[100],
    backgroundPosition: '50% 50%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '36px',
    borderRadius: '4px',
    width: '36px',
    position: 'relative',
    marginBottom: '5px',
    '& svg': {
      fill: theme.palette.grey[700],
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      borderRadius: '4px',
      right: 0,
      bottom: 0,
      border: 'solid 2px transparent',
    },
  },
  noCursor: {
    cursor: 'default',
  },
  styleContainer: {
    '& +$styleContainer': {
      marginTop: '10px',
    },
  },
}));

const AvailableBasemaps = ({
  onChange,
  onPopoverClose = () => {},
  basemapConfigs = [],
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.styleContainer}>
      {basemapConfigs.map((cfg) => (
        <Grid
          item
          key={cfg.id}
          className={classes.stylesPopOverGridItem}
          onClick={() => {
            onChange(cfg);
            onPopoverClose();
          }}
        >
          <Grid container direction='column' alignItems='center'>
            <Grid item>
              <div
                className={classes.gridImageContainer}
                style={{
                  backgroundImage: `url(${cfg.icon})`,
                }}
              ></div>
            </Grid>
            <Grid item>
              <Typography variant='inherit'>{cfg.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

// the popover component with the styles
const BasemapPopover = (props) => {
  const {
    anchorEl,
    open,
    onPopoverClose = () => {},
    onChange,
    currentBasemapConfig,
    basemapConfigs = [],
  } = props;
  const classes = useStyles();

  const otherBasemapConfigs = basemapConfigs.filter(
    (cfg) => cfg.id !== currentBasemapConfig.id
  );

  return (
    <Popover
      className={classes.stylesPopOverContainer}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
      anchorEl={anchorEl}
      onClose={onPopoverClose}
      open={open}
    >
      {isArrayNotEmpty(basemapConfigs) && (
        <Grid
          className={classes.stylesPopOverGrid}
          container
          justifyContent='flex-start'
          alignItems='flex-start'
        >
          <Grid item>
            <AvailableBasemaps
              onChange={onChange}
              onPopoverClose={onPopoverClose}
              basemapConfigs={otherBasemapConfigs}
            />
          </Grid>
        </Grid>
      )}
    </Popover>
  );
};

// Basemap selector default component
const Basemap = ({
  onChange = () => {},
  currentBasemapConfig,
  basemapConfigs = [],
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const isPopoverOpen = Boolean(anchorEl);

  if (!currentBasemapConfig) return (<></>);

  return (
    <>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className={classes.baseMapContainer}
        onClick={handleClick}
        style={{
          backgroundImage: `url(${currentBasemapConfig.icon})`,
        }}
      >
        <Typography component='div' align='center' variant='body2'>
          {currentBasemapConfig.name}
        </Typography>
      </Grid>
      {isPopoverOpen && (
        <BasemapPopover
          anchorEl={anchorEl}
          open={isPopoverOpen}
          onPopoverClose={handlePopoverClose}
          onChange={onChange}
          currentBasemapConfig={currentBasemapConfig}
          basemapConfigs={basemapConfigs}
        />
      )}
    </>
  );
};

export default Basemap;
