import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import GlobalLoading from '@/components/GlobalLoading';
import { useProject } from '@/hooks/useProject';
import { setProjectViewConfig } from '@/store/appSlice';
import emit from '@/utils/emit';

const Environment = (props) => {
  const { currentEnvId, currentProjectView } = props;
  const { loadProjectViewConfig } = useProject();
  const [isEnvLoading, setIsEnvLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const loadViewConfig = async (envId, viewId) => {
      setIsEnvLoading(true);
      try {
        const cfg = await loadProjectViewConfig(envId, viewId);
        if (cfg?.error === undefined && cfg?.config) {
          dispatch(setProjectViewConfig(cfg.config));
        } else if (cfg?.error) {
          emit.error(cfg.error);
        }
      } catch (e) {
        console.error(e);
      }
      setIsEnvLoading(false);
    };

    if (!currentEnvId || !currentProjectView) return;
    loadViewConfig(currentEnvId, currentProjectView);
  }, [currentEnvId, currentProjectView]);

  return <div>{isEnvLoading && <GlobalLoading />}</div>;
};

export default Environment;
