import { useEffect, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import GlobalLoading from '@/components/GlobalLoading';
import {
  OrderForm,
  validateFormState as validateOrderForm,
} from '@/components/Wam/OrderForm';
import {
  OrderPreview,
  validateFormState as validatePreview,
} from '@/components/Wam/OrderPreview';
import { useProduct } from '@/hooks/useProduct';
import emit from '@/utils/emit';
import { isNilOrEmpty } from '@/utils/validator';

import { WAM, INPUT_KEYS, LEGAL_KEYWORDS } from '../constants';
import { 
  computeViewStateFromBbox,
  getBbox,
  assembleWamOrder,
} from '../utils';

const WamOrderFlow = ({
  inModal=false,
  onExit,
}) => {
  /*
   * Steps are:
   * 1. populate order - fill out order form
   * 2. preview order - review order form
   */
  const [currentStep, setNextStep] = useState('populateOrder');
  const [formState, setFormState] = useState({});
  const [hasError, setHasError] = useState({});
  const [isPlacingOrder, setIsPlacingOrder] = useState(false);

  const calculationsRef = useRef();

  const {
    data: productConfig,
    isSuccess: isProductReady,
    createProductOrder
  } = useProduct(WAM);

  const modifyOrder = () => {
    setNextStep('populateOrder');
  };

  const previewOrder = () => {
    if (validateOrderForm({
      formState,
      hasError,
      setHasError,
    })) {
      setNextStep('previewOrder');
    }
  };

  const placeOrder = () => {
    if (validatePreview({
      formState,
      hasError,
      setHasError,
    })) {
      const wamOrder = assembleWamOrder(
        productConfig,
        formState,
        calculationsRef.current
      );

      setIsPlacingOrder(true);
      createProductOrder(wamOrder).then(
        (envConfig) => {
          emit.success(`Successfully placed WAM Order: ${formState[INPUT_KEYS.NAME]}`);
          onExit(undefined, 'projectCreated', envConfig);
        },
        (e) => {
          console.error(e);
          emit.error('Error placing WAM order. Please try again: ${formState[INPUT_KEYS.NAME]}');
        }
      );
    }
  };

  const title = 'Order Wide Area Monitoring';
  let prevButton = <></>;
  let nextButton;
  let currentComponent;

  switch(currentStep) {
    case 'previewOrder':
      calculationsRef.current = {}
      prevButton = <Button onClick={modifyOrder} variant='outlined'>Modify Order</Button>;
      nextButton = <Button onClick={placeOrder} variant='contained'>Place Order</Button>;
      currentComponent = <OrderPreview
        formState={formState}
        setFormState={setFormState}
        hasError={hasError}
        setHasError={setHasError}
        calculationsRef={calculationsRef}
        productConfig={productConfig}
      />;
      break;
    case 'populateOrder':
    default:
      nextButton = <Button onClick={previewOrder} variant='contained'>Preview Order</Button>;
      currentComponent = <OrderForm
        formState={formState}
        setFormState={setFormState}
        hasError={hasError}
        setHasError={setHasError}
        productConfig={productConfig}
      />;
      break;
  }

  if (!isProductReady) {
    return (
      <div><GlobalLoading /></div>
    );
  }

  if (inModal) {
    return (
      <>
        {isPlacingOrder && <div><GlobalLoading /></div>}
        <DialogTitle fontSize='1.5rem' fontWeight='bold'>{title}</DialogTitle>
        <DialogContent>
          {currentComponent}
        </DialogContent>
        <DialogActions>
          {prevButton}
          <div style={{flex: '1 0 0'}}/>
          <Button onClick={onExit} variant='outlined'>Cancel</Button>
          {nextButton}
        </DialogActions>
      </>
    );
  }

  return <></>;
};

export default WamOrderFlow;
