import 'mapbox-gl/dist/mapbox-gl.css';

import { useRef } from 'react';
import Map, { Layer, Source } from 'react-map-gl';

import Box from '@mui/material/Box';
import { pathOr, has } from 'ramda';
import bbox from '@turf/bbox';

import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

import { BASE_MAP_CONFIGS } from '@/components/Map/utils/basemap';

// constants
const DEFAULT_CSS_STYLE = {
  width: '100%',
  height: '100%',
  borderRadius: '4px',
};
const DEFAULT_MAP_STYLE = BASE_MAP_CONFIGS[0].uri;
const DEFAULT_BOX_STYLE = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 2,
};
const REACT_APP_MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const DEFAULT_VIEWPORT = {
  width: '100%',
  height: '100%',
  zoom: 1,
};

const GeoJSONMap = (props) => {
  const mapRef = useRef(null);

  const style = pathOr(DEFAULT_CSS_STYLE, ['style'], props);
  const initialViewState = pathOr({}, ['initialViewState'], props);
  const mapStyle = pathOr(DEFAULT_MAP_STYLE, ['mapStyle'], props);
  const boxStyle = pathOr({}, ['boxStyle'], props);
  const features = pathOr([], ['features'], props);

  let fragmentSourceLayer = <></>;
  if (isArrayNotEmpty(features)) {
    const geojson = {
      type: 'FeatureCollection',
      features: features,
    };

    const fillLayerConfig = {
      type: 'fill',
      paint: {
        'fill-color': '#9400D3',
        'fill-outline-color': '#9400D3',
        'fill-opacity': 0.1,
      },
    };
    const lineLayerConfig = {
      type: 'line',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
      },
      paint: {
        'line-color': '#9400D3',
        'line-width': 3,
      },
    };

    fragmentSourceLayer = (
      <Source type='geojson' data={geojson}>
        <Layer {...fillLayerConfig} />
        <Layer {...lineLayerConfig} />
      </Source>
    );
  }

  const onLoad = () => {
    if (isArrayNotEmpty(features)) {
      const geojson = {
        type: 'FeatureCollection',
        features: features,
      };
      mapRef?.current?.fitBounds(
        bbox(geojson),
        {
          padding: 100,
        }
      );
    }
  }

  if (isNilOrEmpty(REACT_APP_MAPBOX_ACCESS_TOKEN)) {
    return <></>;
  }

  return (
    <Box
      sx={{
        ...DEFAULT_BOX_STYLE,
        ...boxStyle,
      }}
      className='geojson-map'
    >
      <Map
        ref={mapRef}
        mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={{ ...DEFAULT_VIEWPORT, ...initialViewState }}
        style={style}
        mapStyle={mapStyle}
        onLoad={onLoad}
      >
        {fragmentSourceLayer}
      </Map>
    </Box>
  );
};

export default GeoJSONMap;
