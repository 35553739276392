import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { isNilOrEmpty } from '@/utils/validator';
import Header from '../../components/Header/Header';
import { Loader } from '../../components/loader';
import useEnvs from '../../hooks/calls/useEnvs';
import { useSession } from '../../context/session';
import { randomString } from '../../utils/tools';
//import './style.css';

import {
  DEFAULT_HEADER_HEIGHT,
} from '@/utils/constants';

const IFrame = (props) => {
  const { srcKey, defaultSrcKey, getSrcUri, title, name, id } = props;

  const { getBearerToken } = useSession();
  const { data, isLoading } = useEnvs();
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const [srcLink, setSrcLink] = useState('');
  const [headerHeight, setHeaderHeight] = useState(0);

  const _title = title !== undefined ? title : 'iframe';
  const _name = name !== undefined ? name : 'iframe-' + randomString(10);
  const _id = id !== undefined ? id : _name;
  const orgConfig = pathOr({}, ['org'], data);
  const envList = pathOr([], ['envs'], data);

  const getSrcLink = async () => {
    if (envList.length > 0) {
      let srcUri = '';
      const envItem = envList.find((env) => env.id === currentEnv) || envList[0];
      if (!isNilOrEmpty(getSrcUri)) {
        srcUri = getSrcUri(envItem, orgConfig);
      }
      else {
        srcUri = envItem[srcKey] || envItem[defaultSrcKey];
      }

      try {
        const url = new URL(srcUri);

        // TODO this should be controlled by the calling component
        //url.search = `?token=${await getBearerToken()}&embedded=True`;

        setSrcLink(url.href);
      } catch (e) {
        console.debug('Not a valid URL:', srcUri);
        setSrcLink('');
      }
    }
  };

  useEffect(() => {
    getSrcLink();
  }, [envList, currentEnv]);

  const getHeaderHeight = (node) => {
    if (isNilOrEmpty(node)) return;
    setHeaderHeight(
      pathOr(
        DEFAULT_HEADER_HEIGHT,
        ['height'],
        node.getBoundingClientRect(),
      )
    );
  }

  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <div>
      <div ref={getHeaderHeight}>
        <Header />
      </div>

      {srcLink !== '' && (
        <_IFrame
          headerHeight={headerHeight}
          title={_title}
          name={_name}
          id={_id}
          frameBorder='0'
          border='0'
          cellSpacing='0'
          scrolling='auto'
          src={srcLink}
        />
      )}
    </div>
  );
};

IFrame.defaultProps = {
  defaultSrcKey: 'embedLink',
  title: 'iframe',
};

const _IFrame = (props) => {
  const {headerHeight, ...config} = props;

  config.style = {
    height: `calc(100% - ${headerHeight}px)`,
    width: '100%',
    backgroundColor: 'white',
    position: 'fixed',
  }; 

  return <iframe { ...config }/>;
};

export default IFrame;
