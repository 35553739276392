import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

import UploadIcon from '@mui/icons-material/Upload';

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #d1d5da;
  border-radius: 2px;
  padding: 4px 16px;
  gap: 8px;
  color: #24292e;
  fill: #24292e;
  font-size: 1em;
  white-space: nowrap;
  font-family: inter;
  line-height: 22px;
  cursor: pointer;
  :hover {
    border-color: #4a93e4;
    color: #4a93e4;
    fill: #4a93e4;
    svg {
      fill: #4a93e4;
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

const UploadButton = ({onClick, children}) => {
  return (
    <StyledButton
      onClick={onClick}
    >
      <UploadIcon />
      {children}
    </StyledButton>
  );
};

export default UploadButton;
