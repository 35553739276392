import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import useEnvs from '@/hooks/calls/useEnvs';
import Spinner from './Spinner';

const SelectEnv = () => {
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const { data, isLoading: isEnvListLoading } = useEnvs();
  const envList = pathOr([], ['envs'], data);

  if (isEnvListLoading) return <Spinner />;

  return (
    <div style={{ marginLeft: 11, marginRight: 11 }}>
      {(envList.find((env) => env.id === currentEnv) || envList[0]).name}
    </div>
  );
};

export default SelectEnv;
