import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { pathOr } from 'ramda';
import styled from 'styled-components';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { updateEnvironment } from '@/calls/env';
import GlobalLoading from '@/components/GlobalLoading';
import emit from '@/utils/emit';
import { isNilOrEmpty } from '@/utils/validator';

const Row = styled.div`
  margin-bottom: 1em;
`;

const PROJECT_STATUSES = {
  Active: 'ACTIVE',
  Archived: 'ARCHIVED',
};

const INPUT_KEYS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  STATUS: 'status',
};

const ProjectDetailModal = (props) => {
  const {
    name,
    description,
    status,
    envId,
    env,
    setIsModalVisible,
    isModalVisible,
    onProjectUpdated,
  } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [ formState, setFormState ] = useState({
    name: name,
    description: description,
    status: status,
  });
  const [ hasError, setHasError ] = useState({});
  const [ isSaving, setIsSaving ] = useState(false);

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
      case 'escapeKeyDown':
        break;
      default:
        setIsModalVisible(false);
        break;
    }
  };

  const buildChangeHandler = (keyword) => {
    const handleChange = (evt) => {
      const newValue = evt.target.value;
      const newFormState = {
        ...formState,
        [keyword]: newValue,
      };
      setFormState(newFormState);

      let newHasError = {
        ...hasError,
      };
      switch (keyword) {
        case INPUT_KEYS.NAME:
        case INPUT_KEYS.STATUS:
          newHasError[keyword] = isNilOrEmpty(newValue) ?
            'Required' :
            false;
          break;
      };

      setHasError(newHasError);
    };

    return handleChange;
  }

  const handleSave = async () => {
    setIsSaving(true);
    const newEnv = {
      ...env,
      name: formState[INPUT_KEYS.NAME],
      description: formState[INPUT_KEYS.DESCRIPTION],
      status: formState[INPUT_KEYS.STATUS],
    };

    const token = await getAccessTokenSilently();
    updateEnvironment({ token, newEnv, envId }).then(
      () => {
        setIsSaving(false);
        emit.success(`Successfully updated Environment: ${formState[INPUT_KEYS.NAME]}`);
        setIsModalVisible(false);
        onProjectUpdated(newEnv);
      },
      (e) => {
      setIsSaving(false);
      console.error(e);
      emit.error(`Error updating Environment. Please try again: ${formState[INPUT_KEYS.NAME]}`);
    });
  };

  return (
    <>
      <Dialog
        open={isModalVisible}
        onClose={handleClose}
        maxWidth={false}
      >
        <div style={{ width: '500px' }}>
          <DialogTitle>Project Details</DialogTitle>
          <DialogContent>
            <Row>
              <TextField
                id='name'
                label='Name'
                variant='outlined'
                value={pathOr('', [INPUT_KEYS.NAME], formState)}
                onChange={buildChangeHandler(INPUT_KEYS.NAME)}
                error={!!pathOr(false, [INPUT_KEYS.NAME], hasError)}
                helperText={
                  (typeof pathOr(false, [INPUT_KEYS.NAME], hasError) === 'string') ?
                    hasError[INPUT_KEYS.NAME] :
                    undefined
                }
                required
              />
            </Row>
            <Row>
              <TextField
                id='description'
                label='Description'
                variant='outlined'
                value={pathOr('', [INPUT_KEYS.DESCRIPTION], formState)}
                onChange={buildChangeHandler(INPUT_KEYS.DESCRIPTION)}
                multiline
              />
            </Row>
            <Row>
              <FormControl fullWidth>
                <InputLabel id="status-label">Status</InputLabel>
                <Select
                  labelId="status-label"
                  id="status"
                  value={pathOr(PROJECT_STATUSES['Active'], [INPUT_KEYS.STATUS], formState)}
                  label="Status"
                  onChange={buildChangeHandler(INPUT_KEYS.STATUS)}
                >
                  {Object.entries(PROJECT_STATUSES).map(
                    ([k, v]) => <MenuItem key={v} value={v}>{k}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Row>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
            <Button variant='contained' onClick={handleSave}>Save</Button>
          </DialogActions>
        </div>
      </Dialog>
      {isSaving && <GlobalLoading />}
    </>
  );
};

export default ProjectDetailModal;
