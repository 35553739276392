import set from 'lodash.set';
import { pathOr, has, apply } from 'ramda';
import { scaleLinear, scalePow, scaleQuantile } from 'd3-scale';
import { color } from 'd3-color';
import hash from 'object-hash';
import { memoize } from '@formatjs/fast-memoize';
import {
  Box,
  styled
} from '@mui/material';

// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';
import { getFormatter } from '@/utils/formatters';
import { getAxiosInstance, numEq, numGte, numLte } from '@/utils/tools';

import { modifyLayerFilters } from '@/store/appSlice';

// constants
import { DEFAULT_BASEMAP_ICON, MAPBOX_MAPS } from '@/utils/basemap-config';
import {
  LAYER_SOURCE,
  MOUSE_EVENTS,
  TEMPLATE_LAYERS,
  MAP_LAYER_TYPE,
  ELLIPSIS_LAYER_INFO_URI,
  ELLIPSIS_MVT_URI_TEMPLATE,
  ELLIPSIS_MVT_STYLE_URI_TEMPLATE,
  ELLIPSIS_XYZ_RASTER_URI_TEMPLATE,
  ELLIPSIS_DEFAULT_PLACEHOLDERS,
  MAX_ELLIPSIS_ZOOM_LEVEL,
  DOUBLE_DASH,
} from '@/utils/constants';


const axios = getAxiosInstance({});

export const doesEnvRequireCarto =
  (currentEnvConfig) => !!(currentEnvConfig?.cartoMapToken);

/**
 * Sets the basemap based on the id of the style to the general mapconfig
 * @param {string} idOfCurrentBasemapStyle - id of basemap style
 * @param {object} mapConfig - the global map config generated from fetchMap
 * @returns {void}
 */
export const setBaseMapToConfig = (idOfCurrentBasemapStyle, mapConfig) => {
  if (idOfCurrentBasemapStyle && mapConfig) {
    let reconfiguredMapConfig = set(
      mapConfig,
      'data.keplerMapConfig.config.mapStyle.styleType',
      idOfCurrentBasemapStyle,
    );
    reconfiguredMapConfig = set(
      mapConfig,
      'map.mapStyle.styleType',
      idOfCurrentBasemapStyle,
    );
    return reconfiguredMapConfig;
  }
};

/**
 * Method used to remove the maplibre logo
 * the prop `maplibreLogo` doesn't work on Map instance from react-map-gl
 * @returns {void}
 */
export const removeMapLogo = () => {
  const logoClass = '.mapboxgl-ctrl-logo';
  const el = document.querySelector(logoClass);
  if (el) {
    try {
      el.remove();
    } catch (e) {
      console.error(e);
    }
  }
};

/**
 * Remove the google prefix
 *
 * @param {string} currentBasemapStyleId  - style map name
 * @returns {string} - the style name without the google prefix
 */
export const mapGoogleToDefault = (currentBasemapStyleId) => {
  const GOOGLE_PREFIX = /google-/;
  if (GOOGLE_PREFIX.test(currentBasemapStyleId)) {
    return (currentBasemapStyleId = currentBasemapStyleId.replace(
      GOOGLE_PREFIX,
      '',
    ));
  }
  return currentBasemapStyleId;
};

export const normalizeLayerConfigs = (layerConfigs) => {
  layerConfigs.forEach((layerConfig) => {
    layerConfig.pathId = findPathId(layerConfig);
  });
};

/**
 * Sets the state with custom legend layers, and adding the visibility of them
 *
 * @param {array} extraLayers - the layer coming from the config bucket
 * @param {array} layersFromProjectViewConfig - array layers from the state
 * @param {Function} dispatch  - state dispatch function
 * @param {Function} setCustomLegendLayers  - state method for setting the custom legend layers
 * @returns {void}
 */
export const setStateCustomLegendLayers = (
  extraLayers = [],
  layersFromProjectViewConfig = [],
  dispatch = () => {},
  setCustomLegendLayers = () => {},
) => {
  // set the customLayers
  const modifiedCustomLegendLayers = isArrayNotEmpty(extraLayers)
    ? extraLayers.map((layer) => {
        return {
          ...layer,
          visible:
            isLayerVisible(
              layersFromProjectViewConfig.find(
                (layerFromProjectViewConfig) =>
                  layerFromProjectViewConfig?.id === layer.id,
              )
            ) || isLayerVisible(layer),
          id: layer.id,
        };
      })
    : extraLayers;

  if (isArrayNotEmpty(modifiedCustomLegendLayers)) {
    dispatch(setCustomLegendLayers(modifiedCustomLegendLayers));
  }
};

/**
 *
 * @param {array} extraBaseMaps - basemaps from bucket config
 * @param {Function} dispatch  - state dispatch function
 * @param {Function} setCustomBasemapStyles  - state method for setting the custom basemaps
 */
export const setStateCustomBasemap = (
  extraBaseMaps = [],
  dispatch = () => {},
  setCustomBasemapStyles = () => {},
) => {
  if (isArrayNotEmpty(extraBaseMaps)) {
    dispatch(setCustomBasemapStyles(extraBaseMaps));
  }
};

/**
 * Creates a slug string as an id
 *
 * @param {string} str  - string to be converted to a slug string
 * @returns {string} - the modified string to string with slug
 */
export const toSlug = (str = '') => {
  if (!isNilOrEmpty(str)) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();

    // remove accents, swap ñ for n, etc
    var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
    var to = 'aaaaaeeeeiiiioooouuuunc------';

    for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

    return str;
  }
  return str;
};

const DEFAULT_BASEMAP_STYLE_PROP = ['name', 'uri'];
/**
 * Reformats the basemap array to be used for the Basemap component
 *
 * @param {array} customBasemapStyles - custom basemap styles
 * @returns {array}  - a new formatted basemap to be used for Basemap component
 */
export const createBaseMapsfromConfig = (customBasemapStyles = []) => {
  return customBasemapStyles
    .filter((customBasemapStyle) => {
      const hasAllProps = DEFAULT_BASEMAP_STYLE_PROP.every(
        (defaultBasemapStyle) =>
          Object.keys(customBasemapStyle).indexOf(defaultBasemapStyle) !== -1,
      );
      return hasAllProps ? customBasemapStyle : undefined;
    })
    .map((filteredCustomBasemapStyle) => ({
      id: toSlug(filteredCustomBasemapStyle?.name),
      label: filteredCustomBasemapStyle?.name,
      icon: filteredCustomBasemapStyle?.icon
        ? filteredCustomBasemapStyle?.icon
        : DEFAULT_BASEMAP_ICON,
      options: {
        mapType: MAPBOX_MAPS.MAPBOX,
      },
      url: filteredCustomBasemapStyle?.uri,
      type: MAPBOX_MAPS.MAPBOX,
    }));
};

/**
 * Checks if a layer has geojson data
 *
 * @param {object} rawLayer  - layer object
 * @param {object} GEOJSON_LAYER_MAPPING  - geojson layer mapping
 * @returns {string|boolean}
 */
export const hasGeoJsonData = (rawLayer, GEOJSON_LAYER_MAPPING) => {
  const type = pathOr('', ['type'], rawLayer);
  if (has(type, GEOJSON_LAYER_MAPPING)) {
    return GEOJSON_LAYER_MAPPING[type];
  }
  return false;
};

export const orderLayerIds = (props) => {
  const cartoLayers = pathOr([], ['cartoLayers'], props);
  const customLegendLayers = pathOr([], ['customLegendLayers'], props);
  let defaultLayersOrder = pathOr([], ['defaultLayersOrder'], props);

  if (!isArrayNotEmpty(defaultLayersOrder)) {
    return cartoLayers.concat(customLegendLayers).map((layer) => layer.id);
  }

  let added = [];
  let _cartoLayers = Array.from(cartoLayers);

  // build ordered layers
  let ordered = defaultLayersOrder.map((id_) => {
    const findLayerById = (lyr) => lyr.id == id_;
    let layer;

    if (id_ === null) {
      layer = _cartoLayers.shift();
    }
    else {
      layer = customLegendLayers.find(findLayerById);
      if (layer === undefined) {
        const idx = _cartoLayers.findIndex(findLayerById);
        if (idx !== -1) {
          layer = _cartoLayers.splice(idx, 1)[0];
        }
      }
    }

    added.push(layer);
    return layer;
  });

  ordered = ordered.filter((lyr) => lyr !== undefined);

  // push layers not ordered
  for (const layer of _cartoLayers.concat(customLegendLayers)) {
    if (!added.includes(layer)) ordered.push(layer);
  }

  return ordered.map((layer) => layer.id);
};

/**
 * Method to generate a guid
 *
 * @returns {string} - create a unique guid id
 */
export const guid = () => self.crypto.randomUUID();

/**
 * Method for creating a Layer instance
 *
 * @param {object} layerOptions  - layer to be appended on layers
 * @returns {LayerComponent} - DeckGL Layer instance
 */
export const createLayerComponent = (layerConfig, layerComponent) => {
  let uri = pathOr('', ['uri'], layerConfig);
  const id = pathOr('', ['id'], layerConfig);
  const layersInfo = pathOr({}, ['layersInfo'], layerConfig);
  delete layersInfo?.layerId;
  const layerVisible = isLayerVisible(layerConfig);
  if (layerVisible && !isNilOrEmpty(uri)) {
    // add a fail safe not to trigger if we detect {timestampId}
    if (!/{timestampId}/.test(uri)) {
      return layerComponent(uri, {
        visible: layerVisible,
        id: id,
        layersInfo,
      });
    }
  }

  return null;
};

/**
 * Method for appending a layer to the generic layers
 *
 * @returns {void}
 */
export const appendNewLayer = ({
  layerConfig = null,
  layers = [],
  layerComponent = null,
}) => {
  if (!isNilOrEmpty(layerConfig) && !isNilOrEmpty(layerComponent)) {
    const layerInstance = createLayerComponent(layerConfig, layerComponent);
    if (layerInstance !== null) layers.push(layerInstance);
  }
};

/**
 * Returns the array prop name based on a array of prop names
 *
 * @param {object} object - the object to search in
 * @param {array} props  - array of prop names
 * @returns {string|null} - prop name
 */
export const getObjectPropNameByProps = (object, props) => {
  for (const prop of props) {
    if (has(prop, object)) {
      return prop;
    }
  }
  return null;
};

export const extractPathIdFromUri = (uri) => {
  let parts;
  try {
    parts = (new URL(uri)).pathname.split('/');
  }
  catch (e) {
    return;
  }

  let index;
  if (uri.includes('/path/')) {
    index = parts.indexOf('path');
  }
  else if (uri.includes('/ogc/mvt/')) {
    index = parts.indexOf('mvt');
  }
  if (index) {
    const pathId =  parts[index + 1];
    if (pathId != '{pathId}') return pathId;
  }

  return;
}

export const findPathId = (layerConfig) => {
  let pathId = layerConfig.pathId;
  if (!pathId) {
    for (const uri of [
      layerConfig?.uri,
      layerConfig?.uriTemplate,
      layerConfig?.styleUri,
      layerConfig?.styleUriTemplate,
    ]) {
      if (uri) {
        pathId = extractPathIdFromUri(uri);
        if (pathId) break;
      }
    }
  }

  return pathId;
};

export const createLayerMetadataUri = (layerConfig) => {
  let pathId = findPathId(layerConfig);
  if (!pathId) throw Error('Cannot find pathId for Layer Metadata URI');
  return ELLIPSIS_LAYER_INFO_URI.replace('{pathId}', pathId);
};

const replacePlaceholders = (uriTemplate, placeholders, valuesMap) => {
  let uri = uriTemplate;
  for (const placeholder of placeholders) {
    let value;
    switch (placeholder) {
      case 'style':
      case 'styleId':
        value = (
          pathOr(undefined, ['style'], valuesMap) ||
          pathOr(undefined, ['styleId'], valuesMap)
        );
        break;
      default:
        value = pathOr(undefined, [placeholder], valuesMap);
    }
    if (value !== undefined) {
      uri = uri.replace('{' + placeholder + '}', value);
    }
  }
  return uri;
};

export const createLayerDataUri = (layerConfig, templateKeyword='uriTemplate') => {
  const pathId = pathOr('', ['pathId'], layerConfig);
  const style = pathOr('', ['style'], layerConfig) || pathOr('', ['styleId'], layerConfig);
  const token = pathOr('', ['token'], layerConfig);
  const layerSource = pathOr('', ['layerSource'], layerConfig);
  const layerType = pathOr('', ['layerType'], layerConfig);
  let placeholders = ELLIPSIS_DEFAULT_PLACEHOLDERS;
  const valuesMap = {
    pathId: pathId,
    style: style,
    styleId: style,
    token: token,
  };

  let uriTemplate = pathOr('', [templateKeyword], layerConfig);
  switch (layerSource) {
    case LAYER_SOURCE.ELLIPSIS_DRIVE:
      placeholders = pathOr(
        ELLIPSIS_DEFAULT_PLACEHOLDERS,
        ['uriTemplatePlaceholders'],
        layerConfig
      );
      valuesMap.styleId = pathOr('', ['styleId'], layerConfig);
      if (isNilOrEmpty(uriTemplate)) {
        switch (templateKeyword) {
          case 'uriTemplate':
            switch (layerType) {
              case MAP_LAYER_TYPE.MVT:
                uriTemplate = ELLIPSIS_MVT_URI_TEMPLATE;
                break;
              case MAP_LAYER_TYPE.XYZ_RASTER:
                uriTemplate = ELLIPSIS_XYZ_RASTER_URI_TEMPLATE;
                break;
            }
            break;
          case 'styleUriTemplate':
            uriTemplate = ELLIPSIS_MVT_STYLE_URI_TEMPLATE;
            break;
        }
      }
    default: 
      return replacePlaceholders(uriTemplate, placeholders, valuesMap);
  }
}

export const createLayerStyleUri = (layerConfig) => {
  return createLayerDataUri(layerConfig, 'styleUriTemplate');
}

export const isLayerVisible = (layerConfig) => {
  return layerConfig?.visible === true;
};

export const assembleRequestHeaders = (layerConfig) => {
  const token = layerConfig.token;
  if (!token) return {};

  return {
    Authorization: `Bearer ${token}`,
  };
};

/**
 * Use a GET request to the url
 *
 * @param {string} url - url string
 * @returns {object} - the url response
 */
export const fetchDataFromUrl = async (url='', headers={}) => {
  if (isNilOrEmpty(url)) return;

  const request = {
    url: url,
    method: 'GET',
  };

  // given existing env configs may have tokens with insufficient privileges
  // and our own misunderstanding of tokens included as part of
  // a XYZ raster or MVT layer are scoped to only read the data, it is
  // expected that the request may fail.
  // if the response is a 401, retry without headers

  let response;
  try {
    response = await axios(request);
  }
  catch (e) {
    request.headers = headers;
    response = await axios(request);
  }

  return response.data;
};

export const isTimelineLayer = (layerConfig) => {
  const layerSource = pathOr('', ['layerSource'], layerConfig);
  switch (layerSource) {
    default: 
      return TEMPLATE_LAYERS.includes(layerConfig?.layerType)
    case LAYER_SOURCE.ELLIPSIS_DRIVE:
      const placeholders = pathOr('', ['uriTemplatePlaceholders'], layerConfig);
      return placeholders.includes('timestampId');
  }
};

export const getLayerTypeMetadata = (response) => {
  return pathOr({}, [response.type], response)
}

export const extractTimestamps = (response) => {
  return pathOr(
    [],
    ['timestamps'],
    getLayerTypeMetadata(response),
  );
}

export const mapTimestamps = (timestamps, props) => {
  const mappedTimestamps = timestamps.filter(
    (timestamp) => timestamp?.status === 'active'
  ).map((timestamp) => {
    const to = pathOr('', ['date', 'to'], timestamp);
    const from = pathOr('', ['date', 'from'], timestamp);
    const timeStampId = pathOr('', ['id'], timestamp);
    let timeStampDates = (
      from === to ?
        `${new Date(from).toLocaleString()}` :
        `${new Date(from).toLocaleString()} - ${new Date(to).toLocaleString()}`
    );
    return {
      timeStampId,
      timeStampDates,
      ...props,
    };
  });

  return mappedTimestamps;
}

/**
 * Extracts the timestamps from a array of layers
 *
 * @param {array} layers  - array of custom layers
 * @returns {array}  - an array of timestamps
 */
export const fetchLayersTimestampsData = async (layers) => {
  const modifiedTimestamps = [];
  if (isArrayNotEmpty(layers)) {
    for (const layer of layers) {
      const layerMetadataUri = createLayerMetadataUri(layer);
      const customLayerId = pathOr(null, ['id'], layer);
      const layerType = pathOr(null, ['layerType'], layer);
      const customLayerUri = createLayerDataUri(layer);

      await fetchDataFromUrl(
        layerMetadataUri,
        assembleRequestHeaders(layer),
      ).then((response) => {
        if (!isNilOrEmpty(response)) {
          const timestamps = extractTimestamps(response);

          if (isArrayNotEmpty(timestamps)) {
            const mappedTimestamps = mapTimestamps(
              timestamps,
              {
                customLayerId,
                customLayerUri,
                layerType,
              }
            );

            if (isArrayNotEmpty(mappedTimestamps)) {
              modifiedTimestamps.push(...mappedTimestamps);
            }
          }
        }
      });
    }
  }
  return modifiedTimestamps;
};

/**
 * Trims a html string based on classes and a number of occurences
 *
 * @param {string} htmlData  - html data
 * @param {number} stripFromIndex  - filter the data based on a number
 * @param {array} classes  - array of classes to look for
 * @param {string} parentClass  - parent class name to wrap in
 * @returns {string} - stripped string
 */
export const stripHTMLTooltip = (
  htmlData = '',
  stripFromIndex = 0,
  classes = [],
  parentClass = '',
) => {
  let regexpStr = '';
  if (
    isArrayNotEmpty(classes) &&
    !isNilOrEmpty(htmlData) &&
    !isNilOrEmpty(stripFromIndex)
  ) {
    for (const cls of classes) {
      regexpStr += `<div class="${cls}">((?:(?:(?!<div[^>]*>|</div>).)+|<div[^>]*>.*?</div>)*)</div>`;
    }
    if (!isNilOrEmpty(regexpStr)) {
      const regexp = new RegExp(regexpStr, 'g');
      const allOccurrences = [...htmlData.matchAll(regexp)];

      if (isArrayNotEmpty(allOccurrences)) {
        const occurrencesToStr = allOccurrences
          .map((occurrence) => occurrence[0])
          .filter((_, index) => index < stripFromIndex)
          .join('');
        var strRegExPattern = ' class="' + parentClass + '"';
        const newHtml = htmlData
          .replaceAll(regexp, '')
          .replace(new RegExp(strRegExPattern, 'g'), '');
        return `<div class="${parentClass}">${newHtml}${occurrencesToStr}</div>`;
      }
    }
  }
  return htmlData;
};

/**
 * Toggles the drawer(info panel) on/off
 *
 * @param {object} DRAWER_ARGS - a set of args passed to an object
 * @returns {void}
 */
export const toggleDrawer = ({
  anchorType,
  open,
  setPopupConfig,
  visibilityDrawer,
  setVisibilityDrawer,
  debounceMarkerInViewport,
  viewportObject,
  popupConfigReference,
}) => {
  const valueOfPopupConfig = pathOr(null, ['current'], popupConfigReference);
  const popupConfigTrigger = pathOr(null, ['trigger'], valueOfPopupConfig);
  if (!open) {
    setPopupConfig(false);
  } else {
    if (!isNilOrEmpty(viewportObject)) {
      if (popupConfigTrigger === MOUSE_EVENTS.CLICK) {
        debounceMarkerInViewport(viewportObject);
      }
    }
  }
  setVisibilityDrawer({ ...visibilityDrawer, [anchorType]: open });
};

/**
 * Check if marker is in viewport
 * @param {object} viewportObject - from deckgl containing viewport, mouse xy, etc
 * @returns {void}
 */
export const checkIfMarkerIsInViewport = ({
  viewportObject = null,

  dispatch,
  setViewState,
  viewState,
  changeViewState,

  DRAWER_MAX_MIN_WIDTH,
}) => {
  if (!isNilOrEmpty(viewportObject)) {
    const WIDTH_ADJUSTMENT = 50; // small adjustement for x mouse pos
    const x = pathOr(null, ['x'], viewportObject);
    const width = pathOr(null, ['viewport', 'width'], viewportObject);

    if (!isNilOrEmpty(width) && !isNilOrEmpty(x)) {
      const isInViewport =
        x + WIDTH_ADJUSTMENT > width - DRAWER_MAX_MIN_WIDTH ? false : true;
      if (!isInViewport && !isNilOrEmpty(viewState)) {
        const coordinate = pathOr([], ['coordinate'], viewportObject);
        if (isArrayNotEmpty(coordinate)) {
          if (typeof changeViewState === 'function') {
            changeViewState({
              latitude: coordinate[1],
              longitude: coordinate[0],
            });
          }
          else {
            dispatch(
              setViewState({
                ...viewState,
                latitude: coordinate[1],
                longitude: coordinate[0],
              }),
            );
          }
        }
      }
    }
  }
};

/**
 * Sets the info for the custom layer to be used for layers (e.g zoom prop)
 *
 * @param {object} layerConfigs - layer configs
 * @param {function} dispatch - redux dispatch
 * @param {function} setCustomLayersInfo - redux method
 * @returns {void}
 */
export const setStateLayerInfo = async (
  layerConfigs = [],
  dispatch,
  setCustomLayersInfo,
) => {
  if (!isArrayNotEmpty(layerConfigs)) return;

  const pathPromises = layerConfigs.map((layerConfig) => {
    return fetchDataFromUrl(
      createLayerMetadataUri(layerConfig),
      assembleRequestHeaders(layerConfig),
    ).then((response) => {
      return {
        layerConfig,
        response: response,
      }
    });
  });
  if (!isArrayNotEmpty(pathPromises)) return;

  const results = await Promise.all(pathPromises);
  if (!isArrayNotEmpty(results)) return;

  for (const result of results) {
    const layerConfig = result.layerConfig;
    const response = result.response;
    if (!layerConfig || !response) return;

    const layerId = layerConfig.id;
    const pathId = pathOr(null, ['id'], response);
    if (isNilOrEmpty(pathId) || pathId != layerConfig.pathId) return;

    const layerSource = layerConfig?.layerSource;
    const layerType = pathOr(null, ['type'], response);

    if (layerSource === LAYER_SOURCE.ELLIPSIS_DRIVE) {
      await resolveEllipsisStyle(layerConfig, response);
    }

    if (layerType !== 'vector') return;

    // every valid layer has at least one timestamp
    const timestamps = extractTimestamps(response);
    const maxZoom = timestamps.reduce(
      (min_zoom, timestamp) => {
        return Math.min(
          pathOr(MAX_ELLIPSIS_ZOOM_LEVEL, ['precompute', 'vectorTileZoom'], timestamp),
          min_zoom,
        )
      },
      MAX_ELLIPSIS_ZOOM_LEVEL
    );

    const mostCommonGeometryType = findMostCommonGeometryTypeFromTimestamps(
      timestamps
    );

    let styleUri;
    if (
      [
        MAP_LAYER_TYPE.TEMPLATE_MVT_LAYER,
        MAP_LAYER_TYPE.MVT_LAYER,
        MAP_LAYER_TYPE.MVT,
      ].includes(layerConfig.layerType)
    ) {
      if (layerConfig?.styleUri) {
        styleUri = layerConfig.styleUri;
      }
      else {
        styleUri = createLayerStyleUri(layerConfig);
        if (isArrayNotEmpty(timestamps)) {
          styleUri = styleUri.replace('{timestampId}', timestamps[0].id);
        }
      }
    }

    if (!styleUri) {
      dispatch(
        setCustomLayersInfo({
          maxZoom: maxZoom,
          geometryType: mostCommonGeometryType,
          layerId,
        }),
      );
      return;
    }

    const styleResponse = await fetchDataFromUrl(
      styleUri,
      assembleRequestHeaders(layerConfig),
    );
    dispatch(
      setCustomLayersInfo({
        maxZoom: maxZoom,
        geometryType: mostCommonGeometryType,
        layerInfo: styleResponse.layers,
        layerId,
      }),
    );
  }
};

export const resolveEllipsisStyle = async (layerConfig, layerMetadata) => {
  const styleId = pathOr(null, ['styleId'], layerConfig);
  if (styleId) return styleId;

  const pathType = layerMetadata.type;
  const styleConfig = pathOr(null, ['styleConfig'], layerConfig);
  const knownStyles = pathOr(
    [],
    ['styles'],
    getLayerTypeMetadata(layerMetadata)
  );

  const searchForStyle = (knownStyles, testFn) => {
    for (const style of knownStyles) {
      if (testFn(style) === true) return style;
    }
    return false;
  };

  const defaultStyle = searchForStyle(
    knownStyles,
    (style) => style.default === true
  );
  if (!styleConfig) {
    if (defaultStyle !== false) {
      layerConfig.styleId = defaultStyle.id;
      return layerConfig.styleId;
    }
  }

  const styleName = hash.sha1(styleConfig);
  const style = searchForStyle(
    knownStyles,
    (style) => style.name === styleName
  );
  if (style !== false) {
    layerConfig.styleId = style.id;
    return layerConfig.styleId;
  }

  const token = layerConfig.token;
  if (!token) throw Error('Cannot create style without Token');
  const headers = assembleRequestHeaders(layerConfig);
  headers['Content-Type'] = 'application/json';

  const url = new URL(createLayerMetadataUri(layerConfig));
  url.pathname += `/${pathType}/style`;

  const request = {
    url: url.toString(),
    method: 'POST',
    data: {
      ...styleConfig,
      name: styleName,
      default: false,
    },
    headers: headers,
  }
  try {
    const response = await axios(request);
    layerConfig.styleId = response.data.id;
    return layerConfig.styleId;
  }
  catch (e) {
    console.error(`Error creating Style for Layer. Using default style: ${layerConfig.id}`);
    console.error(e);
    if (defaultStyle !== false) {
      layerConfig.styleId = defaultStyle.id;
      return layerConfig.styleId;
    }
    else {
      console.error(`Default style not found. This Layer will probably not work: ${layerConfig.id}`);
      return;
    }
  }
}

export const findMostCommonGeometryTypeFromTimestamps = (timestamps) => {
  const totalGeometryFractions = timestamps.map(
    (timestamp) => {
      const geometryTypes = pathOr([], ['statistics', 'geometryTypes'], timestamp);
      const maxGeometryType = geometryTypes.reduce(
        (maxGeometryType, geometryType) => {
          if (maxGeometryType === undefined) return geometryType;
          if (geometryType.fraction > maxGeometryType.fraction) return geometryType;
          return maxGeometryType;
        },
        undefined
      );
      return maxGeometryType;
    }
  ).reduce(
    (totalFraction, maxGeometryType) => {
      if (maxGeometryType === undefined) return totalFraction;
      const fraction = (
        (maxGeometryType.type in totalFraction) ?
          totalFraction[maxGeometryType.type] :
          0
      );
      totalFraction[maxGeometryType.type] = fraction + maxGeometryType.fraction;
      return totalFraction;
    },
    {}
  );

  let mostCommonGeometryFraction;
  for (const [geometryType, totalFraction] of Object.entries(totalGeometryFractions)) {
    if (
        (mostCommonGeometryFraction === undefined) ||
        (totalFraction > mostCommonGeometryFraction[1])
    ) {
      mostCommonGeometryFraction = [geometryType, totalFraction];
    }
  }
  const mostCommonGeometryType = (
    mostCommonGeometryFraction.length > 0 ?
      mostCommonGeometryFraction[0].toLowerCase().trim() :
      undefined
  );

  return mostCommonGeometryType;
};

const hexToRGBArray = (hex) =>
  hex.match(/[A-Za-z0-9]{2}/g).map((v) => parseInt(v, 16));
const asRGBA = (mvtColor, opacityFloat) => {
  const rgba = hexToRGBArray(mvtColor);
  rgba.push(parseInt(opacityFloat * 255));
  return rgba;
};

const buildColorGetter = (color, opacity) => {
  /*
  !!! DO NOT MODIFY THE COLOR OR OPACITY VALUES !!!
  */

  if (typeof color === 'string') {
    return asRGBA(color, opacity);
  }
  else if (!(color instanceof Array)) {
    console.warn(`Unknown Color structure: ${color}`);
    return;
  }

  const expressionName = color[0];
  switch (expressionName) {
    case 'to-color':
      if (typeof color[1] === 'string') {
        return asRGBA(color[1], opacity);
      }
      else if (color[1] instanceof Array) {
        switch (color[1][0]) {
          case 'case':
            return buildCaseColorGetter(color[1], opacity);
          case 'match':
            return buildMatchColorGetter(color[1], opacity);
        }
      }

      console.error(`Unknown Color expression: ${expressionName} ${color} ${opacity}`);
      return;
    case 'case':
      return buildCaseColorGetter(color, opacity);
      break;
    default:
      console.error(`Unknown Color expression: ${expressionName} ${color} ${opacity}`);
      return;
  }

};

const buildMatchColorGetter = (decision, opacity) => {
  if (decision[0] !== 'match') {
    throw new Error(`Unsupported Color match statement: ${decision} ${opacity}`);
  }

  return (data) => {
    const inputValue = evaluateInput(decision[1], data);

    for (let idx = 2; idx < decision.length; idx += 2) {
      const testMatch = decision[idx];

      // the fallback expression
      if (idx === decision.length - 1) {
        return asRGBA(evaluateInput(testMatch, data), opacity);
      }
      else {
        const testMatch = decision[idx];
        const testOutput = decision[idx + 1];
        if (testMatch instanceof Array) {
          if (testMatch.indexOf(inputValue) !== -1) {
            return asRGBA(testOutput, opacity);
          }
        }
        else if (inputValue == testMatch) {
          return asRGBA(testOutput, opacity);
        }
      }
    }
  };
};

const buildCaseColorGetter = (decision, opacity) => {
  if (decision[0] !== 'case') {
    throw new Error(`Unsupported Color case statement: ${decision} ${opacity}`);
  }

  return (data) => {
    for (let idx = 1; idx < decision.length; idx += 2) {
      const testCondition = decision[idx];

      // the fallback expression
      if (idx === decision.length - 1) {
        return asRGBA(evaluateInput(testCondition, data), opacity);
      }
      else {
        const testOutput = decision[idx + 1];
        if (evaluateCondition(testCondition, data))
          return asRGBA(evaluateInput(testOutput, data), opacity);
      }
    }
  };
};

const evaluateCondition = (condition, data) => {
  let left;
  let right;

  const lookupOrDecision = condition[0];
  switch (lookupOrDecision) {
    case '!':
      return !evaluateCondition(condition[1], data);
      break;
    case 'has':
      return data.properties?.[condition[1]] !== undefined;
      break;
    case 'get':
      return data.properties[condition[1]];
    case '==':
    case '>=':
    case '>':
    case '<':
    case '<=':
    case '!=':
      left = evaluateInput(condition[1], data);
      right = evaluateInput(condition[2], data);
      switch (lookupOrDecision) {
        case '==':
          return numEq(left, right);
        case '>=':
          return numGte(left, right);
        case '>':
          return left > right;
        case '<':
          return left < right;
        case '<=':
          return numLte(left, right);
        case '!=':
          return !numEq(left, right);
      }
    default:
      throw new Error(`Unsupported lookup/decision: ${lookupOrDecision}`);
  }
};

const evaluateInput = (input, data) => {
  if (!(input instanceof Array)) {
    return input;
  }

  const lookup = input[0];
  switch (lookup) {
    case 'get':
      return data.properties[input[1]];
      break;
    case 'interpolate':
      return interpolateData(input, data);
    default:
      throw new Error(`Unsupported lookup: ${lookup}`);
  }

};

const interpolateData = (condition, data) => {
  if (condition[0] !== 'interpolate') {
    throw new Error(`Not 'interpolate': ${condition}`);
  }

  const d3ScaleFn = memoizedAssembleD3Scale(condition);
  const input = evaluateInput(condition[2], data);

  const value = color(d3ScaleFn(input));
  return value.formatHex();
}

const assembleD3Scale = (condition) => {
  const interpolationType = condition[1][0];
  switch (interpolationType) {
    case 'linear':
    case 'exponential':
      const domain = [];
      const range = [];
      for (let idx = 3; idx < condition.length; idx += 2) {
        domain.push(condition[idx]);
        range.push(condition[idx + 1]);
      }

      switch (interpolationType) {
        case 'linear':
          return scaleQuantile(domain, range);
          // strange but what we see in Ellipsis Drive isn't a linear scale
          //return scaleLinear(domain, range);
        case 'exponential':
          {
            const base = condition[1][1];
            return scalePow(domain, range).exponent(base);
          }
      }
    default:
      throw new Error(`Unsupported interpolation type: ${interpolationType}`);
  }
};

const memoizedAssembleD3Scale = memoize(assembleD3Scale);

export const createCustomLayerConfig = ({
  layerConfig,
  layerInfo,
  popupConfigRef,
  setPopupConfig,
  widgetConfigs,
  widgetConfigsHash,
  onViewportLoadedTiles,
}) => {
  if (isNilOrEmpty(layerInfo) || isNilOrEmpty(layerInfo.layerInfo)) return;
  const layerId = pathOr(null, ['id'], layerConfig);
  const geometryType = pathOr(null, ['geometryType'], layerInfo);
  const isPoint = geometryType.search('point') !== -1;
  const isPolygon = geometryType.search('polygon') !== -1;
  const allWidgetConfigs = widgetConfigs || [];

  const config = {
    layerName: layerConfig.name,
    pickable: false,
    maxZoom: pathOr(MAX_ELLIPSIS_ZOOM_LEVEL, ['maxZoom'], layerInfo),
    elevationScale: 5,
    extruded: false,
    filled: false,
    getFillColor: null,
    getLineColor: null,
    getLineWidth: null,
    getPointRadius: null,
    highlightColor: null, // TODO
    stroked: false,
    visible: false,
    wireframe: false,
  };

  // has at least one widget
  const hasWidget = allWidgetConfigs.findIndex(
    (widgetConfig) => widgetConfig?.layerId === layerId
  ) !== -1;

  if (hasWidget === true) {
    config.onViewportLoad = (tiles) => {
      onViewportLoadedTiles(layerId, tiles);
    };
  }

  //
  // styling
  //

  const pointTypes = []; // text, icon, circle

  layerInfo.layerInfo.forEach((style) => {
    const layout = pathOr({}, ['layout'], style);
    const paint = pathOr({}, ['paint'], style);
    config.visible = true;

    // TODO consider style.Filter

    switch (style.id) {
      case 'fill':
      case 'fillStyle':
        if (isPolygon || isPoint) {
          config.filled = true;
          config.getFillColor = buildColorGetter(
            paint['fill-color'],
            paint['fill-opacity'],
          )
        }
        break;
      case 'line':
      case 'lineStyle':
        if (!isPolygon && !isPoint) {
          config.stroked = true;
          config.getLineWidth = paint['line-width'];
          config.getLineColor = buildColorGetter(
            paint['line-color'],
            paint['line-opacity'],
          );
          config.lineMiter = 2;
          config.lineWidthUnits = 'pixels';
        }
        break;
      case 'point':
      case 'pointStyle':
        config.getPointRadius = paint['circle-radius'];
        if (isPoint) {
          pointTypes.push('circle');

          config.pointRadiusUnits = 'pixels';
        }
        break;
      case 'border':
      case 'borderStyle':
        if (isPolygon || isPoint) {
          config.stroked = true;
          config.getLineWidth = (
            pathOr(null, ['line-width'], paint) ||
            pathOr(null, ['line-width'], style) ||
            1
          );
          config.getLineColor = buildColorGetter(
            paint['line-color'],
            paint['line-opacity'],
          );
          config.lineMiter = 2;
          config.lineWidthUnits = 'pixels';
        }
        break;
      case 'point-icon-symbol':
        if (!isPoint) break;

        pointTypes.push('icon');

        let iconUri = layout['icon-image'];
        if (iconUri.startsWith('/')) {
          iconUri = `https://api.ellipsis-drive.com/v3${iconUri}`;
        }
        config.getIcon = (f) => {
          return {
            url: iconUri,
            height: 128,
            width: 128,
          };
        };
        config.iconSizeScale = 1;
        config.iconSizeUnits = 'common';
        config.iconSizeMinPixels = 16; // 2^4
        config.iconSizeMaxPixels = 32; // 2^5
        break;
      case 'point-symbol':
        if (!isPoint) break;

        pointTypes.push('text');

        config.getText = (f) => {
          let textValue = layout['text-field'];
          for (const [key, value] of Object.entries(f.properties)) {
            textValue = textValue.replaceAll('{' + key + '}', value);
          }
          return textValue;
        };
        config.getTextSize = 18;
        config.textSizeUnits = 'pixels';
        config.textSizeScale = 1;
        config.getTextColor = paint['text-color'];

        //config.textFontSettings = {
        //  sdf: true,
        //};
        //config.outlineWidth = 4;
        //config.textOutlineColor = [255, 255, 255, 255]; // TODO should be the inverse of text color

        //config.textBackground = true;
        //config.getTextBackgroundColor = [255, 255, 255, 255]; // TODO should be the inverse of text color
        break;
      case 'line-symbol':
      case 'fill-symbol':
        //console.warn(`Unsupported Style ID: ${style.id}`);
        break;
      default:
        console.warn(`Unknown Style ID: ${style.id}`);
    }
  });

  if (pointTypes.length > 0) {
    const hasText = pointTypes.indexOf('text') != -1;
    const hasIcon = pointTypes.indexOf('icon') != -1;
    let hasCircle = pointTypes.indexOf('circle') != -1;
    config.pointType = [
      hasText ? 'text' : null,
      hasIcon ? 'icon' : null,
      hasCircle ? 'circle' : null,
    ].filter((x) => x !== null).join('+');

    if (hasText) {
      config.getTextAnchor = 'middle';
      let yOffset;
      if (hasIcon) {
        yOffset = config.iconSizeMaxPixels;
      }
      else if (hasCircle) {
        yOffset = -1 * (config.getPointRadius + 5);
      }
      config.getTextPixelOffset = [0, yOffset];
    }
  }

  //
  // details (hover popup and details sidebar)
  //

  const formatFunctions = {};
  const buildInfoHTML = (
    { layer, object: feature, coordinate },
    trigger,
    columns,
  ) => {
    const measures = columns.map((columnConfig) => {
      const displayName = columnConfig.displayName || columnConfig.attributeName;
      let value = feature?.properties[columnConfig.attributeName];

      if (value === undefined) value = DOUBLE_DASH;
      if (value !== DOUBLE_DASH && columnConfig.formatId !== undefined) {
        if (formatFunctions[columnConfig.formatId] === undefined) {
          formatFunctions[columnConfig.formatId] = getFormatter(
            columnConfig.formatId,
            value,
          );
        }
        value = formatFunctions[columnConfig.formatId](value);
      }
      return `<div class="display-name">${displayName}</div><div class="value">${value}</div>`;
    });

    setPopupConfig({
      trigger,
      coordinate,
      innerHTML: `
<div class="content">
  <h3>
    <strong>${layer.props.layerName}</strong>
  </h3>
  ${measures.join('')}
</div>
      `,
    });
  };

  const details = pathOr({}, ['details'], layerConfig);
  const detailsEnabled = pathOr(false, ['enabled'], details);
  if (detailsEnabled !== true) return config;

  const detailsEvents = pathOr([], ['events'], details);
  const hover = detailsEvents.find((cfg) => cfg.type === 'hover');
  const click = detailsEvents.find((cfg) => cfg.type === 'click');

  if (hover !== undefined || click !== undefined) {
    config.pickable = true;
  }

  if (hover !== undefined) {
    const hoverColumns = pathOr([], ['columns'], hover);
    config.onHover = (info) => {
      if (popupConfigRef.current?.trigger !== MOUSE_EVENTS.CLICK) {
        buildInfoHTML(info, MOUSE_EVENTS.HOVER, hoverColumns);
      }
    };
  }
  if (click !== undefined) {
    const clickColumns = pathOr([], ['columns'], click);
    config.onClick = (info) => {
      if (popupConfigRef.current?.popupCloseClicked !== true) {
        buildInfoHTML(info, MOUSE_EVENTS.CLICK, clickColumns);
      } else {
        setPopupConfig(false);
      }
    };
  }

  return config;
};

export const conditionallyPickableFeatures = ({
  config,
  parameter,
  layerFilters,
  setPopupConfig,
  analytics,
  analyticsHash,
}) => {
  const originalHandler = config[parameter];
  const isLayerFiltersEmpty = isNilOrEmpty(layerFilters);

  if (isLayerFiltersEmpty) {
    config[parameter] = originalHandler;
  }
  else {
    config[parameter] = (info, evt) => {
      if (
        !isNilOrEmpty(info) &&
        !isFeatureFiltered({ // intentionally not using memoized as the serialization is more costly
          analytics,
          analyticsHash,
          layerFilters,
          properties: pathOr({}, ['object', 'properties'], info),
        })
      ) {
        return originalHandler(info);
      }

      if (parameter === 'onHover' && evt.type === 'pointermove') {
        setPopupConfig(false);
      }
      return;
    };
  }
};

export const conditionallyFilterFeatures = ({
  config,
  parameter,
  layerFilters,
  filteredValue,
  analytics,
  analyticsHash,
}) => {
  const originalValue = config[parameter];
  const originalValueIsFunction = typeof originalValue === 'function';
  const updateTriggers = pathOr({}, ['updateTriggers'], config);

  const originalValueHandler = (data) => {
    return (
      originalValueIsFunction
        ? originalValue(data)
        : originalValue
    );
  };

  const triggerForFeatures = getUpdateTriggerValue({analytics, layerFilters});

  updateTriggers[parameter] = triggerForFeatures;
  config.updateTriggers = updateTriggers;

  if (!isNilOrEmpty(triggerForFeatures)) {
    config[parameter] = (data) => {
      if (isNilOrEmpty(data)) return;

      // intentionally not using memoized as the serialization is more costly
      if (!isFeatureFiltered({
        analytics,
        analyticsHash,
        layerFilters,
        properties: pathOr({}, ['properties'], data),
      })) {
        return originalValueHandler(data);
      }

      return filteredValue;
    };
  }
  else {
    config[parameter] = originalValueHandler;
  }
};

export const getUpdateTriggerValue = ({analytics, layerFilters}) => {
  if (isNilOrEmpty(layerFilters)) return null;

  const triggerValueMembers = [];

  let widgetType;
  const widgetIds = Array.from(Object.keys(layerFilters));
  widgetIds.sort();

  for (const widgetId of widgetIds) {
    const widgetConfig = analytics.find(
      (widgetConfig) => widgetConfig.id === widgetId
    );
    const widgetFilter = layerFilters[widgetId];
    widgetType = widgetConfig.type;
    switch (widgetType) {
      case 'range':
      case 'histogram':
      case 'category':
        triggerValueMembers.push(widgetFilter.values);
        break;
      // formula is intentionally not here as that widget has no filtering component
      default:
        console.error(`Unsupported Widget type: ${widgetType}`)
        break;
    }
  }

  if (triggerValueMembers.length < 1) return null;
  return hash.sha1(triggerValueMembers);
}

export const isFeatureFiltered = ({
  analytics,
  analyticsHash,
  layerFilters,
  properties,
}) => {
  // safe option so that user can say "why is the data not being filtered?!"
  if (isNilOrEmpty(layerFilters)) return false;

  const widgetIds = Array.from(Object.keys(layerFilters));
  widgetIds.sort();

  for (const widgetId of widgetIds) {
    const widgetConfig = analytics.find(
      (widgetConfig) => widgetConfig.id === widgetId
    );
    const widgetFilter = layerFilters[widgetId];
    if (widgetFilter?.enabled === false) continue;

    let value, filterRange, result;

    const widgetType = widgetConfig.type;
    switch (widgetType) {
      case 'range':
        value = properties[widgetConfig.value];
        filterRange = widgetFilter.values;
        result = (
          numGte(value, filterRange[0]) && 
          numLte(value, filterRange[1])
        );
        if (!result) return true;
        break;
      case 'histogram':
        value = properties[widgetConfig.value];
        filterRange = widgetFilter.values;

        const inBins = () => {
          if (!isArrayNotEmpty(filterRange)) return true;

          for (let i = 0; i < filterRange.length; i++) {
            const bin = filterRange[i];
            if (i < filterRange.length - 1) {
              if (numGte(value, bin[0]) && value < bin[1]) return true;
            }
            else {
              if (numGte(value, bin[0]) && numLte(value, bin[1])) return true;
            }
          }

          return false;
        }
        result = inBins();
        if (!result) return true;
        break;
      case 'category':
        value = properties[widgetConfig.category.categories];
        filterRange = widgetFilter.values;
        if (filterRange.find((f) => f === value) === undefined) return true;
        break;
      // formula is intentionally not here as that widget has no filtering component
      default:
        console.error(`Unsupported Widget type: ${widgetType}`)
        break;
    }
  }

  // safe option so that user can say "why is the data not being filtered?!"
  return false;
};

export const memoizedIsFeatureFiltered = memoize(
  isFeatureFiltered,
  {
    serializer: (x) => hash.sha1({
      analyticsHash: x.analyticsHash,
      layerFilters: x.layerFilters,
      properties: x.properties,
    }),
  }
);

export const extractValuesFromFeatures = ({
  features,
  featuresHash,
  valueField,
  uniqueId,
  layerFilters,
  layerWidgetConfigs,
  layerWidgetConfigsHash,
}) => {
  const valuesMap = new Map();
  const hasUniqueId = !isNilOrEmpty(uniqueId);

  for (let i = 0; i < features.length; i++) {
    const feature = features[i];
    const v = feature.properties[valueField];

    if (isNilOrEmpty(v)) continue;
    if (
      !isNilOrEmpty(layerFilters) &&
      !isNilOrEmpty(layerWidgetConfigs) &&
      isFeatureFiltered({
        analytics: layerWidgetConfigs,
        analyticsHash: layerWidgetConfigsHash,
        layerFilters,
        properties: feature.properties,
      })
    ) continue;

    valuesMap.set(
      (
        hasUniqueId
          ? feature.properties[uniqueId]
          : i
      ),
      feature.properties[valueField]
    );
  }

  const values = Array.from(valuesMap.values());
  return values;
};

export const memoizedExtractValuesFromFeatures = memoize(
  extractValuesFromFeatures,
  {
    serializer: (x) => hash.sha1({
      featuresHash: x.featuresHash,
      layerFilters: x.layerFilters,
      layerWidgetConfigsHash: x.layerWidgetConfigsHash,
      uniqueId: x.uniqueId,
      valueField: x.valueField,
    }),
  }
);

export const computeHashFromTilesAndViewport = (tileIds, bounds) => {
  const _tileIds = Array.from(tileIds);
  _tileIds.sort();
  const _bounds = bounds?.bbox || bounds?.geometry;

  return hash.sha1({
    tileIds: _tileIds,
    bounds: _bounds,
  });
};

const _getFormatter = (formatId, sampleValue) => {
  const formatter = (
    isNilOrEmpty(formatId)
      ?  null
      : getFormatter(
        formatId,
        sampleValue,
      )
  );
  return formatter;
}

const _computeBounds = (min, max, numBins) => {
  const bounds = [];
  let from_ = min;
  for (let i = 1; i <= numBins; i++) {
    const up_to = min + (max - min) * (i / numBins);
    bounds.push([from_, up_to]);
    from_ = up_to;
  }
  return bounds;
};

const _computeValuesStat = ({values, valuesHash, stat}) => {
  switch (stat) {
    case 'sum':
    case 'avg':
      let sum = 0;
      // basic for loop is fastest: https://jsbench.me/xal9odbag2/1
      for (let i = 0; i < values.length; i++) sum += values[i];
      if (stat === 'sub') return sum;

      const avg = sum / values.length;
      return avg;
    case 'min':
      return Math.min(...values);
    case 'max':
      return Math.max(...values);
  };
};

const _memoizedComputeValuesStat = memoize(
  _computeValuesStat,
  {
    serializer: (x) => hash.sha1({
      valuesHash: x.valuesHash,
      stat: x.stat,
    }),
  },
);

const _createHistogramWidgetConfig = (features, featuresHash, rawWidgetConfig) => {
  const valueField = rawWidgetConfig.value;
  const uniqueId = rawWidgetConfig?.uniqueId;
  const valuesHash = hash.sha1({featuresHash, valueField, uniqueId});
  // TODO features are only of the viewport. need to support `rawWidgetConfig.viewport === false`
  const values = memoizedExtractValuesFromFeatures({
    features,
    featuresHash,
    valueField,
    uniqueId,
  });

  const typeConfig = pathOr({}, [rawWidgetConfig.type], rawWidgetConfig);
  const numBins = pathOr(null, ['bins'], typeConfig) || 10;
  let minValue = pathOr(null, ['min'], typeConfig);
  let maxValue = pathOr(null, ['max'], typeConfig);

  if (isNilOrEmpty(minValue)) {
    minValue = _memoizedComputeValuesStat({
      values,
      valuesHash,
      stat: 'min',
    });
  }
  if (isNilOrEmpty(maxValue)) {
    maxValue = _memoizedComputeValuesStat({
      values,
      valuesHash,
      stat: 'max',
    });
  }

  // TODO support clampToMin/Max and excludeMin/Max

  const binBounds = _computeBounds(minValue, maxValue, numBins);

  const binCounts = Array(binBounds.length).fill(0);
  const lastBinCountIndex = binCounts.length - 1;
  values.forEach((value) => {
    let binIndex = binBounds.findIndex(
      (binBound) => value < binBound[1]
    );
    if (binIndex === -1) binIndex = lastBinCountIndex;
    binCounts[binIndex]++;
  });

  const formatId = pathOr(null, ['formatId'], rawWidgetConfig);
  const formatter = _getFormatter(formatId, minValue);

  const config = {
    bins: numBins,
    min: minValue,
    max: maxValue, 
    xAxisFormatter: formatter,
    binBounds: binBounds,
    binCounts: binCounts,
    isExternallyLoading: false,
  };

  return config;
};

const _createCategoryWidgetConfig = (features, featuresHash, rawWidgetConfig) => {
  const valueField = rawWidgetConfig.value;
  const uniqueId = rawWidgetConfig?.uniqueId;
  const valuesHash = hash.sha1({featuresHash, valueField, uniqueId});

  const typeConfig = pathOr({}, [rawWidgetConfig.type], rawWidgetConfig);
  const categoriesField = typeConfig.categories;
  const operation = pathOr('count', ['operation'], typeConfig);

  // TODO features are only of the viewport. need to support `rawWidgetConfig.viewport === false`
  const categories = memoizedExtractValuesFromFeatures({
    features,
    featuresHash,
    valueField: categoriesField,
    uniqueId,
  });

  let counts = {};
  let sampleValue = 1;
  if (operation !== 'count') {
    // TODO features are only of the viewport. need to support `rawWidgetConfig.viewport === false`
    const values = memoizedExtractValuesFromFeatures({
      features,
      featuresHash,
      valueField,
      uniqueId,
    });

    const grouped = {}
    for (let i = 0; i < categories.length; i++) {
      grouped[categories[i]] = pathOr([], [categories[i]], grouped);
      grouped[categories[i]].push(values[i]);
    }

    const groupedKeys = Object.keys(grouped);
    for (let i = 0; i < groupedKeys.length; i++) {
      const key = groupedKeys[i];
      counts[key] = _memoizedComputeValuesStat({
        values: grouped[key],
        valuesHash: valuesHash + key,
        stat: operation,
      });
      sampleValue = counts[key];
    }
  }
  else {
    for (let i = 0; i < categories.length; i++) {
      counts[categories[i]] = pathOr(0, [categories[i]], counts) + 1;
    }
  }

  // sort counts
  counts = Object.entries(counts).sort(
    ([,a],[,b]) => a - b
  ).map((entry) => {
    return {
      name: entry[0],
      value: entry[1],
    }
  });

  const formatId = pathOr(null, ['formatId'], rawWidgetConfig);
  const formatter = _getFormatter(formatId, sampleValue);

  const config = {
    counts: counts,
    order: 'fixed',
    formatter: formatter,
    isExternallyLoading: false,
  };

  return config;
};

const _createFormulaWidgetConfig = (
  features,
  featuresHash,
  rawWidgetConfig,
  layerFilters,
  layerWidgetConfigs,
  layerWidgetConfigsHash,
) => {
  const typeConfig = pathOr({}, [rawWidgetConfig.type], rawWidgetConfig);
  const operation = pathOr('count', ['operation'], typeConfig);

  let valueField = rawWidgetConfig.value;
  const uniqueId = rawWidgetConfig?.uniqueId;
  const valuesHash = hash.sha1({
    featuresHash,
    valueField,
    uniqueId,
    layerFilters,
    layerWidgetConfigsHash,
  });

  let values;
  if (operation === 'count') {
    if (isNilOrEmpty(uniqueId)) {
      values = features;
    }
    else {
      valueField = uniqueId;
    }
  }

  // TODO features are only of the viewport. need to support `rawWidgetConfig.viewport === false`
  values = (
    isNilOrEmpty(values) ?
      memoizedExtractValuesFromFeatures({
        features,
        featuresHash,
        valueField,
        uniqueId,
        layerFilters,
        layerWidgetConfigs,
        layerWidgetConfigsHash,
      }) :
      values
  );

  let computedValue = undefined;
  switch (operation) {
    case 'custom':
      // TODO review best practice of doing this outside of eval()
      break;
    case 'sum':
    case 'min':
    case 'max':
    case 'avg':
      computedValue = _memoizedComputeValuesStat({
        values,
        valuesHash,
        stat: operation,
      });
      break;
    case 'count':
    default:
      computedValue = values.length;
      break;
  }

  const formatId = pathOr(null, ['formatId'], rawWidgetConfig);
  const formatter = _getFormatter(formatId, computedValue);

  const config = {
    computedValue: computedValue,
    isExternallyLoading: false,
    formatter: formatter,
  }

  return config;
}

const _createRangeWidgetConfig = (features, featuresHash, rawWidgetConfig) => {
  const valueField = rawWidgetConfig.value;
  const uniqueId = rawWidgetConfig?.uniqueId;
  const valuesHash = hash.sha1({featuresHash, valueField, uniqueId});
  // TODO features are only of the viewport. need to support `rawWidgetConfig.viewport === false`
  const values = memoizedExtractValuesFromFeatures({
    features,
    featuresHash,
    valueField,
    uniqueId,
  });

  const typeConfig = pathOr({}, [rawWidgetConfig.type], rawWidgetConfig);
  const ticks = pathOr(10, ['ticks'], typeConfig);
  let minValue = pathOr(undefined, ['min'], typeConfig); // must use `undefined`
  let maxValue = pathOr(undefined, ['max'], typeConfig); // must use `undefined`
  let step;

  if (values.length > 0) {
    if (isNilOrEmpty(minValue)) {
      minValue = _memoizedComputeValuesStat({
        values,
        valuesHash,
        stat: 'min',
      });
    }
    if (isNilOrEmpty(maxValue)) {
      maxValue = _memoizedComputeValuesStat({
        values,
        valuesHash,
        stat: 'max',
      });
    }

    step = (maxValue - minValue) / ticks;
  }

  // TODO support clampToMin/Max and excludeMin/Max

  const formatId = pathOr(null, ['formatId'], rawWidgetConfig);
  const formatter = _getFormatter(formatId, minValue);

  const config = {
    min: minValue,
    max: maxValue, 
    formatter: formatter,
    isExternallyLoading: false,
    step: step,
    shiftStep: step / 10,
  };

  return config;
}

const SmallTextBox = styled(Box)(({ theme }) => ({
  fontSize: '0.75em',
}));

const smallTextBox = (value) => {
  if (isNilOrEmpty(value)) return undefined;
  return <SmallTextBox>{value}</SmallTextBox>;
};

export const createWidgetConfig = ({
  features = [],
  featuresHash = undefined,
  rawWidgetConfig = null,
  layerFilters,
  layerWidgetConfigs,
  layerWidgetConfigsHash,
}) => {
  if (isNilOrEmpty(rawWidgetConfig)) return {};

  let typeConfig = {};
  let createWidgetConfigFn;
  switch (rawWidgetConfig.type) {
    case 'histogram':
      createWidgetConfigFn = _createHistogramWidgetConfig;
      break;
    case 'category':
      createWidgetConfigFn = _createCategoryWidgetConfig;
      break;
    case 'formula':
      createWidgetConfigFn = _createFormulaWidgetConfig;
      break;
    case 'range':
      createWidgetConfigFn = _createRangeWidgetConfig;
      break;
    default:
      console.error(`Unsupported Widget type: ${rawWidgetConfig.type}`)
      break;
  }

  if (createWidgetConfigFn) {
    typeConfig = createWidgetConfigFn(
      features,
      featuresHash,
      rawWidgetConfig,
      layerFilters,
      layerWidgetConfigs,
      layerWidgetConfigsHash,
    );
  }

  if (isNilOrEmpty(typeConfig)) return {};

  const config = {
    id: rawWidgetConfig.id,
    title: rawWidgetConfig.name,
    notes: smallTextBox(rawWidgetConfig?.description),
    footer: smallTextBox(rawWidgetConfig?.footer),
    layerId: rawWidgetConfig.layerId,
    type: rawWidgetConfig.type,
    addExternalFilter: modifyLayerFilters,
    addExternalFilterExtras: {
      layerId: rawWidgetConfig.layerId,
    },
    externalFiltersSelector: (state) => {
      let values = pathOr(
        undefined,
        [
          'app',
          'layerFilters',
          'current',
          rawWidgetConfig.layerId,
          rawWidgetConfig.id,
          'values'
        ],
        state
      );
      return values;
    },
    ...typeConfig,
  }

  return config;
};

export const memoizedCreateWidgetConfig = memoize(
  createWidgetConfig,
  {
    serializer: (x) => hash.sha1({
      featuresHash: x.featuresHash,
      layerFilters: x.layerFilters,
      widgetConfig: x.rawWidgetConfig,
      layerWidgetConfigsHash: x.layerWidgetConfigsHash,
    }),
  },
);

export const doesLayerHaveFilters = ({
  layerId,
  layerFilters,
  onlyActiveFilters = false,
}) => {
  const hasFilters = Object.keys(layerFilters).length > 0;
  if (hasFilters) {
    if (!onlyActiveFilters) return true;

    for (const widgetFilter of Object.values(layerFilters)) {
      if (widgetFilter?.enabled !== false) return true;
    }
  }

  return false;
};

export const conditionallyUpdateMapTriggers = ({
  config,
  parameters,
  currentFilters,
  priorFilters,
}) => {
  const setMapTriggers = () => {
    const updateTriggers = pathOr({}, ['updateTriggers'], config);
    const value = Math.random(); // just need a junk random value

    for (let i = 0; i < parameters.length; i++) {
      const parameter = parameters[i];
      if (updateTriggers?.[parameter]) continue;
      updateTriggers[parameter] = value;
    }
    config.updateTriggers = updateTriggers;

    return; // intentional to show that we really mean to return nothing
  }

  for (const widgetId in priorFilters) {
    const currentFilter = currentFilters?.[widgetId];
    if (currentFilter === undefined) return setMapTriggers();
    const priorFilter = priorFilters[widgetId];

    if (priorFilter?.enabled !== currentFilter?.enabled) return setMapTriggers();
  }
};
