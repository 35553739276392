import { useState } from 'react';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { makeStyles } from '@material-ui/core/styles';

import Header from '@/components/Header/Header';
import { OrderPreview as WamOrderPreview } from '@/components/Wam/OrderPreview';
import { WAM } from '@/components/Wam/constants';
import { unwindWamOrder } from '@/components/Wam/utils';

import useEnvs from '@/hooks/calls/useEnvs';
import useGuards from '@/hooks/useGuards';
import { useSignedUrls } from '@/hooks/useSignedUrls';

import { DEFAULT_HEADER_HEIGHT, } from '@/utils/constants';
import { isNilOrEmpty } from '@/utils/validator';
import paths from '@/constants/paths';

const useStyles = makeStyles((theme) => ({
  detailsWrapper: (props) => ({
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: `calc(100% - ${props.headerHeight}px)`,
    margin: '0 10px',
  }),
}));

const OrderDetails = () => {
  const [ headerHeight, setHeaderHeight ] = useState(0);
  const { hasFeature } = useGuards();
  const { getSignedUrls } = useSignedUrls();

  const { getCurrentEnvConfig } = useEnvs();
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const currentEnvConfig = getCurrentEnvConfig(currentEnv);

  const classes = useStyles({
    headerHeight,
  });

  let originalProductConfig = [];
  let formState;
  let calculations;
  let fragment = <></>;
  switch (pathOr('default', ['envType'], currentEnvConfig)) {
    case WAM:
      if (hasFeature('WAM')) {
        [originalProductConfig, formState, calculations] = unwindWamOrder(
          currentEnvConfig
        );
        break;
      }
    default:
      window.location = paths.home;
      break;
  }

  const {
    data: signedUrls,
    isSuccess: areSignedUrlsReady,
  } = useSignedUrls(
    isNilOrEmpty(pathOr({}, ['legal'], originalProductConfig)) ?
      [] :
      assembleSignedUrlRequests(originalProductConfig.legal)
  );

  switch (pathOr('default', ['envType'], currentEnvConfig)) {
    case WAM:
      const productConfig = {
        ...originalProductConfig,
        legal: {
          ...originalProductConfig.legal,
        },
      };

      if (areSignedUrlsReady && !isNilOrEmpty(signedUrls)) {
        signedUrls.forEach(
          (signedUrl) => {
            for (const [key, value] of Object.entries(originalProductConfig.legal)) {
              if (!key.includes('Path')) continue;
              if (value !== signedUrl.path) continue;
              productConfig.legal[key.replace('Path', '')] = signedUrl.uri;
            }
          }
        );
      }

      fragment = <WamOrderPreview
        formState={formState}
        productConfig={productConfig}
      />;
      break;
  }

  const getHeaderHeight = (node) => {
    if (isNilOrEmpty(node)) return;
    setHeaderHeight(
      pathOr(
        DEFAULT_HEADER_HEIGHT,
        ['height'],
        node.getBoundingClientRect(),
      )
    );
  };

  return (
    <>
      <div ref={getHeaderHeight}>
        <Header />
      </div>
      <div className={`${classes.detailsWrapper}`}>
        {fragment}
      </div>
    </>
  );
};

export default OrderDetails;

const assembleSignedUrlRequests = (config) => {
  const toGet = [];
  for (const [key, value] of Object.entries(config)) {
    if (
      typeof value !== 'string' ||
      !value.includes('X-Goog-Expires=') // is a signed url
    ) {
      continue;
    }

    toGet.push({
      path: pathOr(null, [`${key}Path`], config),
      generation: pathOr(null, [`${key}Generation`], config),
    });
  }

  return toGet;
};
