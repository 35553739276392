import { useState } from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import Fieldset from '@/components/Fieldset';
import GeoJSONMap from '@/components/GeoJSONMap';
import { BASE_MAP_CONFIGS } from '@/components/Map/utils/basemap';
import Text from '@/components/Text';

import { isNilOrEmpty } from '@/utils/validator';

import { computeEstimatedArea } from '../utils';

import {
  ALL_YEARS,
  ALL_QUARTERS,
  INPUT_KEYS,
  REQUIRED_HELPER_TEXT,
} from '../constants';

const Row = styled.div`
  margin-bottom: 0.5em;
`;

const Header = styled(Text)`
  font-weight: bold;
  font-size: 1.1em;
`;

const BoldCell = styled(TableCell)`
  font-weight: bold;
`;

const CellRight = styled(TableCell)`
 text-align: right;
`;

const BoldCellRight = styled(CellRight)`
  font-weight: bold;
`;

const REQUIRED_KEYS = [
  INPUT_KEYS.TERMS,
  INPUT_KEYS.LICENSE,
];

export const validateFormState = ({
  formState,
  hasError,
  setHasError,
}) => {
  const newHasError = Object.fromEntries(
    REQUIRED_KEYS.map(
      (key) => [
        key,
        pathOr(false, [key], formState) !== true ?
          REQUIRED_HELPER_TEXT :
          false
      ]
    )
  );
  const anyError = Object.values(newHasError).some((v) => v !== false);

  setHasError({
    ...hasError,
    ...newHasError
  });

  return !anyError;
};

export const OrderPreview = ({
  formState,
  setFormState,
  hasError = {},
  setHasError = () => {},
  calculationsRef = {},
  productConfig,
}) => {
  const theme = useTheme();
  const [legalDialogUri, setLegalDialogUri] = useState(false);
  const isReadOnly = isNilOrEmpty(setFormState);

  const closeLegalDialog = () => {
    setLegalDialogUri(false);
  };

  const onCheckLegal = (keyword) => {
    if (isReadOnly) {
      return () => {};
    }

    const wrapper = (evt) => {
      const isChecked = evt.target.checked;
      const newFormState = {
        ...formState,
        [keyword]: isChecked,
      };

      setFormState(newFormState);

      setHasError({
        ...hasError,
        [keyword]: (
          isChecked ?
            false :
            REQUIRED_HELPER_TEXT
        ),
      });
    };

    return wrapper;
  };

  const getValidQuarters = () => {
    const startYear = formState[INPUT_KEYS.START_YEAR];
    const startQuarter = formState[INPUT_KEYS.START_QUARTER];
    const endYear = formState[INPUT_KEYS.END_YEAR];
    const endQuarter = formState[INPUT_KEYS.END_QUARTER];

    const allQuarters = []
    for (let year = startYear; year <= endYear; year++) {
      const sq = year === startYear ? startQuarter : 1;
      const eq = year === endYear ? endQuarter : 4;
      for (let q = sq; q <= eq; q++) {
        allQuarters.push([year, q]);
      }
    }

    return allQuarters;
  };

  const showLegal = (uri) => {
    const wrapperFn = (evt) => {
      evt.stopPropagation();
      evt.preventDefault();
      setLegalDialogUri(uri);
    };

    return wrapperFn;
  };

  const allQuarters = getValidQuarters();
  const estimatedArea = computeEstimatedArea({
    features: pathOr([], [INPUT_KEYS.AOI], formState),
  });

  const monitoringCost = (
    allQuarters.length *
      estimatedArea *
      productConfig.pricing.usd.wam
  );

  const totalCost = (
    monitoringCost +
      productConfig.pricing.usd.reports +
      productConfig.pricing.usd.alerts
  );

  calculationsRef.current = {
    legal: {
      terms: productConfig.legal.terms,
      license: productConfig.legal.license,
    },
    details: {
      quarters: allQuarters,
      area: {
        quantity: estimatedArea,
        unit: 'km2',
      },
    },
    costs: {
      monitoring: {
        quantity: monitoringCost,
        unit: 'USD',
      },
      reports: {
        quantity: productConfig.pricing.usd.reports,
        unit: 'USD',
      },
      alerts: {
        quantity: productConfig.pricing.usd.alerts,
        unit: 'USD',
      },
    },
  };

  const numberFormatter = new Intl.NumberFormat();
  const currencyFormatter = new Intl.NumberFormat(
    undefined,
    {
      style: 'currency',
      currency: 'USD',
    }
  );

  return (
    <div style={{ width: '100%', height: '100%', fontSize: 14 }}>
      <Box style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '500px',
            overflowY: 'auto',
          }}
        >
          <Fieldset
            legend='Details'
          >
            <Row>
              <Header>Project Name</Header>
              <Text>{formState[INPUT_KEYS.NAME]}</Text>
            </Row>
            <Row>
              <Header>Description</Header>
              <Text>{pathOr('--', [INPUT_KEYS.DESCRIPTION], formState)}</Text>
            </Row>
            <Row>
              <Header>Monitor from</Header>
              <Text>{formState[INPUT_KEYS.START_YEAR]}, Quarter {formState[INPUT_KEYS.START_QUARTER]}</Text>
            </Row>
            <Row>
              <Header>Monitor through</Header>
              <Text>{formState[INPUT_KEYS.END_YEAR]}, Quarter {formState[INPUT_KEYS.END_QUARTER]}</Text>
            </Row>
            <Row>
              <Header>Domain</Header>
              <Text>Built Environment (included in Base)</Text>
            </Row>
            <Row>
              <Header>Visualization</Header>
              <Text>Default (included in Base)</Text>
            </Row>
          </Fieldset>

          <Box sx={{ flexGrow: 1 }}/>

          <Fieldset
            legend='Pricing'
          >
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell scope='row' colSpan={2}>
                    <div>Monitoring</div>
                  </TableCell>
                  <CellRight>
                    {currencyFormatter.format(monitoringCost)}
                  </CellRight>
                </TableRow>
                <TableRow>
                  <TableCell/>
                  <TableCell>
                    <div>
                      {numberFormatter.format(estimatedArea)} km<sup>2</sup>
                    </div>
                    <div>{allQuarters.length} quarters</div>
                    <div>
                      {currencyFormatter.format(productConfig.pricing.usd.wam)} per km<sup>2</sup> per quarter
                    </div>
                  </TableCell>
                  <TableCell/>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Reports
                  </TableCell>
                  <CellRight>
                    {currencyFormatter.format(productConfig.pricing.usd.reports)}
                  </CellRight>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>
                    Alerts
                  </TableCell>
                  <CellRight>
                    {currencyFormatter.format(productConfig.pricing.usd.alerts)}
                  </CellRight>
                </TableRow>
                <TableRow>
                  <BoldCell colSpan={2}>
                    Estimated Total
                  </BoldCell>
                  <BoldCellRight>
                    {currencyFormatter.format(totalCost)}
                  </BoldCellRight>
                </TableRow>
              </TableBody>
            </Table>
          </Fieldset>

          <Box style={{ marginTop: '10px' }}>
            <FormControl
              required
              error={!!pathOr(false, [INPUT_KEYS.TERMS], hasError)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!pathOr(false, [INPUT_KEYS.TERMS], formState)}
                    onChange={onCheckLegal(INPUT_KEYS.TERMS)}
                    style={{
                      color: (
                        !!pathOr(false, [INPUT_KEYS.TERMS], hasError) ?
                          theme.palette.error.main :
                          undefined
                      )
                    }}
                  />
                }
                label={
                  <>
                    <span>I agree to the </span>
                    <Link
                      href='#'
                      onClick={showLegal(productConfig.legal.terms)}
                    >
                      Terms & Conditions
                    </Link>
                  </>
                }
              />
              {(typeof pathOr(false, [INPUT_KEYS.TERMS], hasError) === 'string') &&
                <FormHelperText>{hasError[INPUT_KEYS.TERMS]}</FormHelperText>
              }
            </FormControl>
            <FormControl
              required
              error={!!pathOr(false, [INPUT_KEYS.LICENSE], hasError)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!pathOr(false, [INPUT_KEYS.LICENSE], formState)}
                    onChange={onCheckLegal(INPUT_KEYS.LICENSE)}
                    style={{
                      color: (
                        !!pathOr(false, [INPUT_KEYS.LICENSE], hasError) ?
                          theme.palette.error.main :
                          undefined
                      )
                    }}
                  />
                }
                label={
                  <>
                    <span>I agree to the </span>
                    <Link
                      href='#'
                      onClick={showLegal(productConfig.legal.license)}
                    >
                      License Agreement
                    </Link>
                  </>
                }
              />
              {(typeof pathOr(false, [INPUT_KEYS.LICENSE], hasError) === 'string') &&
                <FormHelperText>{hasError[INPUT_KEYS.LICENSE]}</FormHelperText>
              }
            </FormControl>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1, m: 1 }}>
          <GeoJSONMap
            features={Object.values(formState[INPUT_KEYS.AOI])}
            boxStyle={{
              height: '100%',
              width: '100%',
              border: 'solid 1px rgb(192, 192, 192)',
              borderRadius: '4px',
            }}
            mapStyle={BASE_MAP_CONFIGS[0].uri}
          />
        </Box>
      </Box>
      <LegalDialog
        uri={legalDialogUri}
        onClose={closeLegalDialog}
      />
    </div>
  );
};

const LegalDialog = ({
  uri,
  onClose,
}) => {
  return (
    <Dialog
      open={!!uri}
      onClose={onClose}
      maxWidth={false}
    >
      <DialogContent>
        {typeof uri === 'string' && uri.startsWith('https://') ?
          <iframe
            src={uri}
            style={{
              width: '70vw',
              height: '70vh',
              border: '1px solid lightgray',
            }}
          /> :
          <div>{uri}</div>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant='outlined'>Close</Button>
      </DialogActions>
    </Dialog>
  );
};
