import React, { useEffect } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { pathOr } from 'ramda';

import useEnvs from '@/hooks/calls/useEnvs';
import paths from '@/constants/paths';

import { setCurrentEnv, setProjectView } from '@/store/appSlice';
import { views } from '@/constants/env';
import { redirectIfAnyDeckGlProject } from '@/utils/redirect';

import Environment from './Environment';

const EnvironmentsManagement = () => {
  const location = useLocation();
  const [currentEnv, projectView] = useSelector((state) => [
    state.app.currentEnv,
    state.app.projectView,
  ]);
  const dispatch = useDispatch();

  const { data } = useEnvs();
  const envList = pathOr([], ['envs'], data);
  const isYourEnvironments = location.pathname === paths.yourEnvironments;

  useEffect(() => {
    if (envList.length < 1) return;

    redirectIfAnyDeckGlProject(envList);

    // if we are in env page we don't set a default env
    if (isYourEnvironments) return;

    let envId = '';
    const envItem = envList.find((env) => env.id === currentEnv);
    if (!envItem) {
      envId = envList[0].id;
    } else {
      envId = envItem.id;
    }
    dispatch(setCurrentEnv(envId));

    // when changing envs, we always default to "MyView"
    dispatch(setProjectView(views.MY_VIEW));
  }, [envList]);

  if (isYourEnvironments) return null;

  return (
    <div>
      {currentEnv && (
        <Environment
          key={currentEnv}
          currentEnvId={currentEnv}
          currentProjectView={projectView}
        />
      )}
    </div>
  );
};

export default withAuthenticationRequired(EnvironmentsManagement);
