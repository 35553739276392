import 'mapbox-gl/dist/mapbox-gl.css';

import { useEffect, useRef, useState } from 'react';
import Map, { Popup, Marker } from 'react-map-gl';
import { useDispatch, useSelector } from 'react-redux';
import { setBasemap, setViewState } from '@carto/react-redux';
import DeckGL from '@deck.gl/react';

import { BASEMAPS, DARK_MATTER } from '@carto/react-basemaps';
import { useMediaQuery, useTheme, makeStyles } from '@material-ui/core';
import { pathOr, has } from 'ramda';
import debounce from 'lodash.debounce';

// mui
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// utils
import {
  removeMapLogo,
  appendNewLayer,
  createCustomLayerConfig,
  orderLayerIds,
  stripHTMLTooltip,
  checkIfMarkerIsInViewport,
  toggleDrawer,
  conditionallyFilterFeatures,
  conditionallyPickableFeatures,
  doesLayerHaveFilters,
  conditionallyUpdateMapTriggers,
} from '@/utils/map-utils';
// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

// store
import { addLoadedLayers } from '@/store/appSlice';

// hooks
import { useMapHooks } from './useMapHooks';

// constants
import {
  MAPBOX_MAPS,
  MAP_STYLES,
  DEFAULT_MAP_STYLE,
} from '@/utils/basemap-config';

import {
  LAYER_SOURCE,
  MAP_LAYER_TYPE,
  MAP_POPUP_INDEX_REMOVE,
  DRAWER_ANCHOR,
  DRAWER_MAX_MIN_WIDTH,
  CUSTOM_MAP_STYLES,
  MARKER_COLORS,
  MOUSE_EVENTS,
  DEBOUNCE_WIDGET_TIME_VALUE,
} from '@/utils/constants';

// components
import DOMPurifiedHTML from '../../common/DOMPurifiedHTML';
import getBuilderLayers from '../common/BuilderLayers';
import TileLayerComponent from '../common/TileLayer';
import MVTLayerComponent from '../common/MVTLayer';

// styles
const useStyles = makeStyles((theme) => ({
  drawerBoxContainer: {
    padding: '10px 20px',
    minWidth: `${DRAWER_MAX_MIN_WIDTH}px`,
    maxWidth: `${DRAWER_MAX_MIN_WIDTH}px`,
    '& .content': {
      '& .display-name': {
        fontSize: '11px',
        color: theme.palette.grey[600],
        wordBreak: 'break-all',
        marginBottom: '3px',
      },
      '& .value': {
        fontSize: '14px',
        color: theme.palette.common.black,
        marginBottom: '10px',
        paddingBottom: '10px',
        wordBreak: 'break-all',
        borderBottom: `solid 1px ${theme.palette.grey[200]}`,
        '&:last-child': {
          borderBottom: 'none 0',
        },
      },
    },
  },
  closeButtonContainer: {
    textAlign: 'right',
  },
  popup: {
    zIndex: 999,
    opacity: 1,
    '& .content': {
      minWidth: '200px',
      maxWidth: '500px',
      '& div': {
        padding: '0.1rem 0.5rem',
        width: '100%',
      },
      '& div.display-name': {
        color: 'gray',
        textAlign: 'left',
      },
      '& div.value': {
        textAlign: 'right',
      },
    },
  },
}));

const LAYER_FILTER_HANDLER_CONFIGS = {
  getFillColor: {
    handler: conditionallyFilterFeatures,
    filteredValue: [0, 0, 0, 0],
  },
  getLineColor: {
    handler: conditionallyFilterFeatures,
    filteredValue: [0, 0, 0, 0],
  },
  getPointRadius: {
    handler: conditionallyFilterFeatures,
    filteredValue: 0,
  },
  getIconSize: {
    handler: conditionallyFilterFeatures,
    filteredValue: 0,
  },
  getTextSize: {
    handler: conditionallyFilterFeatures,
    filteredValue: 0,
  },
  onHover: {
    handler: conditionallyPickableFeatures,
    filteredValue: 0,
  },
  onClick: {
    handler: conditionallyPickableFeatures,
    filteredValue: 0,
  },
};


// deckgl main component
export default function DeckGLComponent(props) {
  const {
    mapConfig,
    mapState,
    resetMapState,
    newMapStateFlag,
    setNewMapStateFlag,
    setIsMapLoading,
    currentBasemapStyles,
    analytics,
    analyticsHash,
    updateLoadedTiles,
    filtersForCustomLayers,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { handleCursor, handleSizeChange, handleViewStateChange, handleHover } =
    useMapHooks();

  const popupConfigReference = useRef();
  const [popupConfig, setPopupConfig] = useState(false);
  const [visibilityDrawer, setVisibilityDrawer] = useState({
    [DRAWER_ANCHOR]: false,
  });
  popupConfigReference.current = popupConfig;

  // current basemap style id
  const currentBasemapStyleId = mapState?.mapStyle?.styleType;

  const currentBasemapStyle =
    currentBasemapStyles.find((style) => style.id === currentBasemapStyleId) ||
    MAP_STYLES.find((style) => style.id === DEFAULT_MAP_STYLE);

  const popupConfigRef = useRef();
  popupConfigRef.current = popupConfig;

  const viewState = useSelector((state) =>
    Object.keys(state.carto.viewState).length > 0
      ? state.carto.viewState
      : mapState.initialViewState,
  );

  const projectViewConfig = useSelector((state) => state.app.projectViewConfig);
  const [projectViewConfigApplied, setProjectViewConfigApplied] =
    useState(undefined);
  const projectViewConfigAppliedRef = useRef();
  projectViewConfigAppliedRef.current = projectViewConfigApplied;

  // get all custom layers
  const customLegendLayers = useSelector(
    (state) => state.app.customLegendLayers,
  );

  // custom layer info from ellipsis api
  const customLayersInfo = useSelector((state) => state.app.customLayersInfo);

  // get default layers order
  const defaultLayersOrder = useSelector(
    (state) => state.app.defaultLayersOrder,
  );

  const currentFiltersForCustomLayers = pathOr({}, ['current'], filtersForCustomLayers);
  const priorFiltersForCustomLayers = pathOr({}, ['prior'], filtersForCustomLayers);

  // get builder layers
  let layers = getBuilderLayers({
    mapConfig,
    popupConfigRef: popupConfigRef,
    setPopupConfig: setPopupConfig,
    projectViewConfig:
      projectViewConfigApplied === projectViewConfig.key
        ? undefined
        : projectViewConfig,
  });

  // append customLegendLayers
  for (
    let layerIndex = 0;
    layerIndex < customLegendLayers.length;
    layerIndex++
  ) {
    const layerConfig = customLegendLayers[layerIndex];
    const layerSource = pathOr('', ['layerSource'], layerConfig);
    const layerType = pathOr('', ['layerType'], layerConfig);
    const layerInfo = customLayersInfo.find(
      (layer) => layer?.layerId === layerConfig.id,
    );

    let layerComponent = null;
    let customLayerInfo = {};

    switch (layerSource) {
      default:
        switch (layerType) {
          case MAP_LAYER_TYPE.TILE_LAYER:
          case MAP_LAYER_TYPE.TEMPLATE_TILE_LAYER:
            layerComponent = TileLayerComponent;
            break;
          case MAP_LAYER_TYPE.TEMPLATE_MVT_LAYER:
          case MAP_LAYER_TYPE.MVT_LAYER:
            layerComponent = MVTLayerComponent;
            customLayerInfo = createCustomLayerConfig({
              layerConfig,
              layerInfo,
            });
            break;
        }
        break;
      case LAYER_SOURCE.ELLIPSIS_DRIVE:
        switch (layerType) {
          case MAP_LAYER_TYPE.XYZ_RASTER:
            layerComponent = TileLayerComponent;
            break;
          case MAP_LAYER_TYPE.MVT:
            layerComponent = MVTLayerComponent;

            customLayerInfo = createCustomLayerConfig({
              layerConfig,
              layerInfo,
              popupConfigRef,
              setPopupConfig,
              widgetConfigs: analytics,
              widgetConfigsHash: analyticsHash,
              onViewportLoadedTiles: updateLoadedTiles,
            });

            const layerId = pathOr(null, ['id'], layerConfig);
            const layerFilters = pathOr({}, [layerId], currentFiltersForCustomLayers);
            const layerHasActiveFilters = doesLayerHaveFilters({
              layerId,
              layerFilters,
              onlyActiveFilters: true,
            });

            if (layerHasActiveFilters) {
              for (const parameter in LAYER_FILTER_HANDLER_CONFIGS) {
                if (!has(parameter, customLayerInfo)) continue;
                const handlerConfig = LAYER_FILTER_HANDLER_CONFIGS[parameter];
                const handlerFn = handlerConfig.handler;

                handlerFn({
                  config: customLayerInfo,
                  parameter: parameter,
                  layerFilters: layerFilters,
                  filteredValue: handlerConfig.filteredValue,
                  analytics,
                  analyticsHash,
                  setPopupConfig,
                });
              }
            }
            else {
              const priorFilters = pathOr({}, [layerId], priorFiltersForCustomLayers);

              conditionallyUpdateMapTriggers({
                config: customLayerInfo,
                parameters: Object.keys(LAYER_FILTER_HANDLER_CONFIGS),
                currentFilters: layerFilters,
                priorFilters: priorFilters,
              });
            }
            break;
        }
        break;
    }

    appendNewLayer({
      layerConfig: {
        layersInfo: {
          ...customLayerInfo,
        },
        ...layerConfig,
      },
      layers,
      layerComponent,
    });
  }

  // sort layers for visibility
  const cartoLayers = mapConfig?.map?.layers || [];
  const orderedLayerIds = orderLayerIds({
    cartoLayers,
    customLegendLayers,
    defaultLayersOrder,
  });
  layers = orderedLayerIds
    .map((layerId) => {
      return layers.find((layer) => layer?.id == layerId);
    })
    .filter((layer) => layer !== undefined);

  let timeout;
  const deferLoadingTrigger = () => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      clearTimeout(timeout);
      setIsMapLoading(false);
    }, 5000);
  };

  useEffect(() => {
    if (newMapStateFlag !== true) return;
    setNewMapStateFlag(false);
  }, [newMapStateFlag]);

  useEffect(() => {
    if (
      !projectViewConfig ||
      projectViewConfigAppliedRef === projectViewConfig.key ||
      !layers
    ) {
      return;
    }

    const mapConfig = projectViewConfig.mapConfig || {};

    if (projectViewConfig.noConfig !== true) {
      if (mapConfig.viewState) dispatch(setViewState(mapConfig.viewState));
      if (mapConfig.basemap) dispatch(setBasemap(mapConfig.basemap));
    } else if (newMapStateFlag !== true) {
      resetMapState();
    }

    setProjectViewConfigApplied(projectViewConfig.key);
  }, [projectViewConfig]);

  // create a debounce for the marker viewport fct
  // to wait for the drawer(info panel) animation
  let debounceMarkerInViewport = debounce(function (viewportObject) {
    checkIfMarkerIsInViewport({
      viewportObject,
      dispatch,
      setViewState,
      viewState,
      DRAWER_ANCHOR,
      DRAWER_MAX_MIN_WIDTH,
    });
  }, 500);

  const DRAWER_ARGS = {
    anchorType: DRAWER_ANCHOR,
    open: true,
    setPopupConfig,
    visibilityDrawer,
    setVisibilityDrawer,
    debounceMarkerInViewport,
    popupConfigReference,
  };

  // check if valid popupConfig
  const isValidPopupConfig = (typeOfTrigger) => {
    if (!isNilOrEmpty(popupConfig)) {
      const coordinate = pathOr([], ['coordinate'], popupConfig);
      const trigger = pathOr(null, ['trigger'], popupConfig);
      if (
        isArrayNotEmpty(coordinate) &&
        coordinate.length === 2 &&
        trigger === typeOfTrigger
      ) {
        return true;
      }
    }
    return false;
  };
  return (
    <>
      <DeckGL
        viewState={{ ...viewState }}
        controller={true}
        layers={layers.filter((layer) => layer?.props?.visible === true)}
        onViewStateChange={(deckGLViewState) => {
          handleViewStateChange(deckGLViewState);
        }}
        onResize={handleSizeChange}
        onHover={(object) => {
          handleHover(object);
          // not hovering over a layer
          if (!object?.layer) {
            if (popupConfig?.trigger !== MOUSE_EVENTS.CLICK) {
              setPopupConfig(false);
            }
          }
        }}
        onClick={(object) => {
          // not clicking on a layer
          if (!object?.layer) {
            setPopupConfig(false);
          } else {
            // toggle drawer
            toggleDrawer({
              ...DRAWER_ARGS,
              viewportObject: object,
            });
          }
        }}
        getCursor={handleCursor}
        pickingRadius={isMobile ? 10 : 0}
        onLoad={() => {
          if (timeout) clearTimeout();
        }}
        onAfterRender={() => {
          removeMapLogo();
          deferLoadingTrigger();
          const filtered = layers
            .filter((layer) => {
              return layer.isLoaded === true;
            })
            .map((layer) => ({
              id: layer?.id,
              isLoaded: true,
            }));
          dispatch(addLoadedLayers([...filtered]));
        }}
      >
        <Map
          reuseMaps
          {...(currentBasemapStyle?.options?.mapType === MAPBOX_MAPS.MAPBOX && {
            mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN,
          })}
          mapStyle={
            currentBasemapStyles.find(
              (basemap) => basemap.id === currentBasemapStyleId,
            )?.url || BASEMAPS.positron.options.mapStyle
          }
          styleDiffing={false}
        >
          {isValidPopupConfig(MOUSE_EVENTS.CLICK) && (
            <Marker
              longitude={popupConfig.coordinate[0]}
              latitude={popupConfig.coordinate[1]}
              color={
                [DARK_MATTER, CUSTOM_MAP_STYLES.SATELLITE_ROADS_BW].includes(
                  currentBasemapStyleId,
                )
                  ? MARKER_COLORS.RED
                  : MARKER_COLORS.BLACK
              }
            />
          )}
          {isValidPopupConfig(MOUSE_EVENTS.HOVER) && (
            <Popup
              maxWidth={'400px'}
              longitude={popupConfig.coordinate[0]}
              latitude={popupConfig.coordinate[1]}
              className={classes.popup}
              closeOnClick={false}
              focusAfterOpen={false}
              closeButton={popupConfig?.trigger === MOUSE_EVENTS.CLICK}
              onClose={() => {
                setPopupConfig({
                  ...popupConfig,
                  popupCloseClicked: true,
                });
              }}
            >
              <DOMPurifiedHTML
                elementType='div'
                html={stripHTMLTooltip(
                  popupConfig.innerHTML,
                  MAP_POPUP_INDEX_REMOVE,
                  ['display-name', 'value'],
                  'content',
                )}
              />
            </Popup>
          )}
        </Map>
      </DeckGL>
      {isValidPopupConfig(MOUSE_EVENTS.CLICK) && (
        <Drawer
          open={visibilityDrawer[DRAWER_ANCHOR]}
          anchor={DRAWER_ANCHOR}
          onClose={() => toggleDrawer({ ...DRAWER_ARGS, open: false })}
        >
          <Box className={classes.drawerBoxContainer} role='presentation'>
            <div className={classes.closeButtonContainer}>
              <IconButton
                onClick={() => toggleDrawer({ ...DRAWER_ARGS, open: false })}
              >
                <CloseIcon />
              </IconButton>
            </div>
            <DOMPurifiedHTML elementType='div' html={popupConfig.innerHTML} />
          </Box>
        </Drawer>
      )}
    </>
  );
}
