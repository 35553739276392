import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useQuery } from 'react-query';
import { pathOr } from 'ramda';

import { useEnv } from '@/context/env.context';
import { useSession } from '@/context/session';
import globalConfig from '@/utils/config';
import { isNilOrEmpty } from '@/utils/validator';

const useEnvs = (onStart = () => {}, onFinish = () => {}) => {
  const { isAuthenticated } = useAuth0();
  const { getBearerToken } = useSession();
  const { demandIntelService } = useEnv();

  const makeRequest = async (options) => {
    try {
      if (isAuthenticated) {
        options.config.headers = {
          ...options.config.headers,
          Authorization: `Bearer ${await getBearerToken()}`,
        };
      }
      onStart();
      const response = await axios(options.config);
      onFinish();
      const { data } = response;

      return data;
    } catch (error) {
      onFinish();
      if (axios.isAxiosError(error) && error.response) {
        return error.response.data;
      }

      return error.message;
    }
  };

  const getOrgConfig = async () => {
    const orgRequestConfig = {
      url: `${demandIntelService}${globalConfig.apiRoutes.getOrgConfig}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };
    return makeRequest({ config: orgRequestConfig, authenticated: true });
  }

  const getEnvConfigs = async () => {
    const envRequestConfig = {
      url: `${demandIntelService}${globalConfig.apiRoutes.getEnvList}`,
      method: 'GET',
      headers: {
        'content-type': 'application/json',
      },
    };

    const responses = await Promise.all([
      getOrgConfig(),
      makeRequest({ config: envRequestConfig, authenticated: true }),
    ])
    const orgResponse = responses[0];
    const envResponse = responses[1];
    const envData = pathOr([], ['data'], envResponse);

    const enabledEnvs = (
      (process.env.NODE_ENV !== 'production') ?
        envData :
        envData.filter(
          (env) => pathOr(true, ['enabled'], env) === true
        )
    );
    const sortedEnvs = enabledEnvs.toSorted(
      (a, b) => a.name.localeCompare(b.name)
    ) || [];

    const orgConfig = orgResponse.data || {};
    const envOrder = orgConfig.envOrder || [];
    if (isNilOrEmpty(envOrder)) {
      return {
        org: orgConfig,
        envs: sortedEnvs,
      };
    }

    const orderedEnvs = [];
    for (const envId of envOrder) {
      const envIndex = sortedEnvs.findIndex(
        (envConfig) => envConfig.id == envId
      );
      if (envIndex === -1) continue;

      orderedEnvs.push(sortedEnvs[envIndex]);
      sortedEnvs.splice(envIndex, 1);
    }

    if (sortedEnvs.length > 0) orderedEnvs.push(...sortedEnvs);
    return {
      org: orgConfig,
      envs: orderedEnvs,
    }
  };

  const addEnv = (envConfig) => {
    const envList = pathOr([], ['envs'], data);
    envList.push(envConfig);
    data.envs = [...envList];
  };

  const updateEnv = (envConfig) => {
    const envList = [...(pathOr([], ['envs'], data))];
    const idx = envList.findIndex((env) => env.id === envConfig.id);
    if (idx === -1) {
      addEnv(envConfig);
    }
    else {
      envList[idx] = envConfig;
      data.envs = envList;
    }
  }

  const getCurrentEnvConfig = (currentEnv) => {
    const envList = pathOr([], ['envs'], data);
    const currentEnvConfig = isLoading
      ? null
      : envList.find((env) => env.id === currentEnv) || envList[0];

    return currentEnvConfig;
  }

  const { isLoading, data, isError, error, isFetched } = useQuery(
    'envList',
    () => {
      async function callApi() {
        return await getEnvConfigs();
      }

      return callApi();
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      retry: 0,
    },
  );

  return {
    isLoading,
    data,
    error,
    isError,
    isFetched,
    addEnv,
    updateEnv,
    getCurrentEnvConfig,
  };
};
export default useEnvs;
