export const redirectIfAnyDeckGlProject = (envList) => {
  if (envList.length < 1) return;

  // if any env is deck.gl, all must be deck.gl
  const requiresDeckGl = envList.some(
    (env) => (
      (
        env.cartoMapId !== undefined &&
        env.cartoMapId !== null
      ) || (
        env.viewState !== undefined &&
        env.viewState !== null
      )
    )
  );
  const thisUrl = new URL(document.URL);

  const deckUrl = process.env.REACT_APP_INTELLIGENCE_CARTO_URL || process.env.REACT_APP_INTELLIGENCE_DECKGL_URL;
  const deckUrlObj = new URL(deckUrl);
  const baseUrl = process.env.REACT_APP_INTELLIGENCE_KEPLER_URL;
  const baseUrlObj = new URL(baseUrl);

  if (
    thisUrl.hostname !== deckUrlObj.hostname &&
    thisUrl.hostname !== baseUrlObj.hostname
  ) {
    console.debug(
      `Unrecognized hostname. Skipping redirect logic: ${document.URL}`,
    );
    return;
  }

  if (requiresDeckGl === true) {
    console.debug('Org requires DeckGL');
    if (thisUrl.hostname !== deckUrlObj.hostname) {
      if (process.env.NODE_ENV === 'production') {
        console.debug(`Redirecting to DeckGL: ${deckUrl}`);
        if (process.env.REACT_APP_DISABLE_CARTO_REDIRECT || process.env.REACT_APP_DISABLE_DECKGL_REDIRECT) {
          console.debug(`Instructed not to redirect to DeckGL: ${deckUrl}`);
        } else {
          window.location.href = deckUrl;
        }
      } else {
        console.debug(
          `Not in Production. Not redirecting to DeckGL: ${deckUrl}`,
        );
      }
    }
  } else {
    console.debug('Org requires Kepler');
    if (thisUrl.hostname !== baseUrlObj.hostname) {
      if (process.env.NODE_ENV === 'production') {
        console.debug(`Redirecting to Kepler: ${baseUrl}`);
        if (process.env.REACT_APP_DISABLE_CARTO_REDIRECT || process.env.REACT_APP_DISABLE_DECKGL_REDIRECT) {
          console.debug(`Instructed not to redirect to Kepler: ${baseUrl}`);
        } else {
          window.location.href = baseUrl;
        }
      } else {
        console.debug(
          `Not in Production. Not redirecting to Kepler: ${baseUrl}`,
        );
      }
    }
  }
};
