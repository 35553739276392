import 'mapbox-gl/dist/mapbox-gl.css'; 

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { makeStyles } from '@material-ui/core';
import { pathOr } from 'ramda';

import DeckGLComponent from './DeckGLComponent';
import WidgetWrapper from '../common/WidgetWrapper';

// validation tools
import {
  isArrayNotEmpty,
  isNilOrEmpty,
  checkEqObjects,
} from '@/utils/validator';

// utils
import {
  memoizedCreateLayerWidgets,
  rebuildWidgets,
} from '../utils/widgets';

import {
  INITIAL_VIEW_STATE,
} from '@/utils/constants';

import {
  setViewState,
} from '@/store/appSlice';

const useStyles = makeStyles((theme) => ({
  map: {
    backgroundColor: theme.palette.grey[50],
    position: 'relative',
    flex: '1 1 auto',
  },
  tooltip: {
    '& .content': {
      ...theme.typography.caption,
      position: 'relative',
      padding: theme.spacing(1, 1.5),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
      color: 'rgba(255, 255, 255, 0.75)',
      transform: `translate(-50%, calc(-100% - ${theme.spacing(2.5)}px))`,

      '& .arrow': {
        display: 'block',
        position: 'absolute',
        top: 'calc(100% - 1px)',
        left: '50%',
        width: 0,
        height: 0,
        marginLeft: theme.spacing(-1),
        borderLeft: `${theme.spacing(1)}px solid transparent`,
        borderRight: `${theme.spacing(1)}px solid transparent`,
        borderTop: `${theme.spacing(1)}px solid ${theme.palette.grey[900]}`,
      },
    },
  },
}));

export default function MapByConfig({
  currentEnvConfig,
  updateWidgets,
  setIsMapLoading,
  currentBasemapConfig = {},
  analytics = [],
  analyticsHash,
  isWidgetContainerOpen,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const viewState = useSelector((state) => state.app.viewState);
  const allLayerVisibility = useSelector((state) => state.app.layerVisibility);

  // filters for custom layers
  const layerFilters = useSelector(
    (state) => state.app.layerFilters,
  );

  // stash the tiles of select layers for latter use
  const loadedTilesByLayerRef = useRef(new Map());

  const updateLoadedTiles = (layerId, tiles) => {
    loadedTilesByLayerRef.current.set(layerId, tiles);
  };

  useEffect(() => {
    rebuildWidgets({
      viewState,
      isWidgetContainerOpen,
      loadedTilesByLayer: loadedTilesByLayerRef.current,
      analyticsConfigs: analytics,
      analyticsConfigsHash: analyticsHash,
      allLayerVisibility,
      updateWidgets,
      allLayerFilters: layerFilters,
    });
  }, [
    viewState,
    allLayerVisibility,
    isWidgetContainerOpen,
    layerFilters,
    analytics,
    analyticsHash,
  ]);

  useEffect(() => {
    if (!isArrayNotEmpty(analytics)) return;

    const widgets = memoizedCreateLayerWidgets({
      widgetConfigs: analytics,
      widgetConfigsHash: analyticsHash,
      data: [],
    });
    if (!isNilOrEmpty(widgets)) updateWidgets(widgets);
  }, [
    analytics,
    analyticsHash,
  ]);

  return (
    <div className={classes.map}>
      <DeckGLComponent
        updateLoadedTiles={updateLoadedTiles}
        currentBasemapConfig={currentBasemapConfig}
        currentEnvConfig={currentEnvConfig}
        setIsMapLoading={setIsMapLoading}
        analytics={analytics}
        analyticsHash={analyticsHash}
        layerFilters={layerFilters}
      />
    </div>
  );
}
