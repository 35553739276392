import { useState, useCallback, memo, useEffect } from 'react';
import Map from 'react-map-gl';

// material ui
import Box from '@mui/material/Box';

// styles
import './styles/styles.css';

// utils
import { pathOr, has } from 'ramda';

// validation tools
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

// components
import DrawControl from '@/components/Mapbox/DrawControl';

import { BASE_MAP_CONFIGS } from '@/components/Map/utils/basemap';

// constants
const DEFAULT_CSS_STYLE = {
  width: '100%',
  height: '100%',
  borderRadius: '4px',
};
const DEFAULT_MAP_STYLE = BASE_MAP_CONFIGS[0].uri;
const DEFAULT_BOX_STYLE = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: 2,
};
const REACT_APP_MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const DEFAULT_VIEWPORT = {
  width: '100%',
  height: '100%',
  zoom: 1,
};

const SIMPLE_SELECT_MODE = 'simple_select';

/*
 * Notes:
 *
 * - order matters in the styles. last is on top
 * - two sets of styles, "static" vs active draw
 */
const MAPBOX_DRAW_STYLES = [
  // line stroke
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['!=', 'mode', SIMPLE_SELECT_MODE],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#9400D3',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },

  // polygon fill
  {
    id: 'gl-draw-polygon-fill',
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', SIMPLE_SELECT_MODE],
    ],
    paint: {
      'fill-color': '#9400D3',
      'fill-outline-color': '#9400D3',
      'fill-opacity': 0.1,
    },
  },

  // polygon outline stroke
  // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', SIMPLE_SELECT_MODE],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#9400D3',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },

  // line stroke
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'LineString'],
      ['==', 'mode', SIMPLE_SELECT_MODE],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#9400D3',
      'line-width': 3,
    },
  },

  // polygon fill
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['==', 'mode', SIMPLE_SELECT_MODE],
    ],
    paint: {
      'fill-color': '#9400D3',
      'fill-outline-color': '#9400D3',
      'fill-opacity': 0.1,
    },
  },

  // polygon outline
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: [
      'all',
      ['==', '$type', 'Polygon'],
      ['==', 'mode', SIMPLE_SELECT_MODE],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#9400D3',
      'line-width': 3,
    },
  },

  // vertex point halos
  {
    id: 'gl-draw-polygon-and-line-vertex-halo-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', SIMPLE_SELECT_MODE],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#FFF',
    },
  },

  // polygon mid points
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'midpoint']
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#D30074',
    },
  },

  // vertex points
  {
    id: 'gl-draw-polygon-and-line-vertex-active',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#F405FC',
    },
  },
];

const EditableGeoJSONMap = (props) => {
  const features = props.features;
  const setFeatures = props.setFeatures;
  const deleteFeatures = props.deleteFeatures;
  const style = pathOr(DEFAULT_CSS_STYLE, ['style'], props);
  const initialViewState = pathOr({}, ['initialViewState'], props);
  const mapStyle = pathOr(DEFAULT_MAP_STYLE, ['mapStyle'], props);
  const boxStyle = pathOr({}, ['boxStyle'], props);

  // evt.features are the features that have been added/updated
  const onDrawUpdate = (evt) => {
    setFeatures(evt.features);
  };

  // evt.features are the features that have been deleted
  const onDrawDelete = (evt) => {
    deleteFeatures(evt.features);
  };

  if (isNilOrEmpty(REACT_APP_MAPBOX_ACCESS_TOKEN)) {
    return <></>;
  }

  return (
    <Box
      sx={{
        ...DEFAULT_BOX_STYLE,
        ...boxStyle,
      }}
      className='geojson-map-editing'
    >
      <Map
        ref={props?.mapRef}
        mapboxAccessToken={REACT_APP_MAPBOX_ACCESS_TOKEN}
        initialViewState={{ ...DEFAULT_VIEWPORT, ...initialViewState }}
        style={style}
        mapStyle={mapStyle}
      >
        <DrawControl
          ref={props?.drawRef}
          position='top-left'
          displayControlsDefault={false}
          controls={{
            polygon: true,
            trash: true,
          }}
          defaultMode={SIMPLE_SELECT_MODE}
          onCreate={onDrawUpdate}
          onUpdate={onDrawUpdate}
          onDelete={onDrawDelete}
          styles={MAPBOX_DRAW_STYLES}
        />
      </Map>
    </Box>
  );
};

export default EditableGeoJSONMap;
