import axios from 'axios';
import { useQuery } from 'react-query';

import { useSession } from '@/context/session';
import config from '@/utils/config';
import { isNilOrEmpty } from '@/utils/validator';

const TWELVE_HOURS = 12 * 60 * 60 * 1000;

export const useSignedUrls = (toGet) => {
  const { getBearerToken } = useSession();

  const getSignedUrls = async () => {
    if (isNilOrEmpty(toGet)) return [];

    const url = `${config.demandIntelService}${config.apiRoutes.getSignedUrls}`;
    const request = {
      url: url,
      method: 'POST',
      data: toGet,
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    };

    const response = await axios(request);
    return response.data.data;
  };

  const { 
    data,
    status,
    isPending,
    isFetched,
    isSuccess,
    isError,
    error,
  } = useQuery(
    ['getSignedUrls', toGet],
    () => {
      async function callApi() {
        return await getSignedUrls();
      }

      return callApi();
    },
    {
      staleTime: TWELVE_HOURS,
      cacheTime: TWELVE_HOURS,
      retry: true,
    },
  );

  return {
    data,
    status,
    isPending,
    isFetched,
    isSuccess,
    isError,
    error,
  };
}
