import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { pathOr } from 'ramda';
import { v4 as uuid4 } from 'uuid';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import bbox from '@turf/bbox';

import GlobalLoading from '@/components/GlobalLoading';
import EditableGeoJSONMap from '@/components/EditableGeoJSONMap';
import { BASE_MAP_CONFIGS } from '@/components/Map/utils/basemap';

import { isNilOrEmpty } from '@/utils/validator';

import { computeEstimatedArea } from '../utils';

import {
  ALL_YEARS,
  ALL_QUARTERS,
  INPUT_KEYS,
  REQUIRED_HELPER_TEXT,
  START_END_HELPER_TEXT,
} from '../constants';

const REQUIRED_KEYS = [
  INPUT_KEYS.NAME,
  INPUT_KEYS.START_YEAR,
  INPUT_KEYS.START_QUARTER,
  INPUT_KEYS.END_YEAR,
  INPUT_KEYS.END_QUARTER,
  INPUT_KEYS.AOI,
];

const Row = styled.div`
  margin-bottom: 1em;
`;

const HiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const isStartEndValid = ({
  formState,
}) => {
  const startYear = pathOr(null, [INPUT_KEYS.START_YEAR], formState);
  const startQuarter = pathOr(null, [INPUT_KEYS.START_QUARTER], formState);
  const endYear = pathOr(null, [INPUT_KEYS.END_YEAR], formState);
  const endQuarter = pathOr(null, [INPUT_KEYS.END_QUARTER], formState);

  let hasError = {};

  if (
    !isNilOrEmpty(startYear) &&
    !isNilOrEmpty(startQuarter) &&
    !isNilOrEmpty(endYear) &&
    !isNilOrEmpty(endQuarter)
  ) {
    const startDate = new Date(startYear, startQuarter);
    const endDate = new Date(endYear, endQuarter);
    if (!(startDate < endDate)) {
      hasError = {
        [INPUT_KEYS.START_YEAR]: START_END_HELPER_TEXT,
        [INPUT_KEYS.START_QUARTER]: START_END_HELPER_TEXT,
        [INPUT_KEYS.END_YEAR]: START_END_HELPER_TEXT,
        [INPUT_KEYS.END_QUARTER]: START_END_HELPER_TEXT,
      };
    }
    else {
      hasError = {
        [INPUT_KEYS.START_YEAR]: false,
        [INPUT_KEYS.START_QUARTER]: false,
        [INPUT_KEYS.END_YEAR]: false,
        [INPUT_KEYS.END_QUARTER]: false,
      };
    }
  }

  return [
    !isNilOrEmpty(hasError),
    hasError,
  ];
}

export const validateFormState = ({
  formState,
  hasError,
  setHasError,
}) => {
  if (isNilOrEmpty(formState)) {
    setHasError({
      ...hasError,
      [INPUT_KEYS.NAME]: REQUIRED_HELPER_TEXT,
      [INPUT_KEYS.START_YEAR]: REQUIRED_HELPER_TEXT,
      [INPUT_KEYS.START_QUARTER]: REQUIRED_HELPER_TEXT,
      [INPUT_KEYS.END_YEAR]: REQUIRED_HELPER_TEXT,
      [INPUT_KEYS.END_QUARTER]: REQUIRED_HELPER_TEXT,
      [INPUT_KEYS.AOI]: REQUIRED_HELPER_TEXT,
    });
    return false;
  }

  const newHasError = Object.fromEntries(
    REQUIRED_KEYS.map(
      (key) => [
        key,
        isNilOrEmpty(pathOr(null, [key], formState)) === true ?
          REQUIRED_HELPER_TEXT :
          false
      ]
    )
  );
  const anyError = Object.values(newHasError).some((v) => v !== false);
  if (anyError) {
    setHasError({
      ...hasError,
      ...newHasError,
    });
    return false;
  }

  const [
    _isStartEndValid,
    startEndValidHasError,
  ] = isStartEndValid({formState});

  if (!_isStartEndValid) {
    setHasError({
      ...hasError,
      ...newHasError,
      ...startEndValidHasError,
    });
    return false;
  }

  return true;
};

export const OrderForm = ({
  formState,
  setFormState,
  hasError,
  setHasError,
}) => {
  const [mapNode, setMapNode] = useState();
  const [drawNode, setDrawNode] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(
    () => {
      if (isNilOrEmpty(mapNode) || isNilOrEmpty(drawNode)) return;

      const features = pathOr({}, [INPUT_KEYS.AOI], formState);
      const drawFeatureCollection = drawNode.getAll();
      if (isNilOrEmpty(drawFeatureCollection?.features) && !isNilOrEmpty(features)) {
        const featureCollection = {
          type: 'FeatureCollection',
          features: Object.values(features),
        };
        drawNode.add(featureCollection);

        mapNode.fitBounds(
          bbox(featureCollection),
          {
            padding: 100,
          }
        );
      }
    },
    [mapNode, drawNode]
  );

  const setRef = (setFn) => {
    const wrapper = (node) => {
      setFn(node);
    };

    return wrapper;
  };

  const buildChangeHandler = (keyword) => {
    const handleChange = (evt) => {
      const newValue = evt.target.value;
      const newFormState = {
        ...formState,
        [keyword]: newValue,
      };
      setFormState(newFormState);

      let newHasError = {
        ...hasError,
      };
      switch (keyword) {
        case INPUT_KEYS.NAME:
        case INPUT_KEYS.START_YEAR:
        case INPUT_KEYS.START_QUARTER:
        case INPUT_KEYS.END_YEAR:
        case INPUT_KEYS.END_QUARTER:
          newHasError[keyword] = isNilOrEmpty(newValue) ?
            REQUIRED_HELPER_TEXT :
            false;
          break;
      };

      const [
        _isStartEndValid,
        startEndValidHasError,
      ] = isStartEndValid({formState: newFormState});

      newHasError = {
        ...newHasError,
        ...startEndValidHasError,
      };

      setHasError(newHasError);
    };

    return handleChange;
  };

  const extractFeatures = (contents) => {
    const features = [];
    if (isNilOrEmpty(contents)) return features;

    const data = JSON.parse(contents);
    switch (data?.type) {
      case 'FeatureCollection':
        features.push(
          ...(data.features.map((feature) => {
            feature.id = uuid4();
            return feature;
          }))
        );
        break;
      case 'Feature':
        feature.id = uuid4();
        features.push(feature);
        break;
      case 'Polygon':
      case 'MultiPolygon':
        features.push({
          id: uuid4(),
          type: 'Feature',
          geometry: data,
        });
        break;
    }

    return features;
  };

  const importAoi = async (evt) => {
    const files = evt.target.files

    setIsLoading(true);
    Promise.all(Array.from(files).map((file) => file.text())).then(
      (allContents) => {
        const features = [];
        for (let i = 0; i < allContents.length; i++) {
          features.push(...(extractFeatures(allContents[i])));
        }

        if (!isNilOrEmpty(features)) {
          setFormState((prevFormState) => {
            return {
              ...prevFormState,
              [INPUT_KEYS.AOI]: {
                ...(pathOr({}, [INPUT_KEYS.AOI], prevFormState)),
                ...features,
              },
            };
          });

          const featureCollection = {
            type: 'FeatureCollection',
            features: features
          };
          drawNode?.add(featureCollection);

          mapNode?.fitBounds(
            bbox(featureCollection),
            {
              padding: 100,
            }
          );
        }

        setIsLoading(false);
      }
    );
  };

  useEffect(
    () => {
      const features = pathOr(undefined, [INPUT_KEYS.AOI], formState);
      if (features === undefined) return;

      setHasError((prevHasError) => {
        return {
          ...prevHasError,
          [INPUT_KEYS.AOI]: (
            isNilOrEmpty(features) ?
              REQUIRED_HELPER_TEXT :
              false
          ),
        }
      });
    },
    [pathOr(undefined, [INPUT_KEYS.AOI], formState)]
  );

  const upsertFeatures = (addedFeatures) => {
    setFormState((prevFormState) => {
      const features = pathOr({}, [INPUT_KEYS.AOI], prevFormState);

      return {
        ...prevFormState,
        [INPUT_KEYS.AOI]: {
          ...features,
          ...Object.fromEntries(
            addedFeatures.map(
              (feature) => [feature.id, feature]
            )
          ),
        },
      };
    });
  };

  const deleteFeatures = (deletedFeatures) => {
    setFormState((prevFormState) => {
      const features = pathOr({}, [INPUT_KEYS.AOI], prevFormState);

      deletedFeatures.forEach((feature) => {
        delete features[feature.id];
      });

      return {
        ...prevFormState,
        [INPUT_KEYS.AOI]: {
          ...features,
        },
      };
    });
  }

  const estimatedArea = computeEstimatedArea({
    features: pathOr([], [INPUT_KEYS.AOI], formState),
    noAreaValue: '',
  });

  return (
    <>
      {isLoading && <GlobalLoading />}
      <div style={{ width: '100%', height: '100%' }}>
        <Box style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px',
            }}
          >
            <Row>
              <TextField
                id='wam-project-name'
                label='Project Name'
                variant='outlined'
                value={pathOr('', [INPUT_KEYS.NAME], formState)}
                onChange={buildChangeHandler(INPUT_KEYS.NAME)}
                error={!!pathOr(false, [INPUT_KEYS.NAME], hasError)}
                helperText={
                  (typeof pathOr(false, [INPUT_KEYS.NAME], hasError) === 'string') ?
                    hasError[INPUT_KEYS.NAME] :
                    undefined
                }
                required
              />
            </Row>
            <Row>
              <TextField
                id='wam-description'
                label='Description'
                variant='outlined'
                value={pathOr('', [INPUT_KEYS.DESCRIPTION], formState)}
                onChange={buildChangeHandler(INPUT_KEYS.DESCRIPTION)}
                multiline
              />
            </Row>
            <Row>
              <FormControl
                error={!!pathOr(false, [INPUT_KEYS.START_YEAR], hasError)}
                required
              >
                <InputLabel id='wam-start-year-label'>First Year</InputLabel>
                <Select
                  labelId='wam-start-year-label'
                  id='wam-start-year'
                  value={pathOr('', [INPUT_KEYS.START_YEAR], formState)}
                  label='First Year'
                  onChange={buildChangeHandler(INPUT_KEYS.START_YEAR)}
                >
                  {ALL_YEARS.map(
                    (year) => <MenuItem key={year} value={year}>{year}</MenuItem>
                  )}
                </Select>
                {(typeof pathOr(false, [INPUT_KEYS.START_YEAR], hasError) === 'string') &&
                  <FormHelperText>{hasError[INPUT_KEYS.START_YEAR]}</FormHelperText>
                }
              </FormControl>
            </Row>
            <Row>
              <FormControl
                error={!!pathOr(false, [INPUT_KEYS.START_QUARTER], hasError)}
                required
              >
                <InputLabel id='wam-start-quarter-label'>First Quarter</InputLabel>
                <Select
                  labelId='wam-start-quarter-label'
                  id='wam-start-quarter'
                  value={pathOr('', [INPUT_KEYS.START_QUARTER], formState)}
                  label='First Quarter'
                  onChange={buildChangeHandler(INPUT_KEYS.START_QUARTER)}
                >
                  {Object.entries(ALL_QUARTERS).map(
                    ([name, value]) => <MenuItem key={value} value={value}>{name}</MenuItem>
                  )}
                </Select>
                {(typeof pathOr(false, [INPUT_KEYS.START_QUARTER], hasError) === 'string') &&
                  <FormHelperText>{hasError[INPUT_KEYS.START_QUARTER]}</FormHelperText>
                }
              </FormControl>
            </Row>
            <Row>
              <FormControl
                error={!!pathOr(false, [INPUT_KEYS.END_YEAR], hasError)}
                required
              >
                <InputLabel id='wam-end-year-label'>Last Year</InputLabel>
                <Select
                  labelId='wam-end-year-label'
                  id='wam-end-year'
                  value={pathOr('', [INPUT_KEYS.END_YEAR], formState)}
                  label='Last Year'
                  onChange={buildChangeHandler(INPUT_KEYS.END_YEAR)}
                >
                  {ALL_YEARS.map(
                    (year) => <MenuItem key={year} value={year}>{year}</MenuItem>
                  )}
                </Select>
                {(typeof pathOr(false, [INPUT_KEYS.END_YEAR], hasError) === 'string') &&
                  <FormHelperText>{hasError[INPUT_KEYS.END_YEAR]}</FormHelperText>
                }
              </FormControl>
            </Row>
            <Row>
              <FormControl
                error={pathOr(false, [INPUT_KEYS.END_QUARTER], hasError)}
                required
              >
                <InputLabel id='wam-end-quarter-label'>Last Quarter</InputLabel>
                <Select
                  labelId='wam-end-quarter-label'
                  id='wam-end-quarter'
                  value={pathOr('', [INPUT_KEYS.END_QUARTER], formState)}
                  label='Last Quarter'
                  onChange={buildChangeHandler(INPUT_KEYS.END_QUARTER)}
                >
                  {Object.entries(ALL_QUARTERS).map(
                    ([name, value]) => <MenuItem key={value} value={value}>{name}</MenuItem>
                  )}
                </Select>
                {(typeof pathOr(false, [INPUT_KEYS.END_QUARTER], hasError) === 'string') &&
                  <FormHelperText>{hasError[INPUT_KEYS.END_QUARTER]}</FormHelperText>
                }
              </FormControl>
            </Row>
            <Box sx={{ flexGrow: 1 }} />
            <Row>
              <TextField
                label='Estimated Area'
                id='wam-aoi-area'
                InputProps={{
                  endAdornment: <InputAdornment position='end'>km<sup>2</sup></InputAdornment>,
                  readOnly: true,
                }}
                value={
                  estimatedArea !== '' ?
                    new Intl.NumberFormat().format(estimatedArea) :
                    ''
                }
              />
            </Row>
            <Row>
              <Button
                component='label'
                role={undefined}
                variant='outlined'
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
                sx={{
                  width: '100%',
                }}
                color={
                  !!pathOr(false, [INPUT_KEYS.AOI], hasError) ?
                    'error' :
                    'primary'
                }
              >
                Import AOI
                <HiddenInput
                  type='file'
                  onChange={importAoi}
                  accept='.json,.geojson'
                />
              </Button>
              {(typeof pathOr(false, [INPUT_KEYS.AOI], hasError) === 'string') &&
                <FormHelperText error>{hasError[INPUT_KEYS.AOI]}</FormHelperText>
              }
            </Row>
          </Box>
          <Box sx={{ flexGrow: 1, m: 1 }}>
            <EditableGeoJSONMap
              mapRef={setRef(setMapNode)}
              drawRef={setRef(setDrawNode)}
              initialViewState={null}
              features={pathOr({}, [INPUT_KEYS.AOI], formState)}
              setFeatures={upsertFeatures} // do something
              deleteFeatures={deleteFeatures} // do something
              boxStyle={{
                height: '100%',
                width: '100%',
                border: 'solid 1px rgb(192, 192, 192)',
                borderRadius: '4px',
              }}
              mapStyle={BASE_MAP_CONFIGS[0].uri}
            />
          </Box>
        </Box>
      </div>
    </>
  );
};
