import { closeSnackbar, enqueueSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';

const action = snackbarId => (
  <>
    <IconButton
      onClick={() => { closeSnackbar(snackbarId) }}
    >
      <CancelIcon
        style={{
          color: 'white'
        }}
      />
    </IconButton>
  </>
);

const message = (msg, options) => {
  enqueueSnackbar(
    msg, {
      variant: 'error',
      action,
      ...(options || {}),
    }
  );
};

export default {
  message: message,
  error: (msg, options) => message(msg, {variant: 'error', ...options}),
  success: (msg, options) => message(msg, {variant: 'success', ...options}),
};
