import { deepmerge } from '@mui/utils';
import { createTheme } from '@mui/material/styles';

import { theme as cartoTheme } from '@atlasai/carto-react-ui';
const customTheme = {
  typography: {
    fontSize: 14,
  },
};

const combinedTheme = createTheme(deepmerge(cartoTheme, customTheme));
//console.log(combinedTheme);

export default combinedTheme;
