// TODO remove all the carto stuff
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { views } from '@/constants/env';
import { useEnv } from '@/context/env.context';
import { useSession } from '@/context/session';
import useGuards from '@/hooks/useGuards';
import globalConfig from '@/utils/config';
import { TEMPLATE_LAYERS } from '@/utils/constants';
import emit from '@/utils/emit';
import { doesEnvRequireCarto } from '@/utils/map-utils';
import { isNilOrEmpty } from '@/utils/validator';

import {
  setProjectView,
  setProjectViewConfig
} from '@/store/appSlice';

import GlobalLoading from '@/components/GlobalLoading';

import SaveButton from './SaveButton';

const SaveProject = ({
  currentEnvConfig
}) => {
  const dispatch = useDispatch();
  const [state, projectView, projectViewConfig] = useSelector(
    (state) => [
      state,
      state.app.projectView,
      state.app.projectViewConfig,
    ],
  );
  const { demandIntelService } = useEnv();
  const { getBearerToken } = useSession();
  const { hasPermission } = useGuards();

  const [menuAnchor, setMenuAnchor] = useState(null);
  const openMenu = Boolean(menuAnchor);
  const [modalDetails, setModalDetails] = useState({});
  const isModalVisible = !isNilOrEmpty(modalDetails);

  const [isLoading, setIsLoading] = useState(false);

  const canUpdateMyProject = hasPermission('di:MyProject:update');
  const canUpdateTeamProject = hasPermission('di:TeamProject:update');

  const currentEnv = currentEnvConfig?.id;
  const isCartoEnv = doesEnvRequireCarto(currentEnvConfig);

  const saveView = async () => {
    const saveToViewId = modalDetails.key;
    const payload = {
      datasetIds: [],
      datasets: [],
      metadata: getMapConfig({state, isCartoEnv}),
    };
    const url = `${demandIntelService}${globalConfig.apiRoutes.postProjectView(
      currentEnv,
      saveToViewId,
    )}`;

    if (projectViewConfig.version !== undefined) {
      payload.version = projectViewConfig.version;
      if (saveToViewId === views.MY_VIEW) {
        payload.majorVersion = projectViewConfig.majorVersion;
      }
    }

    // api call
    try {
      const request = {
        url: url,
        method: 'POST',
        data: payload,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };

      const response = await axios(request);

      // don't refresh view config if what got saved isn't the current view
      if (saveToViewId === projectView) {
        dispatch(
          setProjectViewConfig({
            ...projectViewConfig,
            projectId: currentEnv,
            viewId: saveToViewId,
            majorVersion: response.data.data.majorVersion,
            minorVersion: response.data.data.minorVersion,
            version: response.data.data.version,
            // set the coord to the current from carto state, so we remain on the same
            // lat, long position
            mapConfig: {
              ...projectViewConfig.mapConfig,
              viewState: {
                ...(
                  isCartoEnv ?
                    state.carto.viewState :
                    state.app.viewState
                ),
              },
            },
          }),
        );
      }
      emit.success(`Successfully saved this view as "${modalDetails.type}"`);
    } catch (e) {
      console.error(e);
      emit.error(
        `Error saving this view as "${modalDetails.type}". Please try again`,
      );
    }
  };

  const replaceMyViewWithTeamView = async () => {
    const url = `${demandIntelService}${globalConfig.apiRoutes.postReplaceMyView(
      currentEnv,
    )}`;

    // api call
    try {
      const request = {
        url: url,
        method: 'POST',
        data: {},
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${await getBearerToken()}`,
        },
      };

      await axios(request);
      emit.success('Successfully saved "team view" as "my view"');
    } catch (e) {
      console.error(e);
      emit.error('Error saving "team view" as "my view". Please try again');
    }
  };

  const doSaveAction = async () => {
    setIsLoading(true);

    try {
      if (modalDetails.key === views.TEAM_VIEW_AS_MY_VIEW) {
        await replaceMyViewWithTeamView();

        // clear current project view so that the default happens
        // default assumes "my view" and loads that
        dispatch(setProjectView(null));
      } else {
        await saveView();
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
    setModalDetails({});
  };

  const handleSaveButtonClick = (evt) => {
    setMenuAnchor(evt.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const handleSaveAction = (key) => {
    const newModalDetails = { key: key };
    switch (key) {
      case views.MY_VIEW:
        newModalDetails.title = 'Save for me';
        newModalDetails.type = 'my view';
        newModalDetails.body = [
          'The changes in this view will be saved for you only and can be accessed by selecting "my view" in the top left.',
        ];
        break;
      case views.TEAM_VIEW:
        newModalDetails.title = 'Save for team';
        newModalDetails.type = 'team view';
        newModalDetails.body = [
          'The changes in this view will be saved for everyone in this Project and can be seen by selecting "team view" in the top left.',
          'This will not affect the personal view of your team members.',
        ];
        break;
      case views.TEAM_VIEW_AS_MY_VIEW:
        newModalDetails.title = 'Save team view as my view';
        newModalDetails.body = [
          'The current "team view" will overwrite "my view". Any personal changes will be overwritten. This action cannot be undone.',
        ];
        break;
      default:
        throw Error(`Unknown save action: ${e.key}`);
    }

    setModalDetails(newModalDetails);
  };

  const buildSaveAction = (key) => {
    const wrapper = (evt) => {
      handleSaveAction(key);
      handleMenuClose();
    };

    return wrapper;
  };

  const createMenuItem = (key, text) => {
    return (
      <MenuItem
        key={key}
        onClick={buildSaveAction(key)}
        style={{
          display: 'flex',
          padding: '5px 10px',
          minWidth: '120px',
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'inherit',
            fontWeight: 'bold',
          }
        }}
      >
        {text}
      </MenuItem>
    );
  };

  const buildMenuItems = () => {
    const menuItems = [];
    if (canUpdateMyProject) {
      menuItems.push(
        createMenuItem(views.MY_VIEW, 'Save for me')
      );
    }

    if (canUpdateTeamProject) {
      menuItems.push(
        createMenuItem(views.TEAM_VIEW, 'Save for team')
      );
    }

    return menuItems;
  };

  const menuItems = buildMenuItems();
  if (menuItems.length < 1) {
    return <></>;
  }

  const handleModalClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
      case 'escapeKeyDown':
        break;
      default:
        setModalDetails({});
        break;
    }
  };

  return (
    <>
      <SaveButton
        onClick={handleSaveButtonClick}
      >
        Save
      </SaveButton>

      <Menu
        id='save-menu'
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleMenuClose}
        children={menuItems}
      />

    {isModalVisible &&
      <Dialog
        open={true}
        onClose={handleModalClose}
        maxWidth={false}
      >
        <div style={{ width: '25vw' }}>
          <DialogTitle>
            Confirm changes: {modalDetails.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {modalDetails.body}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={handleModalClose}
              style={{
                color: 'rgb(2, 77, 158)',
                borderColor: 'rgb(2, 77, 158)',
              }}
            >
                Cancel
              </Button>
            <Button
              variant='contained'
              onClick={doSaveAction}
              style={{
                backgroundColor: 'rgb(2, 77, 158)',
                borderColor: 'rgb(2, 77, 158)',
              }}
            >
                Save
              </Button>
          </DialogActions>
        </div>
      </Dialog>}

      {isLoading && <GlobalLoading />}
    </>
  );
};

export default SaveProject;

const getMapConfig = ({
  state,
  isCartoEnv
}) => {
  const mapConfig = {
    version: '0.0.0',
  };

  const customLayers = state.app.customLegendLayers.map((layer) => ({
    id: layer?.id,
    visible: layer?.visible,
    switchable: true,
    ...(TEMPLATE_LAYERS.includes(layer?.layerType) && {
      currentTimestampID: layer?.currentTimestampID,
    }),
  }));

  if (isCartoEnv) {
    const cartoLayers = state.app.layerConfigs.order
      .map((layerId) => {
        return (
          state.carto.layers[layerId] && cleanLayer(state.carto.layers[layerId])
        );
      })
      .filter((layer) => layer);

    Object.assign(
      mapConfig,
      {
        _for: 'carto',
        basemap: state.carto.basemap,
        viewState: state.carto.viewState,
        dataSources: Object.values(state.carto.dataSources).map(
          (dataSource) => cleanDatasource(dataSource),
        ),
        layers: [
          ...cartoLayers,
          ...customLayers,
        ],
      }
    );
  }
  else {
    Object.assign(
      mapConfig,
      {
        _for: 'custom',
        basemap: state.app.basemapConfig.id,
        viewState: state.app.viewState,
        layers: [
          ...customLayers,
        ]
      }
    )
  }

  return mapConfig;
};

const cleanDatasource = (datasource) => {
  const cleaned = new Map();
  for (const [k, v] of Object.entries(datasource)) {
    if (k === 'credentials') continue;
    cleaned.set(k, v);
  }
  return Object.fromEntries(cleaned);
};

const cleanLayer = (layer) => {
  const cleaned = new Map();
  for (const [k, v] of Object.entries(layer)) {
    switch (k) {
      case 'id':
      case 'switchable':
      case 'visible':
        cleaned.set(k, v);
        break;
    }
  }
  return Object.fromEntries(cleaned);
};

