import axios from 'axios';
import { useQuery } from 'react-query';

import { useSession } from '@/context/session';
import config from '@/utils/config';
import { isNilOrEmpty } from '@/utils/validator';

const THIRTY_MINUTES = 30 * 60 * 1000;

export const useProduct = (productId) => {
  const { getBearerToken } = useSession();

  if (isNilOrEmpty(productId)) {
    throw new Error('Product ID must be provided');
  }

  const getProductConfig = async () => {
    const url = `${config.demandIntelService}${config.apiRoutes.productPrefix}/config/${productId}`;
    const request = {
      url: url,
      method: 'GET',
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    };

    const response = await axios(request);
    return response.data.data;
  };

  const { 
    data,
    status,
    isPending,
    isFetched,
    isSuccess,
    isError,
    error,
  } = useQuery(
    ['productConfig', productId],
    () => {
      async function callApi() {
        return await getProductConfig();
      }

      return callApi();
    },
    {
      staleTime: THIRTY_MINUTES,
      cacheTime: THIRTY_MINUTES,
      retry: true,
    },
  );

  const createProductOrder = async (envConfig) => {
    const url = `${config.demandIntelService}${config.apiRoutes.productPrefix}/${productId}`;
    const request = {
      url: url,
      method: 'POST',
      data: envConfig,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    };

    const response = await axios(request);
    return response.data.data;
  };

  return {
    data,
    status,
    isPending,
    isFetched,
    isSuccess,
    isError,
    error,
    createProductOrder,
  };
}
