import IFrame from '@/components/IFrame/IFrame';
import { isNilOrEmpty } from '@/utils/validator';

const Documentation = () => {
  const getSrcUri = (envItem, orgConfig) => {
    if (!isNilOrEmpty(envItem.secureDocumentationLink)) {
      const url = new URL(envItem.secureDocumentationLink);
      if (!isNilOrEmpty(orgConfig.secureDocsToken)) {
        url.searchParams.append('token', orgConfig.secureDocsToken);
      }
      return url.href;
    }

    return envItem.documentationLink;
  };

  return (
    <IFrame
      getSrcUri={getSrcUri}
    />
  );
};

export default Documentation;
