import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { pathOr } from 'ramda';

import useGuards from '@/hooks/useGuards';
import useEnvs from '@/hooks/calls/useEnvs';

import GlobalLoading from '@/components/GlobalLoading';
import Header from '@/components/Header/Header';
import Text from '@/components/Text';
import WamOrderFlow from '@/components/Wam/OrderFlow';

import { ENV_TYPES } from '@/utils/constants';
import { redirectIfAnyDeckGlProject } from '@/utils/redirect';

import {
  reset as resetApp,
  setProjectView,
  setCurrentEnv,
} from '@/store/appSlice';
import { reset as resetCarto } from '@/store/Carto';

import { views } from '@/constants/env';

import {
  CreateProjectCard,
  ProjectCard,
} from './ProjectCard';
import Resources from './Resources';

const CardWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;
const WamCardWrapper = styled(CardWrapper)`
  margin-bottom: 2em;
`;

const YourEnvironments = () => {
  const { data, addEnv, updateEnv, isLoading: isEnvListLoading } = useEnvs();
  const [envReset, setEnvReset] = useState(false);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { hasFeature } = useGuards();

  const envList = pathOr([], ['envs'], data);
  const pageConfig = pathOr({}, ['org', 'page_config', 'environments'], data);

  useEffect(() => {
    if (envReset) return;

    dispatch(resetApp());
    dispatch(resetCarto());
    setEnvReset(true);
  }, [envReset]);

  useEffect(() => {
    if (envList.length < 1) return;
    redirectIfAnyDeckGlProject(envList);
  }, [envList]);

  const setEnvironment = (envId) => {
    dispatch(setCurrentEnv(envId));
    dispatch(setProjectView(views.MY_VIEW));
  };

  if (isEnvListLoading) {
    return (
      <>
        <GlobalLoading />
      </>
    );
  }

  const envsByType = new Map(
    Object.values(ENV_TYPES).map((envType) => [envType, []])
  );
  for (let i = 0; i < envList.length; i++) {
    const env = envList[i];
    const envType = pathOr(ENV_TYPES.DEFAULT, ['envType'], env);
    let envTypeArray = envsByType.get(envType);
    if (envTypeArray === undefined) {
      envTypeArray = [];
      envsByType.set(envType,envTypeArray); 
    }

    envTypeArray.push(env);
  }

  const evalTemplate = (template, user) => {
    const handler = new Function(
      'user',
      'const tagged = (user) => `' + template + '`; return tagged(user)'
    );
    return handler(user);
  }

  const pageGreetingTemplate = pathOr(
    'Hello, ${user.name}',
    ['greeting'],
    pageConfig
  );
  const envTitleTemplate = pathOr(
    'Your Environments',
    ['title'],
    pageConfig
  );
  const envSubtitleTemplate = pathOr(
    'See all of your team projects below',
    ['subtitle'],
    pageConfig
  );

  const hasWAMFeatureFlag = (
    hasFeature('WAM') ||
    process.env.NODE_ENV !== 'production'
  );

  const onEnvUpdated = (envConfig) => {
    updateEnv(envConfig);
    setEnvReset(false); // wrong tool but works
  };

  const onEnvCreated = (envConfig) => {
    addEnv(envConfig);
    setEnvReset(false); // wrong tool but works
  };

  return (
    <div>
      <Header page='environments' />
      <div className='p-48'>
        {/* top message */}
        {pageGreetingTemplate !== false &&
          <Text className='text19 mb-24'>
            {evalTemplate(pageGreetingTemplate, user)}
          </Text>}

        {/* wam section */}
        {hasWAMFeatureFlag && (
          <>
            <Text className='text15 mb-4'>Wide Area Monitoring</Text>
            <Text className='text16 mb-16'></Text>
          </>
        )}
        {hasWAMFeatureFlag &&
        <WamCardWrapper className='wam-list-container'>
          {envsByType.get(ENV_TYPES.WAM).map((env) => {
            return (
              <ProjectCard
                key={env.id}
                env={env}
                setEnvironment={setEnvironment}
                onProjectUpdated={onEnvUpdated}
              />
            );
          })}
          <CreateProjectCard
            name='Order WAM'
            componentInModal={WamOrderFlow}
            onProjectCreated={onEnvCreated}
          />
        </WamCardWrapper>}

        {/* environments section */}
        {envTitleTemplate !== false &&
          <Text className='text15 mb-4'>
            {evalTemplate(envTitleTemplate, user)}
          </Text>}
        {envSubtitleTemplate !== false &&
          <Text className='text16 mb-16'>
            {evalTemplate(envSubtitleTemplate, user)}
          </Text>}
        <CardWrapper className='env-list-container'>
          {envsByType.get(ENV_TYPES.DEFAULT).map((env) => {
            return (
              <ProjectCard
                key={env.id}
                env={env}
                setEnvironment={setEnvironment}
                onProjectUpdated={onEnvUpdated}
              />
            );
          })}
        </CardWrapper>

      </div>
      <Resources />
    </div>
  );
};

export default YourEnvironments;
