import { useDispatch, useSelector } from 'react-redux';

import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { setProjectView } from '@/store/appSlice';
import { views } from '@/constants/env';

const SelectProjectView = () => {
  const dispatch = useDispatch();
  const projectView = useSelector((state) => state.app.projectView);

  const handleViewChange = (evt) => {
    dispatch(setProjectView(evt.target.value));
  };

  const menuItems = [
    [views.MY_VIEW, 'my view'],
    [views.TEAM_VIEW, 'team view'],
  ].map(([key, text]) => {
    return (
      <MenuItem
        key={key}
        value={key}
        onClick={(evt) => evt.stopPropagation()}
        style={{
          display: 'flex',
          padding: '5px 10px',
          minWidth: '125px',
          backgroundColor: '#ffffff',
          color: '#000000',
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'inherit',
            fontWeight: 'bold',
          },
          '&:focus-visible': {
            border: '0px solid',
            borderColor: 'inherit',
            fontWeight: 'bold',
            boxShadow: 'none !important',
          },
        }}
      >
        {text}
      </MenuItem>
    );
  });

  return (
    <div>
      <Select
        sx={{
          'width': '120px',
          '.MuiOutlinedInput-notchedOutline': {
            borderStyle: 'none'
          },
        }}
        style={{ fontSize: '14px' }}
        value={projectView || views.MY_VIEW}
        onChange={handleViewChange}
        IconComponent={UnfoldMoreIcon}
      >
        {menuItems}
      </Select>
    </div>
  );
};

export default SelectProjectView;
