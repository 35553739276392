const MIN_YEAR = 2018;
const THIS_YEAR = (new Date()).getFullYear();
const MAX_YEAR = THIS_YEAR + 10;
export const ALL_YEARS = [];
for (let i = 0; i < (MAX_YEAR - MIN_YEAR + 1); i++) {
  ALL_YEARS.push(MIN_YEAR + i);
}

export const ALL_QUARTERS = {
  'Q1 (January - March)': 1,
  'Q2 (April - June)': 2,
  'Q3 (July - September)': 3,
  'Q4 (October - December)': 4,
}

export const INPUT_KEYS = {
  NAME: 'name',
  DESCRIPTION: 'description',
  START_YEAR: 'start-year',
  START_QUARTER: 'start-quarter',
  END_YEAR: 'end-year',
  END_QUARTER: 'end-quarter',
  AOI: 'aoi',
  TERMS: 'terms',
  LICENSE: 'license',
};

export const ALL_REQUIRED_KEYS = [
  INPUT_KEYS.NAME,
  INPUT_KEYS.START_YEAR,
  INPUT_KEYS.START_QUARTER,
  INPUT_KEYS.END_YEAR,
  INPUT_KEYS.END_QUARTER,
  INPUT_KEYS.AOI,
  INPUT_KEYS.TERMS,
  INPUT_KEYS.LICENSE,
];

export const LEGAL_KEYWORDS = [
  INPUT_KEYS.TERMS,
  INPUT_KEYS.LICENSE,
];

export const REQUIRED_HELPER_TEXT = 'Required';
export const START_END_HELPER_TEXT = 'First Year/Quarter must be before Last Year/Quarter';

export const WAM = 'wam';
