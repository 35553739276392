import { createContext, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

//import { useEnv } from "./env.context";
import { views } from '../constants/env';

export const SessionContext = createContext({});

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error(`useSession must be used within a SessionProvider`);
  }
  return context;
};

export const SessionProvider = ({ children }) => {
  const empty = '';
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  //const { demandIntelService } = useEnv();
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const [bearerToken, setBearerToken] = useState(empty);
  const [accessToken, setAccessToken] = useState({});

  const getBearerToken = async () => {
    const token = isAuthenticated ? await getAccessTokenSilently() : empty;

    if (token !== empty) {
      if (token === bearerToken) return token;

      setBearerToken(token);

      const decodedToken = jwt_decode(token);

      setAccessToken(decodedToken);

      return token;
    }
  };

  return (
    <SessionContext.Provider
      value={{
        isAuthenticated,
        bearerToken,
        accessToken,
        getBearerToken,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
