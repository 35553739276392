import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { pathOr } from 'ramda';

import { useSession } from '@/context/session';
import useGuards from '@/hooks/useGuards';
import useEnvs from '@/hooks/calls/useEnvs';
import useOrganizationLogo from '@/hooks/calls/useOrganizationLogo';

import logo from '@/assets/img/atlasai-logo-black-small.png';
import paths from '@/constants/paths';
import { isNilOrEmpty } from '@/utils/validator';

import Spinner from './Spinner';
import LogoutButton from './LogoutButton';

import TabButton from './TabButton';
import SaveProject from './SaveProject';
import Upload from './Upload';
import SelectEnv from './SelectEnv';
import SelectProjectView from './SelectView';

const Navbar = styled.div`
  background: #fff;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid #f6f8fa;
  font-size: 14px;
`;

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NavItemsOuter = styled(NavContainer)`
  flex: 1;
`;

const NavItemsInnerLeft = styled(NavContainer)`
  margin-right: auto;
`;

const NavItemsInnerRight = styled(NavContainer)`
  margin-left: auto;
`;

const Header = (props) => {
  const { page } = props;
  const isEnvironmentsPage = page === 'environments';
  const history = useHistory();
  const location = useLocation();
  const orgLogo = useOrganizationLogo();
  const { accessToken } = useSession();
  const { hasFeature, hasPermission } = useGuards();

  const currentEnv = useSelector((state) => state.app.currentEnv);
  const { data, isLoading: isEnvListLoading } = useEnvs();
  const envList = pathOr([], ['envs'], data);
  const currentEnvConfig = isEnvListLoading
    ? null
    : envList.find((env) => env.id === currentEnv) || envList[0];

  const envType = pathOr('default', ['envType'], currentEnvConfig);

  const leftItems = () => {
    return (
      <div
        onClick={() => {
          history.push(paths.yourEnvironments);
        }}
        className='pointer mr-16'
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {orgLogo.isLoading ? (
          <Spinner />
        ) : (
          <img
            src={orgLogo.data}
            id='base64image'
            style={{ maxHeight: '30px' }}
            alt='company-logo'
          />
        )}

        <img
          src={logo}
          alt={'logo'}
          style={{ maxHeight: '20px' }}
          className='ml-10'
        />

        {!isEnvironmentsPage && (
          <>
            <div>/</div>
            <SelectEnv />
            <div>/</div>
            <SelectProjectView />
          </>
        )}
      </div>
    );
  };

  const getDocFragment = () => {
    if (
      !hasFeature('DOCUMENTATION') || (
        isNilOrEmpty(currentEnvConfig?.documentationLink) &&
        isNilOrEmpty(currentEnvConfig?.secureDocumentationLink)
      )
    ) {
      return <></>;
    }

    return (
      <TabButton
        onClick={() => {
          history.push(paths.documentation);
        }}
        active={location.pathname === paths.documentation}
      >
        {isNilOrEmpty(currentEnvConfig?.documentationName)
          ? 'Documentation'
          : currentEnvConfig.documentationName}
      </TabButton>
    );
  };

  const getDashboardFragment = () => {
    if (
      hasFeature('DASHBOARD') && (
        !isNilOrEmpty(currentEnvConfig?.dashboardLink) ||
        !isNilOrEmpty(currentEnvConfig?.embedLink)
      )
    ) {
      return (
        <TabButton
          onClick={() => {
            history.push(paths.dashboard);
          }}
          active={location.pathname === paths.dashboard}
        >
          {isNilOrEmpty(currentEnvConfig?.dashboardName)
            ? 'Dashboard'
            : currentEnvConfig.dashboardName}
        </TabButton>
      );
    }

    return <></>;
  };

  const getVizFragment = () => {
    return (
      <TabButton
        onClick={() => {
          history.push(paths.visualization);
        }}
        active={location.pathname === paths.visualization}
      >
        {isNilOrEmpty(currentEnvConfig?.visualizationName)
          ? 'Visualization'
          : currentEnvConfig.visualizationName}
      </TabButton>
    );
  };

  const getFilesFragment = () => {
    if (
      hasFeature('FILES') &&
      !isNilOrEmpty(currentEnvConfig?.filesLink)
    ) {
      return (
        <TabButton
          onClick={() => {
            history.push(paths.files);
          }}
          active={location.pathname === paths.files}
        >
          {isNilOrEmpty(currentEnvConfig?.filesName)
            ? 'Files'
            : currentEnvConfig.filesName}
        </TabButton>
      );
    }

    return <></>;
  };

  const getOrderFragment = () => {
    switch (envType) {
      case 'wam':
        return (
          <TabButton
            onClick={() => {
              history.push(paths.order);
            }}
            active={location.pathname === paths.order}
          >
            {isNilOrEmpty(currentEnvConfig?.orderName)
              ? 'Order'
              : currentEnvConfig.orderName}
          </TabButton>
        );
      default:
        return <></>;
    }
  };

  const centerItems = () => {
    if (isEnvironmentsPage) return;

    switch (envType) {
      default:
        return (
          <>
            {getDocFragment()}
            {getDashboardFragment()}
            {getOrderFragment()}
            {getVizFragment()}
            {getFilesFragment()}
          </>
        );
    }
  };

  const rightItems = () => {
    return (
      <>
        {!isEnvironmentsPage &&
          (hasPermission('di:MyProject:update') ||
            hasPermission('di:TeamProject:update')) &&
            <SaveProject
              currentEnvConfig={currentEnvConfig}
            />
        }
        {hasPermission('di:FileUpload:create') && (
          <div className='ml-12'>
            <Upload />
          </div>
        )}
        <div className='nav-bar__actions ml-12'>
          <LogoutButton />
        </div>
      </>
    );
  };

  return (
    <Navbar>
      <NavItemsOuter>
        <NavItemsInnerLeft>{leftItems()}</NavItemsInnerLeft>
      </NavItemsOuter>
      <NavItemsOuter>
        <NavContainer>{centerItems()}</NavContainer>
      </NavItemsOuter>
      <NavItemsOuter>
        <NavItemsInnerRight>{rightItems()}</NavItemsInnerRight>
      </NavItemsOuter>
    </Navbar>
  );
};

export default Header;
