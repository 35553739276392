import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { pathOr } from 'ramda';

import AddIcon from '@mui/icons-material/Add';

import { ReactComponent as ThreeDotsIcon } from '@/assets/img/three-dots.svg';
import FallbackImage from '@/components/FallbackImage';
import Text from '@/components/Text';
import { WAM } from '@/components/Wam/constants';

import paths from '@/constants/paths';
import useGuards from '@/hooks/useGuards';
import { getDate } from '@/utils/date';
import { isNilOrEmpty } from '@/utils/validator';

import ProjectDetailModal from './ProjectDetailModal';
import CreateProjectModal from './CreateProjectModal';
import Label from './Label';
import img1 from './assets/img1.png';

const Wrapper = styled.div`
  border: 1px solid #e1e4e8;
  position: relative;
  width: 384px;
`;

const ThreeDotsIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    border: 1px solid rgba(0, 0, 0, 0.3);
  }
  border-radius: 50%;
`;

const CreateProjectCardContainer = styled.div`
  min-height: 300px;
`;

const CreateOuterWrapper = styled(Wrapper)`
  border: 2px solid #76787a;
  opacity: 0.3;
  :hover {
    opacity: 0.8;
  }
  height: 100%;
  color: #76787a;
`;


const CreateInnerWrapper = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% , -50%);
`;

const CreateNameText = styled(Text)`
  color: #76787a;
  text-align: center;
`;

export const ProjectCard = ({
  env,
  setEnvironment,
  onProjectUpdated,
}) => {
  const { hasFeature } = useGuards();
  const { user } = useAuth0();
  const history = useHistory();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  let defaultPath = paths.visualization;
  switch (pathOr('default', ['envType'], env)) {
    case WAM:
      if (hasFeature('WAM')) {
        defaultPath = paths.order;
        break;
      }
    default:
      if (hasFeature('DASHBOARD') && !isNilOrEmpty(env?.dashboardLink)) {
        defaultPath = paths.dashboard;
      }
      else if (
        hasFeature('DOCUMENTATION') && (
          !isNilOrEmpty(env?.documentationLink) ||
          !isNilOrEmpty(env?.secureDocumentationLink)
        )
      ) {
        defaultPath = paths.documentation;
      }
      else if (hasFeature('FILES') && !isNilOrEmpty(env?.filesLink)) {
        defaultPath = paths.files;
      }
      break;
  }

  const isMigratedEnv = env.name.startsWith('Migrated - ');
  const canSeeMigratedEnv = (
    process.env.NODE_ENV !== 'production' ||
    (
      user.email.includes('-admin@') ||
      user.email.includes('-internal@')
    )
  );

  if (isMigratedEnv && !canSeeMigratedEnv) {
    return <></>;
  }

  return (
    <div className='project-card-container'>
      <Wrapper
        onClick={() => {
          setEnvironment(env.id);
          history.push(defaultPath);
        }}
        className='pointer'
      >
        <Label status={!!env.enabled ? env.status : 'DISABLED'} />
        <FallbackImage
          src={env.imageLink}
          alt={env.name}
          width={384}
          height={216}
          className='mb-16'
          fallback={img1}
        />
        <div className='p-16'>
          <Text className='text20 mb-4'>{env.name}</Text>
          <Text className='text21 mb-16'>{env.description}</Text>
          <div className='flex-b-c'>
            <Text className='text22 mb-4 opacity-40'>
              Last update: {getDate(env.lastUpdateDate)}
            </Text>
            <ThreeDotsIconWrapper onClick={showModal}>
              <ThreeDotsIcon className='pointer' />
            </ThreeDotsIconWrapper>
          </div>
        </div>
      </Wrapper>
      <ProjectDetailModal
        key={Date.now()}
        name={env.name}
        description={env.description}
        status={env.status}
        envId={env.id}
        env={env}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        onProjectUpdated={onProjectUpdated}
      />
    </div>
  );
};

export const CreateProjectCard = ({
  name,
  componentInModal,
  onProjectCreated,
}) => {
  const modalKey = Date.now();

  const { hasFeature } = useGuards();
  const { user } = useAuth0();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  return (
    <CreateProjectCardContainer>
      <CreateOuterWrapper
        onClick={showModal}
        className='pointer'
      >
        <CreateInnerWrapper>
          <div style={{ textAlign: 'center' }}>
            <AddIcon sx={{ fontSize: '10rem' }}/>
          </div>
          <div className='p-16'>
            <CreateNameText className='text20 mb-4'>{name}</CreateNameText>
          </div>
        </CreateInnerWrapper>
      </CreateOuterWrapper>
      {<CreateProjectModal
        key={modalKey}
        setIsModalVisible={setIsModalVisible}
        isModalVisible={isModalVisible}
        componentInModal={componentInModal}
        onProjectCreated={onProjectCreated}
      />}
    </CreateProjectCardContainer>
  );
};
