function capitalizeFirstLetter(input) {
  return !input ? '' : input.charAt(0).toUpperCase() + input.slice(1);
}

export const Error = (params) => {
  return (
    <div>
      <h1>Error!</h1>
      <h2>{capitalizeFirstLetter(params.title)}</h2>
      <p>{params.description}</p>
    </div>
  );
};
