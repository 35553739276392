import React from 'react';
import styled from 'styled-components';

import Link from '@mui/material/Link';
import Text from '@/components/Text';
import { ReactComponent as ResourceIcon } from '@/assets/img/resource.svg';

const Wrapper = styled.div`
  border: 1px solid #d1d5da;
  padding: 12px 24px 12px 30px;
  border-radius: 2px;
  display: flex;
  gap: 14px;
`;

const ResourceCard = (props) => {
  const { title, description, href } = props;
  return (
    <Link
      href={href}
      rel='noreferrer'
      target='_blank'
      underline='none'
    >
      <Wrapper>
        <ResourceIcon />
        <div className=''>
          <Text className='text20'>{title}</Text>
          <Text className='text23'>{description}</Text>
        </div>
      </Wrapper>
    </Link>
  );
};

export default ResourceCard;
