import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr, has } from 'ramda';
import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import hash from 'object-hash';

// constants
import {
  LAYER_SOURCE,
  TEMPLATE_TL_URI_TYPE,
  TEMPLATE_LAYERS,
  MAP_LAYER_TYPE,
  OPERATIONS_WIDGETS,
} from '@/utils/constants';

// store
import {
  setLayerConfigs,
  setDefaultLayersOrder,
  setCustomLegendLayers,
  setCustomBasemapStyles,
  modifyCustomLegendLayersProps,
  setCustomLayersInfo,
  setBasemapConfig,
  setWidgetContainerState,
  setLayerVisibility,
} from '@/store/appSlice';

// utils
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';
import {
  setStateCustomLegendLayers, // handles layer visibility
  createLayerMetadataUri,
  createLayerDataUri,
  fetchDataFromUrl,
  orderLayerIds,
  setStateLayerInfo,
  extractTimestamps,
  mapTimestamps,
  isLayerVisible,
  normalizeLayerConfigs,
  assembleRequestHeaders,
} from '@/utils/map-utils';

// components
import GlobalLoading from '../GlobalLoading';
import WidgetsContainer from './common/WidgetsContainer';
import ZoomControl from './common/ZoomControl';
import MapByConfig from './map/MapByConfig';
import LegendWrapper from './common/LegendWrapper';
import Basemap from './common/Basemap';
import Timeline from './common/Timeline';

import {
  findBasemapConfig,
  prepareBasemapConfigs,
} from './utils/basemap';

// styles
const useStyles = makeStyles((theme) => ({
  mapWrapper: (props) => ({
    position: 'relative',
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    height: `calc(100% - ${props.headerHeight}px)`,

    // Fix Mapbox attribution button not clickable
    '& #deckgl-wrapper': {
      '& #deckgl-overlay': {
        zIndex: 1,
      },
      '& .mapboxgl-marker': {
        zIndex: 2,
      },
      '& #view-default-view > div': {
        zIndex: 'auto !important',
      },
    },
  }),
  zoomControl: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4),

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  legend: {
    position: 'absolute',
    bottom: theme.spacing(4),
    left: theme.spacing(4),
    maxWidth: '300px',
  },
  widgetsContainer: {
    position: 'absolute',
    top: theme.spacing(4),
    right: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
}));

export default function MapContainer({
  headerHeight,
  currentEnvConfig,
}) {
  const dispatch = useDispatch();

  const defaultLayersOrder = pathOr([], ['defaultLayersOrder'], currentEnvConfig);
  const [defaultBasemap, basemapConfigs] = prepareBasemapConfigs({
    defaultBasemapId: pathOr(null, ['defaultBaseMap'], currentEnvConfig),
    basemapConfigs: pathOr([], ['extraBaseMaps'], currentEnvConfig),
  });
  const extraLayers = pathOr([], ['extraLayers'], currentEnvConfig);
  const analytics = pathOr([], ['analytics'], currentEnvConfig);

  const analyticsHash = useMemo(
    () => hash.sha1(analytics),
    [analytics],
  );

  const currentBasemapConfig = useSelector((state) => state.app.basemapConfig)
  const isWidgetContainerOpen = useSelector((state) => state.app.widgetContainer);

  const [widgets, setWidgets] = useState({});

  const savedConfig = useSelector((state) => state.app.projectViewConfig);
  const [
    appliedSavedConfigBasemap,
    setAppliedSavedConfigBasemap,
  ] = useState(undefined);
  const [
    appliedSavedConfigLayers,
    setAppliedSavedConfigLayers,
  ] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [layerTimestamps, setLayerTimestamps] = useState([]);
  const [currentTimestamp, setCurrentTimestamp] = useState(null);

  const customLegendLayers = useSelector(
    (state) => state.app.customLegendLayers,
  );
  const customBasemapStyles = useSelector(
    (state) => state.app.customBasemapStyles,
  );

  const classes = useStyles({
    headerHeight,
  });

  // saved base map
  const savedBasemap = pathOr(
    undefined,
    ['mapConfig', 'basemap'],
    savedConfig,
  );

  // saved layers
  const savedLayers = pathOr(
    [],
    ['mapConfig', 'layers'],
    savedConfig,
  );

  // responsbile for basemap
  useEffect(
    () => {
      if (
        !isNilOrEmpty(currentBasemapConfig) && 
        appliedSavedConfigBasemap === savedConfig.key
      ) {
        return;
      }

      const config = findBasemapConfig({
        ids: [
          savedBasemap,
          defaultBasemap,
        ],
        basemapConfigs,
        notFound: basemapConfigs[0],
      });

      if (!isNilOrEmpty(config)) {
        dispatch(setBasemapConfig(config));
      }

      setAppliedSavedConfigBasemap(savedConfig.key);
    }, 
    [
      basemapConfigs,
      currentBasemapConfig,
      savedBasemap,
      defaultBasemap,
    ]
  );

  const updateWidgets = (updates) => {
    setWidgets((oldWidgets) => {
      const newWidgets = {
        ...oldWidgets,
        ...updates,
      };
      return newWidgets;
    });
  };

  // set map is loading
  const setIsMapLoading = (value) => {
    if (value === true) {
      setIsLoading(true);
    } else if (isLoading === true) {
      setIsLoading(false);
    }
  };

  // set the current timestamp
  const onTimelineItemSelect = (timelineItem) => {
    const timeStampId = pathOr('', ['timeStampId'], timelineItem);
    const customLayerId = pathOr('', ['customLayerId'], timelineItem);
    let customLayerUri = pathOr('', ['customLayerUri'], timelineItem);
    if (!isNilOrEmpty(timeStampId) && !isNilOrEmpty(customLayerUri)) {
      customLayerUri = customLayerUri.replace(/{timestampId}/, timeStampId);
      dispatch(
        modifyCustomLegendLayersProps({
          layerId: customLayerId,
          propsToBeChanged: [
            {
              propName: 'uri',
              propValue: customLayerUri,
            },
            {
              propName: 'currentTimestampID',
              propValue: timeStampId,
            },
          ],
        }),
      );
    }
  };

  // methods for custom layers from the config
  const handleLegendWrapperLayerChange = (customLayer) => {
    // treat for particular cases like Timeline layers
    const layerSource = pathOr(null, ['layerSource'], customLayer);
    const layerType = pathOr(null, ['layerType'], customLayer);
    const visible = isLayerVisible(customLayer);
    const customLayerId = pathOr(null, ['id'], customLayer);

    dispatch(
      setLayerVisibility({
        [customLayerId]: visible
      })
    );

    // create a tmp copy of customLayer
    let modifiedCustomLayer = {
      ...customLayer,
    };

    const updateTimestampsForHiddenLayer = (customLayer, layerTimestamps) => {
      // create a new array of layerTimestamps based on pathId to avoid duplicates
      const stateLayerTimestamps = layerTimestamps.filter(
        (layerTimestamp) => layerTimestamp?.customLayerId !== customLayer?.id,
      );
      setLayerTimestamps([
        ...stateLayerTimestamps
      ]);
    };

    const updateTimestampsForVisibleLayer = (
      customLayer,
      layerMetadataUri,
      layerTimestamps,
      props,
    ) => {
      return fetchDataFromUrl(
        layerMetadataUri,
        assembleRequestHeaders(customLayer),
      ).then((response) => {
        if (isNilOrEmpty(response)) return [];

        const timestamps = extractTimestamps(response);
        if (!isArrayNotEmpty(timestamps)) return [];

        const modifiedTimestamps = mapTimestamps(timestamps, props);
        if (!isArrayNotEmpty(modifiedTimestamps)) return [];

        // only include on timeline if layer has more than one timestamp
        if (modifiedTimestamps.length > 1) {
          // combine all the layerTimestamps in one single array
          setLayerTimestamps([
              ...layerTimestamps,
              ...modifiedTimestamps
          ]);
        }

        return modifiedTimestamps;
      });
    };

    // current pattern for ellipsis drive
    if (layerSource === LAYER_SOURCE.ELLIPSIS_DRIVE) {
      if (!visible) {
        updateTimestampsForHiddenLayer(customLayer, layerTimestamps);
        dispatch(setCustomLegendLayers(modifiedCustomLayer));
      }
      else {
        // set custom layer extra info
        setStateLayerInfo(
          [customLayer],
          dispatch,
          setCustomLayersInfo
        ).then(() => {
          // URI to get the layer data
          const customLayerUri = createLayerDataUri(customLayer);

          modifiedCustomLayer = {
            ...customLayer,
            uri: customLayerUri,
          };

          // URI to get layer info, which contains timestamps
          const layerMetadataUri = createLayerMetadataUri(customLayer);

          if (
            !isNilOrEmpty(layerMetadataUri) &&
            !isNilOrEmpty(customLayerUri)
          ) {
            updateTimestampsForVisibleLayer(
              customLayer,
              layerMetadataUri,
              layerTimestamps,
              {
                customLayerId,
                customLayerUri,
                layerType,
              },
            ).then((timestamps) => {
              if (!isArrayNotEmpty(timestamps)) return;

              const timestampId = timestamps[0].timeStampId;
              modifiedCustomLayer.uri = customLayerUri.replace(
                /{timestampId}/,
                timestampId,
              );

              if (timestamps.length > 1) {
                modifiedCustomLayer.currentTimestampID = timestampId;
              }

              dispatch(setCustomLegendLayers(modifiedCustomLayer));
            });
          }
          else {
            dispatch(setCustomLegendLayers(modifiedCustomLayer));
          }
        });
      }
    }
    // original "template" pattern for ellipsis drive
    else if (TEMPLATE_LAYERS.includes(layerType)) {
      if (!visible) {
        updateTimestampsForHiddenLayer(customLayer, layerTimestamps);
        dispatch(setCustomLegendLayers(modifiedCustomLayer));
      }
      else {
        // set custom layer extra info
        setStateLayerInfo([customLayer], dispatch, setCustomLayersInfo).then(
          () => {
            // URI to get the layer data
            const customLayerUri = createLayerDataUri(customLayer);

            // URI to get layer info, which contains timestamps
            const layerMetadataUri = createLayerMetadataUri(customLayer);

            if (
              !isNilOrEmpty(layerMetadataUri) &&
              !isNilOrEmpty(customLayerUri)
            ) {
              updateTimestampsForVisibleLayer(
                customLayer,
                layerMetadataUri,
                layerTimestamps,
                {
                  customLayerId,
                  customLayerUri,
                  layerType,
                },
              );

              // if we have the new uri for layers modify the uri prop
              modifiedCustomLayer = {
                ...customLayer,
                uri: customLayerUri,
                currentTimestampID: null,
              };
            }
            dispatch(setCustomLegendLayers(modifiedCustomLayer));
          },
        );
      }
    }
    // original "direct" pattern for ellipsis drive
    else if (layerType === MAP_LAYER_TYPE.MVT_LAYER && visible) {
      // set custom layer extra info
      setStateLayerInfo([customLayer], dispatch, setCustomLayersInfo).then(
        () => {
          // everything is loaded before dispatching the custom legend layers
          dispatch(setCustomLegendLayers(modifiedCustomLayer));
        },
      );
    }
    // unknown
    else {
      // dispatch the custom legend layers
      dispatch(setCustomLegendLayers(modifiedCustomLayer));
    }
  };

  useEffect(() => {
    dispatch(setDefaultLayersOrder(defaultLayersOrder || []));
  }, [defaultLayersOrder])

  // responsible for layers
  useEffect(() => {
    if (
      isNilOrEmpty(savedConfig) ||
      appliedSavedConfigLayers === savedConfig.key
    ) {
      return;
    }

    dispatch(
      setLayerConfigs({
        order: getLayerOrder(savedConfig),
      }),
    );

    if (isArrayNotEmpty(extraLayers)) {
      normalizeLayerConfigs(extraLayers);

      // set state custom legend layers
      setStateCustomLegendLayers(
        extraLayers,
        savedLayers,
        dispatch,
        setCustomLegendLayers,
      );

      const visibleLayers = extraLayers.filter(
        (layerConfig) => isLayerVisible(layerConfig)
      );

      visibleLayers.forEach((layerConfig) => {
        handleLegendWrapperLayerChange(layerConfig);
      });
    }

    setAppliedSavedConfigLayers(savedConfig.key);
  }, [
    savedConfig,
    savedBasemap,
    savedLayers,
    extraLayers,
    appliedSavedConfigLayers,
    setAppliedSavedConfigLayers,
  ]);

  // reset the current time layer stamp
  useEffect(() => {
    if (!isArrayNotEmpty(layerTimestamps)) {
      setCurrentTimestamp(null);
    }
  }, [layerTimestamps]);

  if (!appliedSavedConfigBasemap || !appliedSavedConfigLayers) {
    return (
      <>
        <GlobalLoading />
      </>
    );
  }

  return (
    <>
      {isLoading === true ? <GlobalLoading /> : <></>}
      <Grid item className={`${classes.mapWrapper}`}>
      <MapByConfig
          currentEnvConfig={currentEnvConfig}
          updateWidgets={updateWidgets}
          setIsMapLoading={setIsMapLoading}
          currentBasemapConfig={currentBasemapConfig}
          analytics={analytics}
          analyticsHash={analyticsHash}
          isWidgetContainerOpen={isWidgetContainerOpen}
        />
        <Hidden xsDown>
          <ZoomControl className={classes.zoomControl} showCurrentZoom />
        </Hidden>
        <LegendWrapper
          className={classes.legend}
          legendLayerConfig={customLegendLayers}
          layersOrder={
            // we should only used "saved" order once a user can actually define the order
            //layerConfigs?.order ||
            orderLayerIds({
              customLegendLayers,
              defaultLayersOrder,
            }) || []
          }
          onLegendWrapperLayerChange={handleLegendWrapperLayerChange}
          tooltipLabel='Show layer'
          toggleButtonLabel='Layers'
        />
        <WidgetsContainer
          className={classes.widgetsContainer}
          widgets={Object.values(widgets)}
          startsOpen={isWidgetContainerOpen}
          handleOpen={(state) => dispatch(setWidgetContainerState(state))}
        />
        <Basemap
          onChange={(cfg) => dispatch(setBasemapConfig(cfg))}
          currentBasemapConfig={currentBasemapConfig}
          basemapConfigs={basemapConfigs}
        />
        {isArrayNotEmpty(layerTimestamps) && (
          <Timeline
            timestamps={layerTimestamps}
            onItemSelected={onTimelineItemSelect}
            timeLineTitle={'Current date: '}
            currentTimestamp={currentTimestamp}
          />
        )}
      </Grid>
    </>
  );
}

const getLayerOrder = (savedConfig) => {
  const viewLayersConfig = pathOr([], ['mapConfig', 'layers'], savedConfig);
  const layerOrder = new Set(viewLayersConfig.map((cfg) => cfg.id));
  return Array.from(layerOrder);
};
