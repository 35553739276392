import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';

import './App.css';
import { ApolloWrapper } from './ApolloWrapper';

import Dashboard from './pages/Dashboard/Dashboard';
import Documentation from './pages/Documentation/Documentation';
import Files from './pages/Files/Files';
import Visualization from './pages/Visualization';
import { NotFound } from './pages/not-found';
import Profile from './pages/Profile';
import YourEnvironments from './pages/YourEnvironments';
import OrderDetails from './pages/OrderDetails';

import { ProtectedRoute } from './components/protected-route';
import EnvironmentsManagement from './components/EnvironmentsManagement';

import paths from './constants/paths';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 12 * 60 * 60 * 1000, // 12 hours in milliseconds
      cacheTime: 24 * 60 * 60 * 1000, // 24 hours in milliseconds
      retry: true,
      retryDelay: (attempt, error) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

function App() {
  return (
    <IntlProvider locale='en'>
      <QueryClientProvider client={queryClient}>
        <ApolloWrapper>
          <SnackbarProvider
            maxSnack={4}
            autoHideDuration={4000}
            anchorOrigin={{horizontal: 'center', vertical: 'top'}}
          />
          <Router>
            <div className='App'>
              <EnvironmentsManagement />
              <Switch>
                <ProtectedRoute
                  exact
                  path={paths.yourEnvironments}
                  component={YourEnvironments}
                />
                <ProtectedRoute
                  exact
                  path={paths.documentation}
                  component={Documentation}
                />
                <ProtectedRoute
                  exact
                  path={paths.dashboard}
                  component={Dashboard}
                />
                <ProtectedRoute
                  exact
                  path={paths.files}
                  component={Files}
                />
                <ProtectedRoute
                  exact
                  path={paths.profile}
                  component={Profile}
                />
                <ProtectedRoute
                  exact
                  path={paths.order}
                  component={OrderDetails}
                />
                <ProtectedRoute
                  exact
                  path={paths.visualization}
                  component={Visualization}
                />
                <Route path='*' component={NotFound} />
              </Switch>
            </div>
          </Router>
        </ApolloWrapper>
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default App;
