import { useAuth0 } from '@auth0/auth0-react';

import Dialog from '@mui/material/Dialog';

const CreateProjectModal = ({
  setIsModalVisible,
  isModalVisible,
  componentInModal,
  onProjectCreated,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  const handleClose = (evt, reason, envConfig) => {
    switch (reason) {
      case 'backdropClick':
      case 'escapeKeyDown':
        break;
      case 'projectCreated':
        onProjectCreated(envConfig);
        setIsModalVisible(false);
        break;
      default:
        setIsModalVisible(false);
        break;
    }
  };

  const inModal = (
    isModalVisible ?
      componentInModal({
        inModal: true,
        onExit: handleClose,
      }) :
      <></>
  );

  return (
    <Dialog
      open={isModalVisible}
      onClose={handleClose}
      fullScreen
    >
      {inModal}
    </Dialog>
  );
};

export default CreateProjectModal;
