import CircularProgress from '@mui/material/CircularProgress';

const Spinner = () => {
  return <CircularProgress
    color="primary"
    size="18px"
  />
};

export default Spinner;
