import { isNilOrEmpty } from '@/utils/validator';

import {
  MARKER_COLORS,
} from '@/utils/constants';

export const BASE_MAP_CONFIGS = [
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/streets_256.jpg',
    id: '88c481a7-e74e-4bf9-9771-adedf1f1845e',
    name: 'Streets',
    uri: 'mapbox://styles/atlasai/cm4c1y60n017201pz91iq6rab',
  },
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/light_256.jpg',
    id: 'ae9c91ed-3b49-45b4-b1d0-08c2f36a6c3c',
    name: 'Light',
    uri: 'mapbox://styles/atlasai/cm4c2aid1012u01slfldkaq2y',
  },
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/dark_256.jpg',
    id: '2258eec1-ace4-43b7-8d7e-fbf9c757fedb',
    name: 'Dark',
    uri: 'mapbox://styles/atlasai/cm4c2csot000r01raafzf8eoa'
  },
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/satellite_roads_256.jpg',
    id: '547a1a2c-acf7-439f-bf12-8eed58aec997',
    name: 'Satellite Roads',
    uri: 'mapbox://styles/atlasai/cm4c201vr00q101rca19wdnaa'
  },
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/satellite_roads_bw_256.jpg',
    id: 'd229a886-10da-47c7-8d37-fc62472bf20a',
    name: 'Satellite Roads B&W',
    uri: 'mapbox://styles/atlasai/clnvtdda3001801rfdvqh9zte'
  },
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/satellite_256.jpg',
    id: '3a8b4049-dc45-4b24-9016-50e61fedc938',
    name: 'Satellite',
    uri: 'mapbox://styles/atlasai/cm4cvq8o401fq01rdgwsmhel9'
  },
  {
    icon: 'https://storage.googleapis.com/aperture-public-images/basemaps/satellite_bw_256.jpg',
    id: 'd8fc1278-7c3f-4f4d-be94-08cc461ccbff',
    name: 'Satellite B&W',
    uri: 'mapbox://styles/atlasai/cm4cvw3jy003f01rag4n2d7jg'
  }
];

export const DEFAULT_BASE_MAP = 'Light';
export const DEFAULT_MARKER_COLOR = MARKER_COLORS.RED;

export const prepareBasemapConfigs = ({
  defaultBasemapId = null,
  basemapConfigs = BASE_MAP_CONFIGS,
}) => {
  const mergedConfigs = assembleBasemapConfigs(basemapConfigs);
  if (isNilOrEmpty(defaultBasemapId)) {
    defaultBasemapId = DEFAULT_BASE_MAP;
  }

  for (const basemapConfig of mergedConfigs) {
    if (
      basemapConfig.id === defaultBasemapId ||
      basemapConfig.name === defaultBasemapId
    ) {
      return [basemapConfig.id, mergedConfigs];
    }
  }

  return [mergedConfigs[0].id, mergedConfigs];
};

export const assembleBasemapConfigs = (extraConfigs) => {
  const mergedConfigs = {};
  for (const basemapConfig of [...BASE_MAP_CONFIGS, ...extraConfigs]) {
    const cfgId = basemapConfig?.id || basemapConfig?.name;
    mergedConfigs[cfgId] = basemapConfig;
  }

  return Object.values(mergedConfigs);
};

export const findBasemapConfig = ({
  ids = [],
  basemapConfigs = BASE_MAP_CONFIGS,
  notFound,
}) => {
  for (const id of ids) {
    const found = basemapConfigs.find(
      (cfg) => cfg.id === id || cfg.name === id
    );
    if (found !== undefined) {
      return found;
    };
  }

  return notFound;
};

export const getMarkerColor = (basemapConfig) => {
  return DEFAULT_MARKER_COLOR;
}
