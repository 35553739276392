import { useState } from 'react';
import axios from 'axios';
import { v4 as uuid4 } from 'uuid';
import styled from 'styled-components';
import { pathOr } from 'ramda';

import { useDropzone } from 'react-dropzone';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import UploadIcon from '@mui/icons-material/Upload';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import GlobalLoading from '@/components/GlobalLoading';
import { useEnv } from '@/context/env.context';
import { useSession } from '@/context/session';
import useGuards from '@/hooks/useGuards';
import globalConfig from '@/utils/config';
import emit from '@/utils/emit';
import { isNilOrEmpty } from '@/utils/validator';

import UploadButton from './UploadButton';
import UploadDescription from './UploadDescription';

const DropZone = styled('div')({
  backgroundColor: '#E8E8E8',
  border: '1px dashed #D3D3D3',
  cursor: 'pointer',
});

const DropZoneDescription = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '150px',
});

const AccordionProgress = styled(CircularProgress)({
  display: 'inline',
});

const READY = 'ready'
const UPLOADING = 'uploading'
const SUCCEEDED = 'succeeded'
const ERRORED = 'errored'

const UploadData = () => {
  const { demandIntelService } = useEnv();
  const { getBearerToken } = useSession();
  const { hasPermission } = useGuards();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [files, setFiles] = useState({});

  const [isDropZoneEnabled, setIsDropZoneEnabled] = useState(true);
  const [isUploading, setIsUploading] = useState(false);

  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
    acceptedFiles
  } = useDropzone({
    disabled: !isDropZoneEnabled,
    onDrop: (incomingFiles) => {
      const dataTransfer = new DataTransfer();
      incomingFiles.forEach((v) => {
        dataTransfer.items.add(v);
      });

      updateFiles(dataTransfer.files)
    },
  });

  const updateFiles = (newFiles) => {
    setFiles((oldFiles) => {
      const mergedFiles = {...oldFiles};
      for (const newFile of newFiles) {
        mergedFiles[newFile.path] = {
          ...(pathOr({}, [newFile.path], oldFiles)),
          id: pathOr(uuid4(), [newFile.path, 'id'], oldFiles),
          fileObj: newFile,
          state: pathOr(READY, [newFile.path, 'state'], oldFiles),
        };
      }
      return mergedFiles;
    });
  }

  const showUploadModal = () => {
    setIsModalVisible(true);
  };

  const handleClose = (evt, reason) => {
    switch (reason) {
      case 'backdropClick':
      case 'escapeKeyDown':
        break;
      default:
        setIsModalVisible(false);
        setFiles({});
        break;
    }
  };

  const getFilesToUpload = () => {
    const filesToUpload = Object.values(files).filter((file) => file.state !== SUCCEEDED);
    return filesToUpload;
  }

  const getSignedUrls = async (filesToUpload) => {
    const payload = filesToUpload.map((file) => {
      return {
        id: file.id,
        name: file.fileObj.name,
        description: file.description,
        title: file.name,
      };
    });

    const getSignedUrl = `${demandIntelService}${globalConfig.apiRoutes.fileUpload}`;
    const configGet = {
      url: getSignedUrl,
      method: 'POST',
      data: { payload },
      headers: {
        Authorization: `Bearer ${await getBearerToken()}`,
      },
    };

    return axios(configGet);
  };

  const updateFileState = (file, state) => {
    setFiles((oldFiles) => {
      const newFiles = { ...oldFiles };
      newFiles[file.fileObj.path].state = state;
      return newFiles;
    });
  };

  const uploadToSignedUrl = async (fileId, signedUrl) => {
    const file = Object.values(files).find((file) => file.id === fileId);
    const config = {
      url: signedUrl,
      method: 'PUT',
      data: file.fileObj,
      headers: { 
        'Content-Type': 'application/octet-stream',
      },
    };

    updateFileState(file, UPLOADING);

    return axios(config).then(
      () => {
        updateFileState(file, SUCCEEDED);
        return file;
      },
      (e) => {
        updateFileState(file, ERRORED);
        const msg = `Error uploading file: ${file.fileObj.path}, ${signedUrl}, ${e}`;
        console.error(msg);
        throw new Error(msg);
      },
    );
  }

  const handleUpload = async () => {
    setIsDropZoneEnabled(false); // one way road trip, only re-enable on success
    setIsUploading(true);

    const filesToUpload = getFilesToUpload();
    if (filesToUpload.length < 1) {
      setIsUploading(false);
      return;
    }

    let response;
    try {
      response = await getSignedUrls(filesToUpload);
    }
    catch (e) {
      setIsUploading(false);
      console.error('Error getting Signed URLs:', filesToUpload, e);
      emit.error('Cannot upload. Error getting Signed URLs. Please try again');
      return;
    }

    const signedUrls = response.data.data;
    const uploadPromises = signedUrls.map(
      (signedUrl) => uploadToSignedUrl(signedUrl.id, signedUrl.url)
    );
    Promise.all(uploadPromises).then(
      (uploadedFiles) => {
        emit.success(`Successfully uploaded all ${uploadedFiles.length} files`);
        setIsModalVisible(false);
        resetState();
      },
      (e) => {
        emit.error('Some files failed to upload. Please try again');
        setIsUploading(false);
      },
    );
  };

  const resetState = () => {
    setIsDropZoneEnabled(true);
    setFiles({});
    setIsUploading(false);
  };

  if (!hasPermission('di:FileUpload:create')) {
    return <></>;
  }

  return (
    <>
      <UploadButton onClick={showUploadModal}>
        Upload data
      </UploadButton>

      <Dialog
        open={isModalVisible}
        onClose={handleClose}
        maxWidth={false}
        scroll={'paper'}
      >
        <div style={{ width: '576px', maxHeight: '90vh' }}>
          <DialogTitle>
            <div style={{ fontSize: '1.4em', fontWeight: 'bold' }}>
              Upload data to Aperture Intelligence
            </div>
            <div style={{ fontSize: '0.8em' }}>
              Securely upload data to our platform for further analysis or to
              view your own data on the demand intelligence platform.
            </div>
          </DialogTitle>
          <DialogContent>
            <UploadDescription />

            <DropZone {...getRootProps({className: 'dropzone'})}>
              <input {...getInputProps()} />
              <DropZoneDescription>
                {
                  isDropZoneEnabled
                    ?  (
                      <>
                        <UploadIcon style={{ color: '#2579dd', width: '48px', height: '48px', marginBottom: '0.5em' }}/>
                        <div style={{ fontWeight: 'bold'}}>Click or drag files to this area to upload</div>
                        <div style={{ opacity: 0.75 }}>Supports bulk upload</div>
                      </>
                    )
                    : (
                      <>
                        <NotInterestedIcon style={{ color: '#BEBEBE', width: '48px', height: '48px', marginBottom: '0.5em' }}/>
                        <div style={{ fontWeight: 'bold'}}>Adding more files disabled</div>
                      </>
                    )
                    
                }
              </DropZoneDescription>
            </DropZone>

            <div style={{ overflowY: 'auto', maxHeight: '30vh' }}>
              {
                Object.entries(files).map(([key, value]) => {
                    return <FileMetadata
                      key={key}
                      file={value}
                    />;
                })
              }
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              onClick={handleClose}
              disabled={isUploading}
              style={{
                color: 'rgb(2, 77, 158)',
                borderColor: 'rgb(2, 77, 158)',
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={handleUpload}
              disabled={isUploading}
              style={{
                backgroundColor: 'rgb(2, 77, 158)',
                borderColor: 'rgb(2, 77, 158)',
              }}
            >
              Upload
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default UploadData;

const FileMetadata = ({file}) => {
  const [ formState, setFormState ] = useState({
    name: file.name,
    description: file.description,
  })

  const buildChangeHandler = (key) => {
    const onChange = (evt) => {
      const value = evt.target.value;
      file[key] = value;

      setFormState({
        ...formState,
        [key]: value,
      });

      if (key === 'name' && isNilOrEmpty(value)) {
        setHasError(true);
      }
    };
    return onChange;
  };

  let stateIcon;
  switch (file.state) {
    case SUCCEEDED:
      stateIcon = <CheckCircleIcon sx={{ color: 'green', width: '1em', height: '1em' }}/>;
      break;
    case ERRORED:
      stateIcon = <ErrorIcon sx={{ color: 'red', width: '1em', height: '1em' }}/>;
      break;
    case UPLOADING:
      stateIcon = <AccordionProgress size='1em' />;
      break;
    case READY:
    default:
      break;
  }

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <div style={{fontWeight: 'bold'}}>{file.fileObj.path}</div>
        {stateIcon !== undefined &&
          <>
            <div style={{marginLeft: '10px'}}>
              {stateIcon}
            </div>
          </>
        }
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          label='Name'
          variant='outlined'
          value={file?.name || ''}
          onChange={buildChangeHandler('name')}
          style={{marginBottom: '5px'}}
          disabled={file.state === SUCCEEDED || file.state === UPLOADING}
        />
        <TextField
          label='Description'
          variant='outlined'
          value={file?.description || ''}
          onChange={buildChangeHandler('description')}
          disabled={file.state === SUCCEEDED || file.state === UPLOADING}
          multiline
        />

      </AccordionDetails>
    </Accordion>
  );
  return <div></div>;
}
