import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { useAuth0 } from '@auth0/auth0-react';

import Header from '@/components/Header/Header';
import useEnvs from '@/hooks/calls/useEnvs';

import { setCredentials } from '@carto/react-redux';
import { API_VERSIONS } from '@deck.gl/carto';
import CartoContainer from '@/components/Carto/MapContainer';

import MapContainer from '@/components/Map/MapContainer';

import { doesEnvRequireCarto } from '@/utils/map-utils';
import { DEFAULT_HEADER_HEIGHT, } from '@/utils/constants';
import { isNilOrEmpty } from '@/utils/validator';

const Visualization = () => {
  const [ headerHeight, setHeaderHeight ] = useState(0);
  const { user } = useAuth0();

  const { getCurrentEnvConfig, isLoading: isEnvListLoading } = useEnvs();
  const currentEnv = useSelector((state) => state.app.currentEnv);
  const currentEnvConfig = getCurrentEnvConfig(currentEnv);
  const isCartoEnv = doesEnvRequireCarto(currentEnvConfig);

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEnvListLoading) return;
    if (!isCartoEnv) return;
    const dispatchCredentials = async () => {
      await dispatch(
        setCredentials({
          apiVersion: API_VERSIONS.V3,
          apiBaseUrl: 'https://gcp-us-east1.api.carto.com',
          accessToken: currentEnvConfig.cartoMapToken,
        }),
      );
    };
    dispatchCredentials();
  }, [currentEnvConfig, isCartoEnv, isEnvListLoading]);

  const getHeaderHeight = (node) => {
    if (isNilOrEmpty(node)) return;
    setHeaderHeight(
      pathOr(
        DEFAULT_HEADER_HEIGHT,
        ['height'],
        node.getBoundingClientRect(),
      )
    );
  };

  if (isCartoEnv) {
    const defaultLayersOrder = pathOr(
      [],
      ['defaultLayersOrder'],
      currentEnvConfig,
    );
    const extraLayers = pathOr([], ['extraLayers'], currentEnvConfig);
    const extraBaseMaps = pathOr([], ['extraBaseMaps'], currentEnvConfig);
    const analytics = pathOr([], ['analytics'], currentEnvConfig);

    const isMigratedEnv = currentEnvConfig.name.startsWith('Migrated - ');
    const canSeeMigratedEnv = (
      process.env.NODE_ENV !== 'production' ||
      (
        user.email.includes('-admin@') ||
        user.email.includes('-internal@')
      )
    );

    if (isMigratedEnv && !canSeeMigratedEnv) {
      return <></>;
    }

    return (
      <>
        <div ref={getHeaderHeight}>
          <Header />
        </div>

        <CartoContainer
          headerHeight={headerHeight}
          cartoMapId={currentEnvConfig?.cartoMapId}
          analytics={analytics}
          defaultLayersOrder={defaultLayersOrder}
          extraLayers={extraLayers}
          extraBaseMaps={extraBaseMaps}
        />
      </>
    );
  }
  else {
    return (
      <>
        <div ref={getHeaderHeight}>
          <Header />
        </div>

        <MapContainer
          headerHeight={headerHeight}
          currentEnvConfig={currentEnvConfig}
        />
      </>
    );
  }
};

export default Visualization;
