import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border: none;
  border-radius: 2px;
  padding: 4px 16px;
  font-size: 1em;
  cursor: pointer;
  background: transparent;
  :hover {
    color: #1a60b4;
  }
`;

const ActiveButton = styled(StyledButton)`
  background-color: #f1f8ff;
  color: #225e96;
`;

const TabButton = ({onClick, active, children}) => {
  if (active === true) {
    return (
      <ActiveButton
        onClick={onClick}
      >
        {children}
      </ActiveButton>
    );
  }

  return (
    <StyledButton
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

export default TabButton;
