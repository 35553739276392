import 'mapbox-gl/dist/mapbox-gl.css'; 

import { addSource, setViewState } from '@carto/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import DeckGLComponent from './DeckGLComponent';
import WidgetWrapper from '../common/WidgetWrapper';
import cloneDeep from 'lodash.clonedeep';
import { makeStyles } from '@material-ui/core';
import { pathOr } from 'ramda';

// validation tools
import { isArrayNotEmpty, isNilOrEmpty } from '@/utils/validator';

// utils
import {
  memoizedCreateLayerWidgets,
  rebuildWidgets,
} from '../utils/widgets';

import { OPERATIONS_WIDGETS } from '@/utils/constants';

const useStyles = makeStyles((theme) => ({
  map: {
    backgroundColor: theme.palette.grey[50],
    position: 'relative',
    flex: '1 1 auto',
  },
  tooltip: {
    '& .content': {
      ...theme.typography.caption,
      position: 'relative',
      padding: theme.spacing(1, 1.5),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
      color: 'rgba(255, 255, 255, 0.75)',
      transform: `translate(-50%, calc(-100% - ${theme.spacing(2.5)}px))`,

      '& .arrow': {
        display: 'block',
        position: 'absolute',
        top: 'calc(100% - 1px)',
        left: '50%',
        width: 0,
        height: 0,
        marginLeft: theme.spacing(-1),
        borderLeft: `${theme.spacing(1)}px solid transparent`,
        borderRight: `${theme.spacing(1)}px solid transparent`,
        borderTop: `${theme.spacing(1)}px solid ${theme.palette.grey[900]}`,
      },
    },
  },
}));

export default function MapByConfig({
  mapConfig,
  updateWidgets,
  setIsMapLoading,
  currentBasemapStyles = [],
  analytics = [],
  analyticsHash,
  isWidgetContainerOpen,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [mapState, setMapState] = useState(null);
  const [newMapStateFlag, setNewMapStateFlag] = useState(false);
  const layerStates = useSelector((state) => state.carto.layers || []);
  const viewState = useSelector((state) => state.carto.viewState || {});
  const customLayerVisibility = useSelector((state) => state.app.layerVisibility);

  // filters for custom layers
  const filtersForCustomLayers = useSelector(
    (state) => state.app.layerFilters,
  );

  const loadedTilesByLayerRef = useRef(new Map());

  let isReady = false;

  const resetMapState = () => {
    setIsMapLoading(true);
    setMapState(null);
  };

  const createSources = (data) => {
    return data.datasets.map((dataset) => createSource(dataset));
  };

  const createSource = (dataset) => {
    const src = {
      id: dataset.id,
      type: dataset.type,
      connection: dataset.connectionName,
      data: dataset.source,
    };

    dispatch(addSource(src));
    return src;
  };

  const updateLoadedTiles = (layerId, tiles) => {
    loadedTilesByLayerRef.current.set(layerId, tiles);
  };

  const createCartoWidgets = (data, hiddenDataSources) => {
    const widgets = {};
    for (let i = 0; i < data.keplerMapConfig.config.widgets.length; i++) {
      const cfg = data.keplerMapConfig.config.widgets[i];
      const widget = (
        <WidgetWrapper
          key={cfg.id}
          cfg={cloneDeep(cfg)}
          hideWidget={(hiddenDataSources || []).includes(cfg.dataSource)}
        />
      );
      if (!!widget) widgets[cfg.id] = widget;
    }
    return widgets;
  };

  const _setIsMapLoading = (value) => {
    setIsMapLoading(value && isReady);
  };

  useEffect(() => {
    rebuildWidgets({
      viewState,
      isWidgetContainerOpen,
      loadedTilesByLayer: loadedTilesByLayerRef.current,
      analyticsConfigs: analytics,
      analyticsConfigsHash: analyticsHash,
      layerVisibilityStatuses: customLayerVisibility,
      updateWidgets,
      filtersForCustomLayers,
    });
  }, [
    viewState,
    customLayerVisibility,
    isWidgetContainerOpen,
    filtersForCustomLayers,
    analytics,
    analyticsHash,
  ]);

  useEffect(() => {
    if (mapConfig === null) return;
    isReady = false;

    const data = mapConfig.data;
    // note that we copy to preserve a clean state
    const map = {
      ...mapConfig.map,
    };
    // we need to clone each layer
    map.layers = map.layers.map((layer) =>
      layer.clone({
        visible: true,
      }),
    );
    if (!mapState) {
      dispatch(setViewState(map.initialViewState));
      createSources(data);
      updateWidgets(createCartoWidgets(data));
      setNewMapStateFlag(true);
      setMapState({ ...map });
      isReady = true;
    }
  }, [mapConfig, mapState]);

  useEffect(() => {
    const dataSourceVisibility = {};
    Object.values(layerStates).forEach((layerState) => {
      if (dataSourceVisibility[layerState.source] === undefined) {
        dataSourceVisibility[layerState.source] = [];
      }
      dataSourceVisibility[layerState.source].push(layerState.visible);
    });

    const hiddenDataSources = Object.keys(dataSourceVisibility).reduce(
      (hiddenDataSources, sourceId) => {
        if (
          dataSourceVisibility[sourceId].every(
            (visible) => visible === false,
          ) === true
        ) {
          hiddenDataSources.push(sourceId);
        }
        return hiddenDataSources;
      },
      [],
    );

    updateWidgets(
      createCartoWidgets(mapConfig.data, hiddenDataSources),
    );
  }, [layerStates]);

  useEffect(() => {
    if (isArrayNotEmpty(analytics)) {
      const widgets = memoizedCreateLayerWidgets({
        widgetConfigs: analytics,
        widgetConfigsHash: analyticsHash,
        data: [],
      });
      if (!isNilOrEmpty(widgets)) updateWidgets(widgets);
    }
    // TODO what if analytics is empty? not likely after env is loaded
  }, [
    analytics,
    analyticsHash,
  ]);

  const HAS_MAP_STATE = !isNilOrEmpty(mapState);

  return (
    <div className={HAS_MAP_STATE ? classes.map : ''}>
      {HAS_MAP_STATE ? (
        <DeckGLComponent
          updateLoadedTiles={updateLoadedTiles}
          currentBasemapStyles={currentBasemapStyles}
          mapConfig={mapConfig}
          mapState={mapState}
          resetMapState={resetMapState}
          newMapStateFlag={newMapStateFlag}
          setNewMapStateFlag={setNewMapStateFlag}
          setIsMapLoading={_setIsMapLoading}
          analytics={analytics}
          analyticsHash={analyticsHash}
          filtersForCustomLayers={filtersForCustomLayers}
        />
      ) : null}
    </div>
  );
}
